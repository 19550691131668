import { Mutation } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const SET_ADD_PRODUCT_TO_WISHLIST = gql`
  mutation SetAddProductToWishlist($productId: String!) {
    setAddProductToWishlist(productId: $productId) {
      success
    }
  }
`;

export const SET_REMOVE_PRODUCT_FROM_WISHLIST = gql`
  mutation SetRemoveProductFromWishlist($productId: String!) {
    setRemoveProductFromWishlist(productId: $productId) {
      success
    }
  }
`;

export const SET_ADD_ALL_WISHLIST_TO_CART = gql`
  mutation SetAddAllWishlistToCart($products: [WishlistProduct!]) {
    setAddAllWishlistToCart(products: $products) {
      success
      message
      productId
    }
  }
`;

export class SetAddProductToWishlistMutation extends Mutation {
  static defaultProps = {
    mutation: SET_ADD_PRODUCT_TO_WISHLIST,
    refetchQueries: ['ProductsInWishlist', 'MiniCart', 'Cart']
  };
}

export class SetRemoveProductFromWishlistMutation extends Mutation {
  static defaultProps = {
    mutation: SET_REMOVE_PRODUCT_FROM_WISHLIST,
    refetchQueries: ['ProductsInWishlist', 'MiniCart']
  };
}

export class SetAddAllWishlistToCartMutation extends Mutation {
  static defaultProps = {
    mutation: SET_ADD_ALL_WISHLIST_TO_CART,
    refetchQueries: ['ProductsInWishlist', 'MiniCart', 'Cart']
  };
}
