import React from 'react';
import { MegaMenuQuery } from '../MegaMenu/MegaMenuQuery';
import { MegaMenu } from '../MegaMenu/MegaMenu';
import Container from '../../layout/Container/Container';
import { PreHeader } from '../PreHeader/PreHeader';
import { SearchBar } from '../../elements/SearchBar/SearchBar';
import { GoogleTagManagerUsercentrics } from '../../elements/Tracking/GoogleTagManager/Usercentrics';
import { PaybackScridReferrer } from '../../elements/Payback/PaybackScridReferrer';
import { ReferrerTracking } from '../../elements/Tracking/GoogleTagManager/GA4/ReferrerTracking';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { HeaderActions } from './HeaderActions';

export const Header = withStoreConfiguration(({ storeConfiguration, hideMenu }) => (
  <header id="top">
    <ReferrerTracking storeConfiguration={storeConfiguration} />
    <GoogleTagManagerUsercentrics />
    <PaybackScridReferrer />
    <PreHeader />
    <HeaderActions />
    {!hideMenu ? (
      <Container>
        <div className="RelativeContainer">
          <SearchBar />
          <MegaMenuQuery passLoading>{({ data: { megaMenu } }) => <MegaMenu data={megaMenu} />}</MegaMenuQuery>
        </div>
      </Container>
    ) : null}
  </header>
));
