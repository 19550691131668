import React from 'react';
import { Box } from '@deity/falcon-ui';
import { T } from '@deity/falcon-i18n';
import { Price } from '@deity/falcon-ui-kit';
import { ShippingCostTooltip } from '../ShippingCostTooltip/ShippingCostTooltip';
import CartTotals from '../Cart/CartTotals/CartTotals';
import styles from './TotalRow.module.scss';

export const TotalRow = ({
  title,
  taxLabel,
  value,
  code,
  pink,
  bold,
  grey,
  currency,
  showShippingTooltip,
  couponCode,
  ...props
}) => {
  const additionalClasses = [];

  if (bold) {
    additionalClasses.push(styles.TotalRowBold);
  }

  if (grey) {
    additionalClasses.push(styles.TotalRowGrey);
  }

  if (pink) {
    additionalClasses.push(styles.TotalRowPink);
  }

  const dataInspect = `total-row-${code}`;
  return (
    <Box display="flex" {...props} className={[...additionalClasses].join(' ')} data-inspect={dataInspect}>
      <Box
        flex="1"
        className={[
          styles.TotalRowLeft,
          code === CartTotals.TOTALS.SHIPPING && showShippingTooltip !== null ? styles.TotalRowRightPadding : null
        ].join(' ')}
      >
        {code === CartTotals.TOTALS.DISCOUNT ||
        CartTotals.TOTALS.OVERALL_DISCOUNT ||
        code === CartTotals.TOTALS.GIFTCERT ||
        (code === CartTotals.TOTALS.SUBTOTAL && couponCode) ? (
          title
        ) : (
          <T id={`totals.${code}`} />
        )}
        {/* {code === CartTotals.TOTALS.TAX ? ` (${taxLabel.percent}%)` : null} */}
        {code === CartTotals.TOTALS.SHIPPING && showShippingTooltip !== null ? <ShippingCostTooltip /> : null}
      </Box>
      <Price value={value} currency={currency} className={styles.TotalRowRight} />
    </Box>
  );
};
