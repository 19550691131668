import React from 'react';
import { T } from '@deity/falcon-i18n';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { Button } from '../../../ui/Button/Button';

export const AmazonLoginButton = withStoreConfiguration(({ storeConfiguration }) => {
  const active = storeConfiguration?.amsociallogin?.amazon?.enabled === '1';

  if (active === true) {
    return (
      <>
        <Button
          asLink
          path="/amsociallogin/social/login/?type=amazon"
          type="submit"
          types="amazon_login"
          iconSrc="amazon"
        >
          <div>
            <T id="logInWithAmazon" />
          </div>
        </Button>
      </>
    );
  }
  return null;
});
