import React from 'react';
import { T } from '@deity/falcon-i18n';
import PropTypes from 'prop-types';
import { NumberInput } from '../../../ui/NumberInput/NumberInput';
import { Dropdown } from '../../../ui/Dropdown/Dropdown';

export const RemoveItemButton = ({ onClick, loading }) => (
  <React.Fragment>
    <a
      className={['Link', 'LinkPurple', loading ? 'LinkDisabled' : null, 'HideMobile'].join(' ')}
      onClick={() => onClick()}
      aria-hidden
    >
      <T id="cart.removeItem" />
    </a>
    <a
      className={['Link', 'LinkPurple', loading ? 'LinkDisabled' : null, 'ShowInlineMobile'].join(' ')}
      onClick={() => onClick()}
      aria-hidden
    >
      <T id="cart.removeItemShort" />
    </a>
  </React.Fragment>
);

RemoveItemButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool
};

export const ChangeItemNumberInput = ({ onChange, loading, ...props }) => {
  return (
    <div style={{ opacity: loading ? 0.4 : 1 }}>
      <NumberInput disabled={loading} min="1" name="qty" onChange={ev => onChange(ev)} {...props} />
    </div>
  );
};

ChangeItemNumberInput.propTypes = {
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  ...NumberInput.propTypes
};

export const ChangeItemDropdown = ({ onChange, loading, defaultValue }) => {
  const options = [{ value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }];
  const preSelected = defaultValue.toString() || options[0].value;

  return (
    <div style={{ position: 'relative', opacity: loading ? 0.4 : 1 }}>
      <Dropdown
        options={options}
        variant="number"
        preSelected={preSelected}
        size="small"
        listFullWidth
        onChange={onChange}
        disable={loading}
      />
      {loading ? <div style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }} /> : null}
    </div>
  );
};

ChangeItemDropdown.propTypes = {
  onChange: PropTypes.func,
  loading: PropTypes.bool
};
