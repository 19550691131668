import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const GET_SUGGEST = gql`
  query SuggestProducts($term: String!, $maxCompletions: Int, $maxProducts: Int) {
    suggest(term: $term, size: $maxCompletions) {
      suggestList {
        value
      }
      productList(input: { pageSize: $maxProducts, preview: true }) {
        items {
          id
          name
          productImage {
            default
            retina
            defaultWebp
            retinaWebp
          }
          urlPath
          volume
        }
        pagination {
          totalItems
        }
      }
      defaultProductList(input: { pageSize: $maxProducts, preview: true }) {
        items {
          id
          sku
          name
          productImage {
            default
            retina
            defaultWebp
            retinaWebp
          }
          urlPath
          rating
        }
      }
    }
  }
`;

export class SuggestQuery extends Query {
  static defaultProps = { query: GET_SUGGEST };
}
