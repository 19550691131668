import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { UrlQuery } from '@deity/falcon-data';
import { InnerHTML } from '@deity/falcon-ui-kit';
import { useLocation } from 'react-router-dom';
import { CtaBannerTracking } from '../Tracking/GoogleTagManager/GA4/CtaBannerTracking';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { CtaBannersQuery } from './CtaBannerQuery';
import styles from './CtaBanner.module.scss';

const IGNORE_PATH = [
  '/search',
  '/sign-in',
  '/cart',
  '/checkout',
  '/pdp',
  '/logout',
  '/loginascustomer',
  '/paypal_express',
  '/amazon_pay',
  '/stripe',
  '/payu',
  '/contacts',
  '/newsletter/not-confirmed',
  '/newsletter/subscriber',
  '/easym/sso',
  '/socialconnect',
  '/account',
  '/create-password-form',
  '/amsociallogin'
];

export const CtaBanner = withStoreConfiguration(({ storeConfiguration, path }) => {
  if (path && (IGNORE_PATH.includes(path) || IGNORE_PATH.some(ignorePath => path.startsWith(`${ignorePath}/`)))) {
    return null;
  }

  const closedBanners = Cookies.get('ctaBanners');
  const closedBannersIds = closedBanners ? closedBanners.split(':') : [];
  const [closedCtaBanner, setClosedCtaBanner] = useState(closedBanners || []);
  const query = new URLSearchParams(useLocation().search);
  const mobileAppView = query.get('mobileAppView');

  if (mobileAppView !== null && parseInt(storeConfiguration?.asam_ctabanner?.general?.hide_on_mobile_app, 10)) {
    return null;
  }

  const closeHandle = id => {
    if (!closedBanners) {
      closedBannersIds.push(`${id}`);
      Cookies.set('ctaBanners', `${id}`, { expires: 365 });
    } else if (!closedBannersIds.includes(`${id}`)) {
      closedBannersIds.push(`${id}`);
      Cookies.set('ctaBanners', closedBannersIds.join(':'), { expires: 365 });
    }

    setClosedCtaBanner(closedBannersIds);
  };

  return (
    <UrlQuery variables={{ path }} passLoading>
      {({ data: { url } }) => {
        return url && url?.type !== 'not-found' ? (
          <CtaBannersQuery
            passLoading
            variables={{
              path,
              categoryId: url.type === 'shop-category' ? url.id : 'null',
              platform: 'web'
            }}
          >
            {({ data: { ctaBanners } }) => {
              return ctaBanners && ctaBanners.items ? (
                <div className={styles.CtaBanners}>
                  {ctaBanners.items.map(item => {
                    return item.active && !closedCtaBanner.includes(`${item.id}`) ? (
                      <div
                        className={[styles.CtaBanner, item.clickable === 2 ? styles.Pointer : null].join(' ')}
                        key={item.id}
                        onClick={
                          item.clickable === 2
                            ? () => {
                                CtaBannerTracking({ storeConfiguration, ctaBanner: item });
                                window.location.href = `${item.url}`;
                              }
                            : null
                        }
                      >
                        <InnerHTML
                          className={[styles.CtaBannerContent, item.clickable === 1 ? styles.Pointer : null].join(' ')}
                          html={item.content}
                          span
                          onClick={
                            item.clickable === 1
                              ? () => {
                                  CtaBannerTracking({ storeConfiguration, ctaBanner: item });
                                  window.location.href = `${item.url}`;
                                }
                              : null
                          }
                        />
                        {item.closeable ? (
                          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                          <div
                            className={[styles.CtaBannerClose, 'icon-x'].join(' ')}
                            onClick={e => {
                              e.stopPropagation();
                              closeHandle(item.id);
                            }}
                          />
                        ) : null}
                      </div>
                    ) : null;
                  })}
                </div>
              ) : null;
            }}
          </CtaBannersQuery>
        ) : null;
      }}
    </UrlQuery>
  );
});
