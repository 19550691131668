import React from 'react';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { Labels } from '../../Labels';
import styles from './ProductLabels.module.scss';

export const ProductLabels = withStoreConfiguration(({ labels, product, config, storeConfiguration }) => {
  const magentoUrl = storeConfiguration?.web?.unsecure?.base_media_url;

  const productLabelConfig = storeConfiguration?.asam_productlabel;
  const labelConfigKey = `limit_${config}`;

  const productLabelConfigProductView = productLabelConfig[labelConfigKey];
  if (productLabelConfigProductView === undefined) {
    return null;
  }

  const limitDesktop = productLabelConfigProductView.limit_desktop;
  const limitTablet = productLabelConfigProductView.limit_tablet;
  const limitMobile = productLabelConfigProductView.limit_mobile;

  return labels && labels.length ? (
    <div className={styles.ProductLabels}>
      {labels
        .filter(item => !item.badge)
        .map((item, index) => {
          const additionalClasses = [];

          if (limitDesktop && index > limitDesktop - 1) {
            additionalClasses.push(styles.HideOnDesktop);
          }

          if (limitTablet && index > limitTablet - 1) {
            additionalClasses.push(styles.HideOnTablet);
          }

          if (limitMobile && index > limitMobile - 1) {
            additionalClasses.push(styles.HideOnMobile);
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className={[...additionalClasses].join(' ')} key={`label_${index}`}>
              <Labels
                type={item.type}
                size={item.size}
                rotated={item.rotated}
                transparent={item.transparent}
                percent={item.percent}
                text={item.text}
                product={product}
                image={item.images.length ? `${magentoUrl}${item.images[0].image.replace('/media/', '')}` : null}
              />
            </div>
          );
        })}
    </div>
  ) : null;
});
