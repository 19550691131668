import React from 'react';
import CartTotals from '../../Cart/CartTotals/CartTotals';
import styles from '../MiniCart.module.scss';

export const MiniCartSummary = ({ totals, taxLabel, couponCode }) => (
  <CartTotals
    className={styles.MiniCartSummary}
    taxLabel={taxLabel}
    totalsData={totals}
    totalsToDisplay={[
      CartTotals.TOTALS.SUBTOTAL,
      CartTotals.TOTALS.OVERALL_SUBTOTAL,
      CartTotals.TOTALS.DISCOUNT,
      CartTotals.TOTALS.OVERALL_DISCOUNT,
      CartTotals.TOTALS.GIFTCERT,
      CartTotals.TOTALS.SHIPPING,
      CartTotals.TOTALS.OTHER_TAX,
      CartTotals.TOTALS.GRAND_TOTAL,
      CartTotals.TOTALS.TAX
    ]}
    couponCode={couponCode}
    pink={[CartTotals.TOTALS.DISCOUNT, CartTotals.TOTALS.OVERALL_DISCOUNT]}
    bold={[CartTotals.TOTALS.GRAND_TOTAL]}
    grey={[
      CartTotals.TOTALS.SUBTOTAL,
      CartTotals.TOTALS.OVERALL_SUBTOTAL,
      CartTotals.TOTALS.TAX,
      CartTotals.TOTALS.OTHER_TAX
    ]}
  />
);
