import { useQuery } from '@apollo/react-hooks';
import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const GET_PRODUCTS_IN_WISHLIST = gql`
  query ProductsInWishlist {
    productsInWishlist {
      itemId
      wishlistId
      qty
      name
      shortDescription
      sku
      type
      image
      normPrice
      cosContent
      cosBrand
      thumbnailUrl
      urlKey
      price
      finalPrice
      isInStock
      productImage {
        default
        retina
        defaultWebp
        retinaWebp
      }
    }
  }
`;

export class ProductsInWishlistQuery extends Query {
  static defaultProps = {
    query: GET_PRODUCTS_IN_WISHLIST,
    notifyOnNetworkStatusChange: true,
    passLoading: true
  };
}

export const useProductsInWishlistQuery = (options = {}) =>
  useQuery(GET_PRODUCTS_IN_WISHLIST, {
    ...options
  });
