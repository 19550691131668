import React from 'react';
import { Route } from 'react-router-dom';

export default props => {
  const { status, children, ...restProps } = props;
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.status = status;
        }
        return children;
      }}
      {...restProps}
    />
  );
};
