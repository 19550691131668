import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CartQuery } from '../../overrides/Cart/CartQuery';
import { CustomerQuery } from '../../overrides/Customer/CustomerQuery';
import Container from '../../layout/Container/Container';
import { Logo } from '../../elements/Logo/Logo';
import { WishlistIcon } from '../../elements/Wishlist/WishlistIcon';
import { MiniCartIcon } from '../../elements/MiniCart/MiniCartIcon';
import { CustomerAccountIcon } from '../../elements/CustomerAccount/CustomerAccountIcon';
import { MobileMenu } from '../MegaMenu/MobileMenu/MobileMenu';
import styles from './HeaderActions.module.scss';

export const HeaderActions = () => {
  let topPosition = typeof window !== 'undefined' ? window.pageYOffset : 0;
  const [stuckEl, setStuckEl] = useState(false);
  const location = useLocation();

  const checkPosition = () => {
    if (topPosition > 160 && window.pageYOffset < topPosition) {
      setStuckEl(true);
    } else {
      setStuckEl(false);
    }

    topPosition = window.pageYOffset;
  };

  if (typeof window !== 'undefined') {
    useEffect(() => {
      topPosition = 0;
      window.scrollTo(0, 0);
      window.removeEventListener('scroll', checkPosition);

      if (window.innerWidth < 1024) {
        window.addEventListener('scroll', checkPosition);
      }

      return () => {
        setStuckEl(false);
      };
    }, [location]);
  }

  return (
    <>
      {stuckEl ? <div className={styles.HeaderSpacer} /> : null}
      <Container className={[styles.HeaderHolder, stuckEl ? styles.HeaderStuck : ''].join(' ')}>
        <div className={styles.Header}>
          <MobileMenu />
          <div className={styles.HeaderLogo} id="HeaderLogo">
            <Logo />
          </div>
          <div className={styles.HeaderActions}>
            <CartQuery passLoading>
              {({ data: { cart } }) => <MiniCartIcon itemsQty={cart ? cart.itemsQty : 0} />}
            </CartQuery>
            <WishlistIcon />
            <CustomerQuery passLoading>
              {({ data: { customer } }) => <CustomerAccountIcon customer={customer} />}
            </CustomerQuery>
          </div>
        </div>
      </Container>
    </>
  );
};
