import { PageviewTracking } from '../Pageview';

export const NewsletterRegistrationTracking = ({ storeConfiguration }) => {
  const dataLayerData = {
    key: `newsletter_registration`,
    event: 'Generic Event',
    event_name: 'newsletter_registration',
    newsletter_registration: {
      event_category: 'newsletter',
      event_action: 'registration'
    }
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
