export const AccountPath = {
  general: {
    to: '/account',
    link: 'creator.prev'
  },

  dashboard: {
    to: '/account',
    link: 'accountDashboard.title',
    exact: true
  },

  profile: {
    to: '/account/profile',
    link: 'accountProfile.title',
    icon: 'profile'
  },

  subscriptions: {
    to: '/account/subscriptions',
    link: 'accountAbo.title',
    icon: 'abo'
  },

  profileEdit: {
    to: '/account/profile/personal-information',
    link: 'editCustomer.title'
  },

  orders: {
    to: '/account/orders',
    link: 'accountOrders.title',
    icon: 'package'
  },

  orderDetails: {
    to: '/account/orders/:id',
    link: 'accountOrders.orderDetailsTitle'
  },

  wishlist: {
    to: '/account/wishlist',
    link: 'accountWishlist.title',
    icon: 'wishlist'
  },

  rating: {
    to: '/account/rank-products',
    link: 'accountRating.title',
    icon: 'rating'
  },

  newsletter: {
    to: '/account/newsletter',
    link: 'accountNewsletter.title',
    icon: 'newsletter'
  },

  subscriberInfo: {
    to: '/account/newsletter/subscriber-info/:id'
  },

  unsubscribe: {
    to: '/newsletter/subscriber/unsubscribe'
  },

  recommendation: {
    to: '/freunde-werben',
    link: 'accountRecommendation.link',
    icon: 'recommendation'
  },

  help: {
    to: '/account/help',
    link: 'accountHelp.title',
    icon: 'counseling'
  },

  changePassword: {
    to: '/account/profile/change-password',
    link: 'changePassword.title'
  },

  changeEmail: {
    to: '/account/profile/change-email',
    link: 'changeEmail.title'
  },

  addressBook: {
    to: '/account/address-book',
    link: 'addressBook.title'
  },

  addressBookAdd: {
    to: '/account/address-book/add',
    link: 'addressBook.addNewButton'
  },

  addressBookEdit: {
    to: '/account/address-book/edit/:id',
    link: 'addressBook.editLink'
  }
};
