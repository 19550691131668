import React from 'react';
import { ProductBanner } from '../ProductBanner/ProductBanner';
import Container from '../../../layout/Container/Container';
import useCheckBreakpoints from '../../../hooks/CheckBreakpoint';

export const ProductUpsale = ({ content }) => {
  const { isMobile } = useCheckBreakpoints();
  return (
    <Container>
      {content.slice(0, 1).map((upsale, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`upsale-${index}`}>
          <ProductBanner
            key={`upsale-${index}`} // eslint-disable-line
            headlineContent={upsale.fields}
            header={upsale.fields.text}
            buttonText={upsale.fields.cta}
            buttonLink={upsale.fields.related_product ? upsale.fields.related_product.fields.products[0].urlPath : null}
            image={
              !isMobile
                ? upsale.fields.image[0].fields.file.url
                : upsale.fields.image[1].fields.file.url || upsale.fields.image[0].fields.file.url
            }
            price={
              upsale.fields.related_product ? upsale.fields.related_product.fields.products[0].price.regular : null
            }
            specialPrice={
              upsale.fields.related_product && upsale.fields.related_product.fields.products[0].price.special
                ? upsale.fields.related_product.fields.products[0].price.special
                : null
            }
            pricePerUnit={
              upsale.fields.related_product ? upsale.fields.related_product.fields.products[0].baseprice : null
            }
          />
        </div>
      ))}
    </Container>
  );
};
