import React from 'react';
import { extractThemableProps } from '@deity/falcon-ui';
import styles from './NumberInput.module.scss';

function triggerChange(element, value) {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

  if (valueSetter && prototypeValueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else if (valueSetter) {
    valueSetter.call(element, value);
  }

  if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
    const evt = document.createEvent('UIEvents');
    evt.initUIEvent('change', true, false, window, 0);
    element.dispatchEvent(evt);
  } else {
    element.dispatchEvent(new Event('change', { bubbles: true }));
  }
}

export class NumberInput extends React.Component {
  inputRef = React.createRef();

  downButtonRef = React.createRef();

  upButtonRef = React.createRef();

  componentDidMount() {
    const currentValue = +this.inputRef.current.value;
    this.lockButtons(currentValue);
  }

  getStep() {
    if (this.props.step === undefined) {
      return 1;
    }
    return +this.props.step;
  }

  getMax() {
    if (this.props.max === undefined) {
      return Number.POSITIVE_INFINITY;
    }
    return +this.props.max;
  }

  getMin() {
    if (this.props.min === undefined) {
      return Number.NEGATIVE_INFINITY;
    }
    return +this.props.min;
  }

  stepUp = () => {
    if (!this.inputRef.current) {
      return;
    }

    const currentValue = +this.inputRef.current.value;
    const max = this.getMax();

    let nextValue = currentValue + this.getStep();

    if (nextValue > max) {
      nextValue = max;
    }

    this.lockButtons(nextValue);

    triggerChange(this.inputRef.current, nextValue);
  };

  stepDown = () => {
    if (!this.inputRef.current) {
      return;
    }

    const currentValue = +this.inputRef.current.value;
    const min = this.getMin();

    let nextValue = currentValue - this.getStep();
    if (nextValue < min) {
      nextValue = min;
    }

    this.lockButtons(nextValue);

    triggerChange(this.inputRef.current, nextValue);
  };

  lockButtons = nextValue => {
    const min = this.getMin();
    const max = this.getMax();

    if (nextValue <= min) {
      this.downButtonRef.current.classList.add('locked');
    } else {
      this.downButtonRef.current.classList.remove('locked');
    }

    if (nextValue >= max) {
      this.upButtonRef.current.classList.add('locked');
    } else {
      this.upButtonRef.current.classList.remove('locked');
    }
  };

  render() {
    const { rest } = extractThemableProps(this.props);

    return (
      <div className={[styles.NumberInput, 'NumberInput'].join(' ')}>
        <button
          type="button"
          aria-hidden
          onClick={this.stepDown}
          ref={this.downButtonRef}
          className="-inner-input-step-down-element icon-minus"
        />

        <label htmlFor={rest.id}>
          <input ref={this.inputRef} min={5} type="text" {...rest} />
        </label>

        <button
          type="button"
          aria-hidden
          onClick={this.stepUp}
          ref={this.upButtonRef}
          className="-inner-input-step-up-element icon-plus"
        />
      </div>
    );
  }
}

NumberInput.defaultProps = {
  readOnly: true
};
