const isSSR = typeof window === 'undefined';

export const ServiceworkerUnregister = ({ children }) => {
  if (!isSSR && window.navigator && 'serviceWorker' in window.navigator) {
    window.navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(registration => {
        registration.unregister();
      });
    });
  }
  return children;
};
