import React from 'react';
import { Formik } from 'formik';
import { useGetUserError } from '@deity/falcon-data';
import { useAddToCartMutation } from '@deity/falcon-shop-data';
import { productOptionsToForm, formProductOptionsToInput } from '@deity/falcon-front-kit';

const productCustomOptionsToForm = options => {
  return options.reduce((prev, current) => {
    return { ...prev, [current.optionId]: current.values[0].optionTypeId };
  }, {});
};

export const AddToCartFormProvider = props => {
  const { onSuccess, onError, onCompleted, initialValues, quantity, product, ...formikProps } = props;
  const defaultInitialValues = {
    qty: quantity,
    options: product.options ? productOptionsToForm(product.options) : [],
    bundleOptions: product.bundleOptions || [],
    customOptions: product.customOptions ? productCustomOptionsToForm(product.customOptions) : []
  };

  const [addToCart] = useAddToCartMutation({
    onCompleted
  });
  const [getUserError] = useGetUserError();

  return (
    <Formik
      initialValues={initialValues || defaultInitialValues}
      onSubmit={(values, { setSubmitting, setStatus }) =>
        addToCart({
          variables: {
            input: {
              sku: product.sku,
              qty: parseInt(values.qty.toString(), 10),
              options: formProductOptionsToInput(values.options),
              bundleOptions: undefined, // values.bundleOptions as any - TODO: add appropriate mapper
              customOptions: formProductOptionsToInput(values.customOptions)
            }
          }
        })
          .then(() => {
            setSubmitting(false);
            return onSuccess && onSuccess();
          })
          .catch(e => {
            const error = getUserError(e);
            if (error.length) {
              setStatus({ error });
              setSubmitting(false);

              if (onError) {
                onError(error);
              }
            }
          })
      }
      {...formikProps}
    />
  );
};
