import React, { useState } from 'react';
import { CartQuery } from '../../overrides/Cart/CartQuery';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { LastOrderUpdate } from '../Tracking/GoogleTagManager';

export const LastOrderUpdateTracking = withStoreConfiguration(({ storeConfiguration, product }) => {
  const [isPushed, setIsPushed] = useState(false);

  const getLatestItems = (cartItems = []) => {
    let alreadyInItems = false;
    const latestItems = [...cartItems].map(item => {
      if (item.sku === product.sku) {
        alreadyInItems = true;
        return {
          ...item,
          qty: item.qty + 1,
          rowTotal: item.price * (item.qty + 1),
          rowTotalInclTax: item.priceInclTax * (item.qty + 1)
        };
      }
      return item;
    });

    setIsPushed(true);

    if (!alreadyInItems) {
      return [
        ...latestItems,
        {
          ...product,
          qty: 1,
          price: product?.price?.special || product?.price?.regular
        }
      ];
    }
    return [...latestItems];
  };
  return (
    <CartQuery>
      {({ data, loading }) => {
        if (loading || !data?.cart) {
          return null;
        }
        const updatedData = {
          cart: {
            ...data.cart,
            items: getLatestItems(data?.cart?.items),
            itemsQty: data.cart.itemsQty + 1
          }
        };
        return !isPushed ? (
          <React.Fragment>{LastOrderUpdate({ storeConfiguration, ...updatedData, product })}</React.Fragment>
        ) : null;
      }}
    </CartQuery>
  );
});
