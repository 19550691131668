import { ApolloConsumer } from '@apollo/react-common'; // eslint-disable-line
import { I18n, T } from '@deity/falcon-i18n';
import React from 'react';
import { BackendConfigQuery } from '@deity/falcon-shop-data';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { Dropdown } from '../../ui/Dropdown/Dropdown';
import { LoaderContext } from '../../ui/Loader/LoaderContext';
import styles from './LanguageSwitcherContent.module.scss';

const { Consumer: LoaderConsumer } = LoaderContext;
export const LanguageSwitcherContent = withStoreConfiguration(({ section, storeConfiguration }) => {
  const stores =
    (storeConfiguration?.asam_store?.language_switch?.external_systems &&
      Object.values(JSON.parse(storeConfiguration.asam_store.language_switch.external_systems))) ||
    '';

  if (!stores) {
    return null;
  }
  return (
    <I18n>
      {(t, i18n) => (
        <ApolloConsumer>
          {() => (
            <LoaderConsumer>
              {({ showGlobalLoader }) => {
                let additionalClass = null;
                let dropdownListFullWidth = false;
                let dropdownSize = 'small';

                if (section === 'menu') {
                  additionalClass = styles.languageSwitcherContentInMenu;
                  dropdownListFullWidth = true;
                  dropdownSize = null;
                }
                return (
                  <div className={[styles.languageSwitcherContent, additionalClass].join(' ')}>
                    <div className={styles.languageSwitcherSection}>
                      <div className={styles.languageSwitcherContentLabel}>
                        <T id="languageSwitcher.language" />
                      </div>
                      <div className={styles.languageSwitcherContentInput}>
                        <BackendConfigQuery>
                          {({
                            data: {
                              backendConfig: {
                                shop: { activeStore }
                              }
                            }
                          }) => {
                            let selected = null;
                            let currentSuffix;
                            const items = [];

                            stores.forEach(store => {
                              if (store.storecode === activeStore) {
                                selected = store.storecode;
                                currentSuffix = store.suffix;
                              }
                              if (store.active === '1') {
                                items.push({
                                  value: store.storecode,
                                  label: store.name
                                });
                              }
                            });

                            return (
                              <Dropdown
                                listFullWidth={dropdownListFullWidth}
                                options={items}
                                onChange={storeViewCode => {
                                  showGlobalLoader(true);
                                  const { locale, suffix } = stores.find(
                                    ({ storecode }) => storecode === storeViewCode
                                  );
                                  i18n.changeLanguage(locale);
                                  const target = document.querySelectorAll(`[hreflang="${locale}"]`);
                                  const validateTarget = target && target[0] ? target[0].href : null;
                                  if (stores) {
                                    let redirectUrl = `/${suffix}/${window.location.pathname}`
                                      .replace('//', '/')
                                      .replace('///', '/');
                                    if (validateTarget === null && currentSuffix !== '/' && currentSuffix !== '') {
                                      redirectUrl = redirectUrl
                                        .replace(currentSuffix, '')
                                        .replace('//', '/')
                                        .replace('///', '/');
                                    }
                                    if (validateTarget !== null) {
                                      redirectUrl = validateTarget;
                                    }
                                    window.location.href = redirectUrl;
                                  }
                                }}
                                preSelected={selected}
                                size={dropdownSize}
                              />
                            );
                          }}
                        </BackendConfigQuery>
                      </div>
                    </div>
                  </div>
                );
              }}
            </LoaderConsumer>
          )}
        </ApolloConsumer>
      )}
    </I18n>
  );
});
