import React, { useState, useEffect } from 'react';
import { Box, themed } from '@deity/falcon-ui';

const DropdownContext = React.createContext({});

const DropdownInnerDOM = props => {
  const { children, onChange, dropdownRef, ...rest } = props;
  const [opened, setOpened] = useState(false);
  const onChangeAndClose = x => onChange && onChange(x);

  const hideSelect = () => {
    if (!dropdownRef.current.classList.contains('dropdownDragEnd')) {
      setOpened(false);
    } else {
      dropdownRef.current.classList.remove('dropdownDragEnd');
    }
  };

  useEffect(() => {
    window.addEventListener('click', hideSelect);
    return () => window.removeEventListener('click', hideSelect);
  }, []);

  return (
    <DropdownContext.Provider value={{ open: opened, onChange: onChangeAndClose }}>
      <Box
        {...rest}
        onClick={e => {
          if (!dropdownRef.current.classList.contains('dropdownDragEnd')) {
            setOpened(!opened);
          }
          e.stopPropagation();
        }}
        tabIndex={-1}
      >
        {children}
      </Box>
    </DropdownContext.Provider>
  );
};

export const Dropdown = themed({
  tag: DropdownInnerDOM,

  defaultTheme: {
    dropdown: {
      display: 'flex',
      borderRadius: 'medium',
      border: 'regular',
      borderColor: 'secondaryDark',

      css: ({ theme }) => ({
        userSelect: 'none',
        position: 'relative',
        ':hover': {
          borderColor: theme.colors.primary
        },
        ':focus': {
          outline: 'none'
        }
      })
    }
  }
});

const DropdownLabelInnerDOM = ({ children, ...rest }) => (
  <DropdownContext.Consumer>
    {({ open }) => (
      <Box {...rest} data-state={open ? 'active' : ''}>
        <Box>{children}</Box>
      </Box>
    )}
  </DropdownContext.Consumer>
);

export const DropdownLabel = themed({
  tag: DropdownLabelInnerDOM,

  defaultTheme: {
    dropdownLabel: {
      display: 'flex',
      py: 'xs',
      px: 'sm',
      fontSize: 'sm',
      justifyContent: 'space-between',
      css: {
        width: '100%',
        cursor: 'pointer'
      }
    }
  }
});

const DropdownMenuInnerDOM = props => (
  <DropdownContext.Consumer>
    {({ open }) => (open ? <Box as="ul" {...props} display={open ? 'block' : 'none'} /> : null)}
  </DropdownContext.Consumer>
);

export const DropdownMenu = themed({
  tag: DropdownMenuInnerDOM,

  defaultTheme: {
    dropdownMenu: {
      m: 'none',
      p: 'none',
      borderRadius: 'medium',
      boxShadow: 'subtle',
      bg: 'white',
      css: ({ theme }) => ({
        position: 'absolute',
        listStyle: 'none',
        top: 'calc(100% + 1px)',
        left: 0,
        right: 0,
        zIndex: theme.zIndex.dropDownMenu
      }),

      variants: {
        above: {
          css: {
            top: 'auto',
            bottom: 'calc(100% + 1px)'
          }
        }
      }
    }
  }
});

const DropdownMenuItemInnerDOM = props => (
  <DropdownContext.Consumer>
    {({ onChange }) => <Box as="li" {...props} onClick={() => onChange && onChange(props.value)} />}
  </DropdownContext.Consumer>
);

export const DropdownMenuItem = themed({
  tag: DropdownMenuItemInnerDOM,

  defaultProps: {
    value: undefined
  },

  defaultTheme: {
    dropdownMenuItem: {
      p: 'xs',
      css: ({ theme }) => ({
        cursor: 'pointer',
        ':hover': {
          background: theme.colors.primaryLight
        }
      })
    }
  }
});
