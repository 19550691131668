import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { List, ListItem, Box, Link, FlexLayout } from '@deity/falcon-ui';
import { T } from '@deity/falcon-i18n';
import { prettyScrollbars } from '@deity/falcon-ui-kit';
import { CartQuery } from '../../overrides/Cart/CartQuery';
import { Button } from '../../ui/Button/Button';
import { LeftForFreeshipping } from '../LeftForFreeshipping/LeftForFreeshipping';
import { Preloader } from '../../ui/Preloader/Preloader';
import { MiniCartSummary } from './MiniCartSummary/MiniCartSummary';
import { MiniCartProduct } from './MiniCartProduct/MiniCartProduct';
import styles from './MiniCart.module.scss';

export const MiniCart = ({ cart: { quoteCurrency, items }, setIsOpen, onAddedToWishlist }) => {
  return (
    <Box>
      {items.length > 0 && (
        <h4>
          <T id="miniCart.title" />
        </h4>
      )}
      <div className={items.length > 3 ? styles.MiniCartGradient : null}>
        <Box
          className={[styles.MiniCartItems, items.length > 3 ? styles.MiniCartExtraSpace : null].join(' ')}
          css={items.length > 3 ? props => ({ ...prettyScrollbars(props.theme) }) : null}
        >
          <List>
            {items
              .sort((foo, bar) => {
                // Free Items always last one
                return foo.freeItem - bar.freeItem;
              })
              .map(item => (
                <ListItem pb="none" key={item.sku}>
                  <MiniCartProduct
                    product={item}
                    currency={quoteCurrency}
                    onAddedToWishlist={onAddedToWishlist}
                    cartItems={[...items]}
                  />
                </ListItem>
              ))}
          </List>
        </Box>
        {!items.length && (
          <FlexLayout alignItems="center" flexDirection="column">
            <Button onClick={() => setIsOpen(false)}>
              <T id="miniCart.goShoppingButton" />
            </Button>
          </FlexLayout>
        )}
      </div>

      {items.length > 0 && (
        <React.Fragment>
          <CartQuery passLoading>
            {({ data: { cart }, loading }) => {
              return (
                <div className={styles.MiniCartTotals}>
                  {loading ? <Preloader cover /> : null}
                  {cart !== undefined ? (
                    <MiniCartSummary taxLabel={cart.taxLabel} totals={cart.totals} couponCode={cart.couponCode} />
                  ) : null}
                  {cart !== undefined ? <LeftForFreeshipping amount={cart.leftForFreeshipping} /> : null}
                </div>
              );
            }}
          </CartQuery>
          <div className={styles.MiniCartProceedToCheckout}>
            <Button size="small" asLink path="/checkout" onClick={() => setIsOpen(false)}>
              <T id="miniCart.checkout" />
            </Button>
          </div>
          <div className={styles.MiniCartGoToCart}>
            <Link
              as={RouterLink}
              to="/cart"
              onClick={() => setIsOpen(false)}
              className="Link LinkLarge LinkMediumWeight LinkPurple"
            >
              <T id="miniCart.goToBasket" />
            </Link>
          </div>
        </React.Fragment>
      )}
    </Box>
  );
};
