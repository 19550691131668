import React from 'react';
import InnerDangerouslyHtml from 'dangerously-set-html-content';
import sanitizeHtml from 'sanitize-html';

export const InnerHTML = ({ html, span = false, allowScript = false, allowStyle = false, ...rest }) => {
  const sanitizeOptions = {
    allowedTags: [
      'b',
      'i',
      'em',
      'strong',
      'a',
      'p',
      'blockquote',
      'strong',
      'img',
      'div',
      'li',
      'ul',
      'ol',
      'span',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'table',
      'tbody',
      'tr',
      'td',
      'th'
    ],
    allowedAttributes: {
      '*': ['alt', 'data-*', 'class', 'id'],
      a: ['href'], // eslint-disable-line
      img: ['src'],
      script: ['src', 'type']
    }
  };

  if (allowScript) {
    sanitizeOptions.allowedTags.push('script');
    sanitizeOptions.allowVulnerableTags = true;
  }

  if (allowStyle) {
    sanitizeOptions.allowedTags.push('style');
    sanitizeOptions.allowVulnerableTags = true;
  }

  const sanitizedHtml = sanitizeHtml(html, sanitizeOptions);
  const role = rest?.onClick ? 'presentation' : null;

  if (span) {
    return (
      /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
      <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} role={role} {...rest} />
    );
  }

  // special method, if js/css is involed, as js doesn't run from dangerouslySetInnerHTML
  if (allowScript || allowStyle) {
    return (
      /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
      <div role={role} {...rest}>
        <InnerDangerouslyHtml html={sanitizedHtml} />
      </div>
    );
  }
  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} role={role} {...rest} />
  );
};
