import { PageviewTracking } from './Pageview';

export const ProductContentTracking = ({ storeConfiguration, open = null }) => {
  let dataLayerData;

  const pagePath = typeof window !== 'undefined' ? window.location.href : '';

  if (open) {
    dataLayerData = {
      key: `product_content_${open}`,
      event: 'Generic Event',
      event_name: 'product_content_open',
      product_content_open: {
        page_location: pagePath, // e.g. 'https://www.asambeauty.com/clear-skin-und-better-skin-set.html'

        event_category: 'product_content',
        event_action: pagePath, // e.g. 'https://www.asambeauty.com/clear-skin-und-better-skin-set.html'
        event_label: 'open'
      }
    };
  } else {
    dataLayerData = {
      key: `product_content_${open}`,
      event: 'Generic Event',
      event_name: 'product_content_close',
      product_content_close: {
        page_location: pagePath, // e.g. 'https://www.asambeauty.com/clear-skin-und-better-skin-set.html'

        event_category: 'product_content',
        event_action: pagePath, // e.g. 'https://www.asambeauty.com/clear-skin-und-better-skin-set.html'
        event_label: 'close'
      }
    };
  }

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
