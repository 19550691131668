import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Mutation } from '@deity/falcon-data';

export const SIGN_IN_MUTATION = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      loggedIn
      customerId
    }
  }
`;

export class SignInMutation extends Mutation {
  static defaultProps = {
    mutation: SIGN_IN_MUTATION,
    refetchQueries: ['Cart', 'MiniCart', 'CustomerWithAddresses', 'Customer', 'ProductsInWishlist']
  };
}

export const useSignMutation = (options = {}) =>
  useMutation(SIGN_IN_MUTATION, {
    refetchQueries: ['Cart', 'MiniCart', 'CustomerWithAddresses', 'Customer', 'ProductsInWishlist'],
    ...options
  });
