import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoad } from '../../../helpers/LazyLoad/LazyLoad';
import { Button } from '../../../ui/Button/Button';
import { Labels } from '../../Labels/Labels';
import { CmsRowHeadline } from '../../Cms/Modules/Components/CmsRowHeadline';
import styles from './ProductBanner.module.scss';

export const ProductBanner = ({
  headlineContent,
  header,
  buttonText,
  buttonLink,
  image,
  price,
  specialPrice,
  pricePerUnit
}) => (
  <LazyLoad>
    {headlineContent.row_headline ? (
      <div className={styles.ProductBannerRowHeadline}>
        <CmsRowHeadline item={headlineContent} />
      </div>
    ) : null}
    <Link to={buttonLink}>
      <div className={styles.ProductBanner}>
        <div className={styles.ProductBannerBg} style={{ backgroundImage: `url(${image})` }} />
        <div className={styles.ProductBannerInfo}>
          <div className={styles.ProductBannerText}>{header}</div>
          {buttonLink ? (
            <div className={styles.ProductBannerButton}>
              <Button size="small">{buttonText}</Button>
            </div>
          ) : null}
        </div>
        {price ? (
          <div className={styles.ProductBannerLabel}>
            <Labels
              price={price}
              specialPrice={specialPrice}
              type="purple"
              size="large"
              pricePerUnit={pricePerUnit}
              rotated
              transparent
              cms
            />
          </div>
        ) : null}
      </div>
    </Link>
  </LazyLoad>
);

ProductBanner.propTypes = {
  /** Set header text of banner */
  header: PropTypes.string,
  /** Set text inside button */
  buttonText: PropTypes.string,
  /** Set button href link */
  buttonLink: PropTypes.string,
  /** Set image visible on background on Desktop */
  image: PropTypes.string,
  /** Set product price that will be visible in label */
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Set product special price that will be visible in label */
  specialPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
