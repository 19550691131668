import React from 'react';
import { NavigationMobileTracking } from '../../../elements/Tracking/GoogleTagManager';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './MobileMenuTrigger.module.scss';

export const MobileMenuTrigger = withStoreConfiguration(({ storeConfiguration, open }) => (
  <div
    className={styles.MobileMenuIcon}
    onClick={() => {
      open();
      NavigationMobileTracking({ storeConfiguration, key: 'burger_menu_mobile' });
    }}
    aria-hidden
  >
    <span />
    <span />
    <span />
  </div>
));
