// TODO: once StoreContext is merged uncomment the code to use the config helper
// import { useStoreContext } from '../../../Store/StoreProvider';

export const CheckDays = storeConfiguration => {
  // const { getStoreConfigInt } = useStoreContext()
  // const days = getStoreConfigInt('asam_shipping.estimated_delivery.days')
  const days = storeConfiguration?.asam_shipping?.estimated_delivery?.days
    ? parseInt(storeConfiguration.asam_shipping.estimated_delivery.days, 10)
    : 2;
  let addDay = 0;
  const date = new Date();
  while (addDay < days) {
    date.setDate(date.getDate() + 1);
    if (date.getDay() !== 0 && date.getDay() !== 7) addDay++;
  }
  return date;
};
