import {
  LocaleProvider,
  OnlyUnauthenticatedRoute,
  ProtectedRoute,
  ScrollToTop,
  SearchProvider
} from '@deity/falcon-front-kit';
import { ThemeEditorState } from '@deity/falcon-theme-editor';
import { ThemeProvider } from '@deity/falcon-ui';
import { AppLayout } from '@deity/falcon-ui-kit';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import logo from 'src/assets/logo.png';
import loadable from 'src/components/loadable';
import { I18n } from '@deity/falcon-i18n';
import { InitQueryLoader } from './theme/asam/components/elements/Init';
import { ErrorBoundary } from './theme/asam/components/overrides/Errors/ErrorBoundary';
import NoSsrRoute from './theme/asam/components/app/NoSsrRoute';
import { deityGreenTheme, globalCss } from './theme';
import DynamicRoute from './theme/asam/components/pages/DynamicRoute';
import { Loader } from './theme/asam/components/ui/Loader/Loader';
import { GlobalStateProvider } from './theme/asam/components/helpers/GlobalState/GlobalState';
import { LoaderContext } from './theme/asam/components/ui/Loader/LoaderContext';
import { ServiceworkerUnregister } from './theme/asam/components/app/ServiceworkerUnregister';
import { StoreDetect } from './theme/asam/components/helpers/StoreConfiguration/StoreDetect';
import { isAppMobile } from './theme/asam/components/helpers/AppMobile/AppMobile';
import { Store } from './theme/asam/components/elements/Store/Store';
import { CtaBanner } from './theme/asam/components/elements/CtaBanner/CtaBanner';
import { LoadingBar } from './theme/asam/components/elements/LoadingBar/LoadingBar';
import SmartBanner from './theme/asam/components/elements/SmartBanner/SmartBanner';
import { Header } from './theme/asam/components/sections/Header/Header';
import { TelljaReferrer } from './theme/asam/components/elements/Tellja/TelljaReferrer';

// eslint-disable-next-line no-unused-expressions
require('asam-fonts');
require('./assets/css/styles.scss');

const HeadMetaTags = ({ path }) => {
  let pageTitleId = 'domain.seoTitle';
  let pageNoindex = false;

  if (path === '/cart') {
    pageTitleId = 'cart.seoTitle';
    pageNoindex = true;
  } else if (
    path.indexOf('account') !== -1 ||
    path.indexOf('amazon_pay') !== -1 ||
    path.indexOf('amsociallogin') !== -1 ||
    path.indexOf('checkout') !== -1 ||
    path.indexOf('contacts') !== -1 ||
    path.indexOf('forgot-password') !== -1 ||
    path.indexOf('newsletter/not-confirmed') !== -1 ||
    path.indexOf('search') !== -1 ||
    path.indexOf('sign-in') !== -1 ||
    path.indexOf('socialconnect') !== -1 ||
    path.indexOf('easym/sso') !== -1
  ) {
    pageNoindex = true;
  }

  return (
    <I18n>
      {t => (
        <Helmet defaultTitle={t(pageTitleId)} titleTemplate="%s">
          <meta name="description" content={t('domain.seoDescription')} />
          <meta name="theme-color" content="#fff" />
          <meta name="format-detection" content="telephone=yes" />
          <meta property="og:title" content={t('domain.seoTitle')} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={t('domain.seoDescription')} />
          <meta property="og:url" content={t('domain.base')} />
          <meta property="og:image" content={logo} />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="107" />
          {pageNoindex ? <meta name="robots" content="noindex" /> : null}
          <body className={isAppMobile ? 'mobileAppView' : ''} />
        </Helmet>
      )}
    </I18n>
  );
};

const Home = loadable(() => import(/* webpackChunkName: "home" */ './theme/asam/components/pages/Home/Home'));
const Account = loadable(() =>
  import(/* webpackChunkName: "account" */ './theme/asam/components/pages/Account/Account')
);
const SignIn = loadable(() => import(/* webpackChunkName: "account" */ './theme/asam/components/pages/SignIn/SignIn'));
const ForgotPassword = loadable(() =>
  import(
    /* webpackChunkName: "account" */ './theme/asam/components/elements/CustomerAccount/ForgotPassword/ForgotPassword'
  )
);
const ResetPasswordForm = loadable(() =>
  import(
    /* webpackChunkName: "account" */ './theme/asam/components/elements/CustomerAccount/ForgotPassword/ForgotPasswordForm'
  )
);
const Logout = loadable(() =>
  import(/* webpackChunkName: "account" */ './theme/asam/components/pages/Account/Logout/Logout')
);
const Cart = loadable(() => import(/* webpackChunkName: "cart" */ './theme/asam/components/pages/Cart/Cart'));
const Checkout = loadable(() =>
  import(/* webpackChunkName: "checkout" */ './theme/asam/components/pages/Checkout/Checkout')
);
const CheckoutConfirmation = loadable(() =>
  import(/* webpackChunkName: "checkout" */ './theme/asam/components/pages/Checkout/CheckoutSuccess/CheckoutSuccess')
);
const CheckoutFailure = loadable(() =>
  import(/* webpackChunkName: "checkout" */ './theme/asam/components/pages/Checkout/CheckoutFailure/CheckoutFailure')
);
const Search = loadable(() => import(/* webpackChunkName: "search" */ './theme/asam/components/pages/Search/Search'));
const NewsletterCreator = loadable(() =>
  import(/* webpackChunkName: "newsletter" */ './theme/asam/components/pages/Newsletter/NewsletterCreator')
);
const NewsletterNotConfirmed = loadable(() =>
  import(/* webpackChunkName: "newsletter" */ './theme/asam/components/pages/Newsletter/NewsletterNotConfirmed')
);
const NewsletterSubscriberUnsubscribe = loadable(() =>
  import(/* webpackChunkName: "newsletter" */ './theme/asam/components/pages/Newsletter/NewsletterUnsubscribe')
);
const EasymSso = loadable(() => import(/* webpackChunkName: "easym" */ './theme/asam/components/pages/Easym/Sso'));

const FacebookConnect = loadable(() =>
  import(/* webpackChunkName: "connectors" */ './theme/asam/components/pages/Facebook/FacebookConnect')
);
const MobileViews = loadable(() =>
  import(/* webpackChunkName: "mobile" */ './theme/asam/components/pages/MobileViews/MobileViews')
);
const LoginAsCustomer = loadable(() =>
  import(/* webpackChunkName: "connectors" */ './theme/asam/components/pages/LoginAsCustomer/LoginAsCustomer')
);
const AmazonCheckout = loadable(() =>
  import(/* webpackChunkName: "payments" */ './theme/asam/components/pages/AmazonV2/Pay')
);
const AmazonCheckoutComplete = loadable(() =>
  import(/* webpackChunkName: "payments" */ './theme/asam/components/pages/AmazonV2/Complete')
);
const AmazonCustomerLogin = loadable(() =>
  import(/* webpackChunkName: "connectors" */ './theme/asam/components/pages/AmazonV2/CustomerLogin')
);
const AmazonCheckoutLogin = loadable(() =>
  import(/* webpackChunkName: "connectors" */ './theme/asam/components/pages/AmazonV2/CheckoutLogin')
);
const PayPalExpressCheckoutLogin = loadable(() =>
  import(/* webpackChunkName: "connectors" */ './theme/asam/components/pages/PayPal/CheckoutLogin')
);
const PayPalExpressCheckout = loadable(() =>
  import(/* webpackChunkName: "connectors" */ './theme/asam/components/pages/PayPal/Checkout')
);
const StripePaymentMethod = loadable(() =>
  import(/* webpackChunkName: "payments" */ './theme/asam/components/pages/Stripe/PaymentMethod')
);
const PayUCheckoutComplete = loadable(() =>
  import(/* webpackChunkName: "payments" */ './theme/asam/components/pages/PayU/Complete')
);
const Contacts = loadable(() =>
  import(/* webpackChunkName: "contact" */ './theme/asam/components/pages/Cms/Contacts/Contacts')
);
const Footer = loadable(() =>
  import(/* webpackChunkName: "general" */ './theme/asam/components/sections/Footer/Footer')
);
const ScrollToTopButton = loadable(() =>
  import(/* webpackChunkName: "general" */ './theme/asam/components/ui/ScrollToTopButton/ScrollToTopButton')
);
const Notifications = loadable(() =>
  import(/* webpackChunkName: "general" */ './theme/asam/components/elements/Notifications/Notifications')
);
const Newsletter = loadable(() =>
  import(/* webpackChunkName: "newsletter" */ './theme/asam/components/sections/Newsletter/Newsletter')
);
const PreFooter = loadable(() =>
  import(/* webpackChunkName: "general" */ './theme/asam/components/sections/PreFooter/PreFooter')
);
const WishlistPopup = loadable(() =>
  import(/* webpackChunkName: "general" */ './theme/asam/components/elements/Wishlist/WishlistPopup/WishlistPopup')
);
const Geoip = loadable(() => import(/* webpackChunkName: "geoip" */ './theme/asam/components/elements/Geoip/Geoip'));
const CookieConsent = loadable(() =>
  import(/* webpackChunkName: "general" */ './theme/asam/components/elements/CookieConsent/CookieConsent')
);
const SocialLoginLogin = loadable(() =>
  import(/* webpackChunkName: "socialLogin" */ './theme/asam/components/pages/SocialLogin/CustomerLogin')
);

const SmartBannerIos = loadable(() =>
  import(/* webpackChunkName: "general" */ './theme/asam/components/elements/SmartBanner/SmartBannerIos')
);

const App = () => {
  const [globalLoader, showGlobalLoader] = useState(false);
  const { Provider: LoaderProvider } = LoaderContext;
  return (
    <GlobalStateProvider>
      <LoaderProvider value={{ globalLoader, showGlobalLoader }}>
        <InitQueryLoader />
        <StoreDetect />
        <ErrorBoundary>
          <LocaleProvider>
            <ServiceworkerUnregister>
              <ScrollToTop />
              <ThemeEditorState initial={deityGreenTheme}>
                {props => (
                  <SearchProvider>
                    <ThemeProvider theme={props.theme} globalCss={globalCss}>
                      <LoadingBar />
                      <Notifications />
                      <SmartBanner />
                      <Route
                        render={() => (
                          <Store>
                            {({ pathname }) => (
                              <>
                                <HeadMetaTags key={`hmt-${pathname}`} path={pathname} />

                                <CtaBanner key={`ctab-${pathname}`} path={pathname} />
                              </>
                            )}
                          </Store>
                        )}
                      />
                      <AppLayout>
                        <Switch>
                          <Route path="/checkout" render={() => (isAppMobile ? null : <Header hideMenu />)} />
                          <Route path="/" render={() => (isAppMobile ? null : <Header />)} />
                        </Switch>
                        <ErrorBoundary>
                          <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/forgot-password" component={ForgotPassword} />
                            <Route exact path="/search" component={Search} />
                            <OnlyUnauthenticatedRoute exact path="/sign-in" component={SignIn} />
                            <NoSsrRoute exact path="/cart" component={Cart}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/checkout" component={Checkout}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/checkout/confirmation" render={() => <CheckoutConfirmation />}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/checkout/failure" component={CheckoutFailure}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/pdp/:sku/view/:active" component={MobileViews}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/logout" component={Logout}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute
                              exact
                              path="/loginascustomer/login/index/secret/:secret"
                              component={LoginAsCustomer}
                            >
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute
                              exact
                              path="/paypal_express/login/checkout/"
                              component={PayPalExpressCheckoutLogin}
                            >
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/paypal_express/checkout/" component={PayPalExpressCheckout}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/amazon_pay/login/authorize/" component={AmazonCustomerLogin}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/amazon_pay/login/checkout/" component={AmazonCheckoutLogin}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/amazon_pay/checkout/" component={AmazonCheckout}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute
                              exact
                              path="/amazon_pay/checkout/completeSession/"
                              component={AmazonCheckoutComplete}
                            >
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute
                              exact
                              path="/stripe/payment/index/payment_method/:paymentMethod"
                              component={StripePaymentMethod}
                            >
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute
                              exact
                              path="/payu/checkout/completeSession/:cart"
                              component={PayUCheckoutComplete}
                            >
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/contacts" component={Contacts}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/newsletter/not-confirmed" component={NewsletterNotConfirmed}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/newsletter/subscriber/confirm/" component={NewsletterCreator}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute
                              exact
                              path={['/newsletter/subscriber/unsubscribe', '/newsletter/subscriber/unsubscribe']}
                              component={NewsletterSubscriberUnsubscribe}
                            >
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/easym/sso" component={EasymSso}>
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute
                              exact
                              path="/socialconnect/facebook/connect/:params?"
                              component={FacebookConnect}
                            >
                              <Route />
                            </NoSsrRoute>
                            <NoSsrRoute path="/account" component={Account}>
                              <ProtectedRoute />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/create-password-form/" component={ResetPasswordForm}>
                              <OnlyUnauthenticatedRoute />
                            </NoSsrRoute>
                            <NoSsrRoute exact path="/amsociallogin/social/login/:params?" component={SocialLoginLogin}>
                              <Route />
                            </NoSsrRoute>
                            <DynamicRoute />
                          </Switch>
                          <Loader />
                        </ErrorBoundary>
                        <Switch>
                          <Route
                            exact
                            path="/checkout"
                            render={prop => (isAppMobile ? null : <Footer {...prop} secondary />)}
                          />
                          <Route path="/newsletter" render={prop => (isAppMobile ? null : <Footer {...prop} />)} />
                          <Route path="/account" render={prop => (isAppMobile ? null : <Footer {...prop} />)} />
                          <Route
                            path="/"
                            render={prop =>
                              isAppMobile ? null : (
                                <>
                                  <Newsletter />
                                  <PreFooter />
                                  <Footer {...prop} />
                                </>
                              )
                            }
                          />
                        </Switch>
                        <Route
                          render={location => {
                            const params = location.location.search;
                            const isShowPopup = params === '?show-popup';

                            return isShowPopup ? <WishlistPopup /> : null;
                          }}
                        />
                      </AppLayout>
                      <Geoip />
                      {isAppMobile ? null : <ScrollToTopButton />}
                    </ThemeProvider>
                  </SearchProvider>
                )}
              </ThemeEditorState>
              <SmartBannerIos />
              <CookieConsent />
              <TelljaReferrer />
            </ServiceworkerUnregister>
          </LocaleProvider>
        </ErrorBoundary>
      </LoaderProvider>
    </GlobalStateProvider>
  );
};

export default App;
