import { Image } from '@deity/falcon-ui';
import React from 'react';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';

export const ProductImage = ({ product, alternativeImage, width, height }) => {
  const productImage = product && product.productImage;

  // return img with srcset attribute if retina image is given
  if (productImage && productImage.retina) {
    const srcset = `${productImage.default} 1x, ${productImage.retina} 2x`;
    return <Image src={productImage.default} alt={product.name} srcSet={srcset} width={width} height={height} />;
  }

  const image = (productImage && productImage.default) || alternativeImage;

  return <Image src={image} alt={product && product.name} width={width} height={height} />;
};

export const ProductImageAlternative = withStoreConfiguration(
  ({ storeConfiguration, product, alternativeImage, width, height }) => {
    const { name, productImage } = product;
    const allowWebP = storeConfiguration?.asam_imagecompression?.webp?.enabled === '1'; // check with storeConfiguration
    const regularImage = productImage.default || productImage.retina || alternativeImage;
    const webpImage = productImage.defaultWebp || productImage.retinaWebp;
    const srcSet =
      productImage.default && productImage.retina && `${productImage.default} 1x, ${productImage.retina} 2x`;
    const srcSetWebP =
      productImage.defaultWebp &&
      productImage.retinaWebp &&
      `${productImage.defaultWebp} 1x, ${productImage.retinaWebp} 2x`;

    return (
      <picture>
        {allowWebP && webpImage && <source srcSet={srcSetWebP} type="image/webp" />}
        {srcSet && !allowWebP && !webpImage && <source srcSet={srcSet} />}

        <img src={regularImage} alt={name} width={width} height={height} />
      </picture>
    );
  }
);

export const ProductImageCart = withStoreConfiguration(
  ({ storeConfiguration, product, alternativeImage, width, height }) => {
    const { name, thumbnailUrl, thumbnailWebpUrl } = product;
    const allowWebP = storeConfiguration?.asam_imagecompression?.webp?.enabled === '1';
    const regularImage = thumbnailUrl || alternativeImage;

    return (
      <picture>
        {allowWebP && thumbnailWebpUrl && <source srcSet={thumbnailWebpUrl} type="image/webp" />}
        {thumbnailUrl && <source srcSet={thumbnailUrl} />}

        <img src={regularImage} alt={name} width={width} height={height} />
      </picture>
    );
  }
);

export const ProductImageAboCart = ({ product, alternativeImage, width, height }) => {
  const { name, gallery } = product;
  const regularImage = gallery?.[0]?.thumbnail || alternativeImage;

  return (
    <picture>
      <img src={regularImage} alt={name} width={width} height={height} />
    </picture>
  );
};
