import { CustomerQuery as CustomerQueryBase } from '@deity/falcon-shop-data/dist/Customer/CustomerQuery';
import gql from 'graphql-tag';

export const GET_CUSTOMER_GROUP_QUERY = gql`
  query Customer {
    customer {
      id
      groupId
      websiteId
    }
  }
`;

export const GET_CUSTOMER = gql`
  query Customer {
    customer {
      id
      firstname
      lastname
      email
      websiteId
      dob
      prefix
      customerNo
    }
  }
`;

export class CustomerQuery extends CustomerQueryBase {
  static defaultProps = { ...super.defaultProps, query: GET_CUSTOMER, ssr: false };
}
