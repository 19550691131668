import { PageviewTracking } from './Pageview';

export const InternalSearchTracking = ({
  storeConfiguration,
  searchTerm,
  searchResults = undefined,
  searchMethod = 'search_query',
  bufferSeconds = 30
}) => {
  const dataLayerData = {
    key: `search_query_${searchTerm}_${searchMethod}`,
    event: 'Generic Event',
    event_name: 'internal_search',
    internal_search: {
      search_term: searchTerm,
      search_results: searchResults,
      event_category: 'internal search',
      search_method: searchMethod,
      event_label: searchTerm
    }
  };
  return PageviewTracking({ storeConfiguration, dataLayerData, bufferSeconds });
};
