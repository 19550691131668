import React, { useEffect } from 'react';
import { InnerHTML } from '@deity/falcon-ui-kit';
import styles from './CmsRichText.module.scss';

export const CmsRichtext = ({ text, smallPrint, itemProp }) => {
  useEffect(() => {
    const x = document.getElementsByClassName(styles.CmsRichtext);
    const setExternal = item => {
      if (item.hostname && !item.hostname.includes('asambeauty')) {
        item.setAttribute('rel', 'noopener noreferrer nofollow');
        item.setAttribute('target', '_blank');
      }
    };

    let i;
    for (i = 0; i < x.length; i++) {
      x[i].innerHTML = x[i].innerHTML.replace('&amp;shy;', `&shy;`);
      if (x[i].getElementsByTagName('a')) {
        x[i].getElementsByTagName('a').forEach(setExternal);
      }
    }

    // Add Table Slider on touch
    const tableSlider = document.querySelector('table');
    let startX, tableScrollLeft;

    const startMoving = e => {
      e.preventDefault();
      const slideX = e.pageX - tableSlider.offsetLeft;
      const scroll = slideX - startX;
      tableSlider.scrollLeft = tableScrollLeft - scroll;
    };

    const stopDragging = () => {
      tableSlider?.removeEventListener('mousemove', startMoving);
      tableSlider?.removeEventListener('mouseup', stopDragging);
    };

    const startDragging = e => {
      startX = e.pageX - tableSlider.offsetLeft;
      tableScrollLeft = tableSlider.scrollLeft;
      // Add the event listeners to Table Slider
      tableSlider?.addEventListener('mousemove', startMoving);
      tableSlider?.addEventListener('mouseup', stopDragging);
    };
    tableSlider?.addEventListener('mousedown', startDragging);
  }, []);

  return (
    <>
      {text ? <InnerHTML html={text} className={styles.CmsRichtext} itemProp={itemProp} /> : null}
      {smallPrint ? <InnerHTML className={styles.CmsSmallPrint} html={smallPrint} /> : null}
    </>
  );
};
