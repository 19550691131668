import { PageviewTracking } from './Pageview';

export const SearchTracking = ({ storeConfiguration, products }) => {
  const productList = [];

  products?.forEach(item => {
    productList.push({
      name: item.name, // e.g. 'Lipstick ...'
      brand: `${item.brand} - ${item.cosmeticlineValue}`, // e.g. 'M. Asam - Magic Finish'
      sku: item.sku, // e.g. '41250'
      price: item.price?.special || item.price?.regular, // e.g. '24.75'
      category: item.categoryPath, // e.g. 'M. Asam/Geschenke/ Limitierte Editionen'
      imageURL: item?.productImage?.default
    });
  });

  const dataLayerData = {
    page_type: 'search',
    currency: storeConfiguration?.currency?.options?.default,
    products: productList
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
