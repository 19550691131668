import { DynamicRoute } from '@deity/falcon-front-kit';
import { Loader } from '@deity/falcon-ui-kit';
import React from 'react';
import loadable from 'src/components/loadable';
import NotFound from '../overrides/Errors/Error/NotFound';

const Category = loadable(() => import(/* webpackChunkName: "category" */ './Category/Category'));
const Product = loadable(() => import(/* webpackChunkName: "product" */ './Product/Product'));
const CmsPage = loadable(() => import(/* webpackChunkName: "cmsPage" */ './Cms/Page'));

export default props => (
  <DynamicRoute
    {...props}
    loaderComponent={Loader}
    components={{
      'shop-category': Category,
      'shop-product': Product,
      'cms-page': CmsPage
    }}
    notFound={NotFound}
  />
);
