import React from 'react';
import { Portal, Box } from '@deity/falcon-ui';
import styles from './Loader.module.scss';
import { LoaderContext } from './LoaderContext';

export const Loader = () => {
  const { Consumer } = LoaderContext;

  return (
    <Consumer>
      {({ globalLoader }) => (
        <Box as={Portal}>
          <div className={[styles.Loader, globalLoader ? styles.LoaderVisible : null].join(' ')} />
        </Box>
      )}
    </Consumer>
  );
};
