import React, { useState, useEffect } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import { useLocation } from 'react-router-dom';
import { Button } from '@deity/falcon-ui';
import { Lightbox } from '../../Lightbox/Lightbox';
import playIcon from '../../../../../../assets/images/product/play.png';
import { ProductLabels } from '../index';
import { Badge } from '../../Badge';
import { supportWebP } from '../../../helpers/BrowserSupport/BrowserSupport';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import useYouTubePlaceholder from '../../../hooks/YouTubePlaceholder';
import loadable from '../../../../../../components/loadable';
import useCheckBreakpoints from '../../../hooks/CheckBreakpoint';
import { CmsCfVideo } from '../../Cms/Modules/Components/CmsCfVideo';
import { Video } from './Video/Video';
import styles from './Gallery.module.scss';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../../ui/Modal/Modal'));

const TYPE_VIDEO = 'external-video';
const TYPE_VIDEO_NAVCONNECT = 'navconnect-video';
const TYPE_YOUTUBE = 'youtube';
const TYPE_CONTENTFUL = 'videos.ctfassets';

export const Gallery = withStoreConfiguration(({ storeConfiguration, items, alt, labels }) => {
  const { loadPlaceholder } = useYouTubePlaceholder();
  const webpSupport = supportWebP();

  const [open, setOpen] = useState(false);
  const [showLabel, setShowLabel] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openVideo, setOpenVideo] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  const allowWebP = storeConfiguration?.asam_imagecompression?.webp?.enabled === '1';

  const query = new URLSearchParams(useLocation().search);
  const trackingConsent = query.get('trackingConsent');

  let galleryImageWidth = 400;
  let galleryImageHeight = 400;
  const { isDesktop } = useCheckBreakpoints();

  if (isDesktop) {
    galleryImageWidth = 580;
    galleryImageHeight = 580;
  }

  items.map(item => {
    item.original = item.full;
    item.title = alt;
    item.originalAlt = alt;
    item.thumbnailAlt = alt;

    return null;
  });

  const images = [];
  const additionalGalleryClasses = [];

  let video = null;
  let videoIndex = null;
  let videoModalContent = null;

  useEffect(() => {
    const hasVideo = item => {
      return item.type === TYPE_VIDEO_NAVCONNECT;
    };

    items.forEach(item => {
      if (item.type === TYPE_VIDEO_NAVCONNECT) {
        setLoadingData(false);
      } else {
        item.thumbnail = allowWebP && webpSupport ? item.webpThumbnail : item.thumbnail;
        item.full = allowWebP && webpSupport ? item.webpFull : item.full;
      }
    });

    if (!items.some(hasVideo)) {
      setLoadingData(false);
    }

    items.filter(item => item.type !== TYPE_VIDEO);
  }, []);

  items.forEach((item, index) => {
    if (item.type === TYPE_VIDEO_NAVCONNECT) {
      item.thumbnail = playIcon;
      video = item;
      videoIndex = index;
      videoModalContent = <Video url={video.embedUrl} videoTitle={video.title} isGallery />;
    } else {
      images.push(item);
    }
  });

  if (trackingConsent === 'false') {
    items = items.filter(item => item.type !== TYPE_VIDEO_NAVCONNECT);
  }

  const openVideoModal = () => {
    setOpenVideo(true);
  };

  const closeVideoModal = () => {
    setOpenVideo(false);
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
      /* HIDE PRODUCT LABEL IN HERE */
      if (labels && slider.track.details.abs !== 0) {
        setShowLabel(styles.HideLabel);
      } else {
        setShowLabel(null);
      }
      setCurrentIndex(slider.track.details.abs);
    },
    created(slider) {
      setLoaded(true);

      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          if (idx !== videoIndex) {
            setOpen(true);
            setCurrentIndex(idx);
          } else if (slide?.className?.indexOf('cfAutoPlay') === -1) {
            openVideoModal();
          }
        });
      });
    }
  });

  useEffect(() => {
    instanceRef?.current?.update();
  }, [items]);

  const updateSlider = idx => {
    if (idx !== videoIndex) {
      instanceRef.current?.moveToIdx(idx);
    } else {
      openVideoModal();
    }
  };

  return (
    <React.Fragment>
      {loadingData && (
        <>
          <div id="GalleryWrapper" className={[styles.Gallery].join(' ')}>
            <div className="keen-slider image-gallery-slides">
              <div className="keen-slider__slide image-gallery-slide">
                <div className="image-gallery-image">
                  <img
                    src={`${allowWebP && webpSupport ? items?.[0]?.webpFull : items?.[0]?.full}`}
                    alt={`${items?.[0]?.originalAlt}`}
                    width={galleryImageWidth}
                    height={galleryImageHeight}
                  />
                </div>
              </div>
            </div>

            <div className="image-gallery-bullets bottom">
              {items.map((item, index) => {
                return (
                  // eslint-disable-next-line react/button-has-type
                  <button
                    key={`button-${item?.full}`}
                    className={`image-gallery-bullet${index === 0 ? ' active' : ''}`}
                  />
                );
              })}
            </div>

            {labels &&
              labels
                .filter(item => item.badge === true)
                .slice(0, 1)
                .map(item => {
                  return (
                    <div
                      className={[styles.BadgeWrapper, styles.ShowLabel, showLabel].join(' ')}
                      key={`badge-${item.text}-${item.type}`}
                    >
                      <Badge text={item.text} type={item.type} size="large" />
                    </div>
                  );
                })}
          </div>
        </>
      )}
      {!loadingData && (
        <>
          <div id="GalleryWrapper" className={[styles.Gallery, additionalGalleryClasses].join(' ')}>
            <React.Fragment>
              {labels && labels.filter(item => !item.badge) !== 0 && (
                <div className={[styles.ShowLabel, showLabel].join(' ')}>
                  <ProductLabels labels={labels} config="product" />
                </div>
              )}
              <div ref={sliderRef} className="keen-slider image-gallery-slides">
                {items.map((item, index) => {
                  const isAutoplayActive = item?.videoAutoplay === '1';
                  if (
                    item.type === TYPE_VIDEO_NAVCONNECT &&
                    item.embedUrl?.indexOf(TYPE_CONTENTFUL) !== -1 &&
                    isAutoplayActive
                  ) {
                    return (
                      <div
                        className={`keen-slider__slide image-gallery-slide video-holder ${
                          isAutoplayActive ? 'cfAutoPlay' : ''
                        }`}
                      >
                        <div className="image-gallery-image image-gallery-video-autoplay">
                          <CmsCfVideo
                            videoUrl={`${item.embedUrl}`}
                            isAutoplayActive={isAutoplayActive}
                            videoThumb={`${item.full}`}
                            isGallery
                            clickHandler={openVideoModal}
                          />
                        </div>
                      </div>
                    );
                  }

                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="keen-slider__slide image-gallery-slide" key={`slide_${index}`}>
                      {!loadPlaceholder && item.type === TYPE_VIDEO_NAVCONNECT ? (
                        <div className="icon-youtube" />
                      ) : (
                        <div className="image-gallery-image">
                          <img
                            src={`${item.full}`}
                            alt={`${item.originalAlt}`}
                            width={`${galleryImageWidth}`}
                            height={`${galleryImageHeight}`}
                          />
                          {item.type === TYPE_VIDEO_NAVCONNECT ? (
                            <>
                              {item.embedUrl?.indexOf(TYPE_YOUTUBE) !== -1 ||
                              (item.embedUrl?.indexOf(TYPE_CONTENTFUL) !== -1 && !isAutoplayActive) ? (
                                <Button className={styles.YoutubePlayButton} onClick={openVideoModal}>
                                  <span className="PlayButtonIcon" />
                                </Button>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {loaded && instanceRef.current && (
                <div className="image-gallery-bullets bottom">
                  {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                    return (
                      // eslint-disable-next-line react/button-has-type
                      <button
                        key={idx}
                        onClick={() => {
                          instanceRef.current?.moveToIdx(idx);

                          // Autoplay video on active slide
                          if (video && video.embedUrl.indexOf(TYPE_CONTENTFUL) !== -1) {
                            const videoObj = document.getElementById('cfVideo');
                            if (idx === videoIndex && video?.videoAutoplay === '1') {
                              videoObj.play();
                            } else {
                              videoObj.pause();
                              videoObj.currentTime = 0;
                            }
                          }
                        }}
                        className={`image-gallery-bullet${currentSlide === idx ? ' active' : ''}`}
                      />
                    );
                  })}
                </div>
              )}
              <div className="image-gallery-thumbnails-wrapper">
                <div className="image-gallery-thumbnails">
                  <div className="image-gallery-thumbnails-container">
                    {items.map((item, idx) => {
                      return (
                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={`imgThumb_${idx}`}
                          className="image-gallery-thumbnail"
                          onClick={() => updateSlider(idx)}
                        >
                          <img src={`${item.thumbnail}`} alt={`${item.thumbnailAlt}`} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {open ? (
                <Lightbox
                  showArrows={!(items.length < 2)}
                  images={images}
                  isOpen={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                  changeIndex={index => {
                    setCurrentIndex(index);
                  }}
                  currentIndex={currentIndex}
                />
              ) : null}
              {video && openVideo ? (
                <Modal content={videoModalContent} visible={openVideo} close={closeVideoModal} variant="tertiary" />
              ) : null}
              {labels &&
                labels
                  .filter(item => item.badge === true)
                  .slice(0, 1)
                  .map((item, index) => {
                    return (
                      <div
                        className={[styles.BadgeWrapper, styles.ShowLabel, showLabel].join(' ')}
                        key={`badge-${index}`} // eslint-disable-line
                      >
                        <Badge text={item.text} type={item.type} size="large" />
                      </div>
                    );
                  })}
            </React.Fragment>
          </div>
        </>
      )}
    </React.Fragment>
  );
});
