import React from 'react';
import { Locale } from '@deity/falcon-front-kit';
import { sprintf } from 'sprintf-js';
import { InnerHTML } from '@deity/falcon-ui-kit';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './LeftForFreeshipping.module.scss';

export const LeftForFreeshipping = withStoreConfiguration(({ amount, storeConfiguration, context }) => {
  if (amount > 0 && storeConfiguration?.asam_shippinginformation?.left_for_freeshipping?.enabled) {
    return (
      <div
        className={[styles.LeftForFreeshipping, context === 'summary' ? styles.LeftForFreeshippingContext : null].join(
          ' '
        )}
      >
        <span className={[styles.Text, 'before-icon-account-rating'].join(' ')}>
          <Locale>
            {({ priceFormat }) => {
              if (priceFormat) {
                const price = priceFormat(amount);
                const text = sprintf(
                  storeConfiguration?.asam_shippinginformation?.left_for_freeshipping?.notification_text,
                  `<span class="PriceText">${price}</span>`
                );
                return <InnerHTML html={text} span />;
              }
              return null;
            }}
          </Locale>
        </span>
      </div>
    );
  }
  return null;
});
