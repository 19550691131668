import React from 'react';
import { Link } from 'react-router-dom';
import { Image as Img } from '@deity/falcon-ui';
import styles from '../MegaMenuSubmenu.module.scss';
import withStoreConfiguration from '../../../../helpers/StoreConfiguration/StoreConfiguration';
import { NavigationHeaderTracking } from '../../../../elements/Tracking/GoogleTagManager';

const checkIsUrlExternal = url => url.indexOf('http') !== -1 || url.indexOf('https') !== -1;

export const Image = withStoreConfiguration(({ storeConfiguration, subMenuId, subMenuClass, parent, ...rest }) => {
  const hasUrl = !!rest.url;
  const isUrlExternal = hasUrl ? checkIsUrlExternal(rest.url) : false;
  const imageElement = <Img src={rest.image} />;
  let wrappedImageElement = null;

  function closeSubmenu() {
    const navItem = document.querySelector(`[data-nav=${subMenuId}]`);
    navItem.classList.remove(subMenuClass);
  }

  if (hasUrl) {
    if (isUrlExternal) {
      wrappedImageElement = (
        <div>
          <a href={rest.url} target={`${rest.url_target}` === '1' ? '_blank' : '_self'} onClick={() => closeSubmenu()}>
            {imageElement}
          </a>
        </div>
      );
    } else {
      wrappedImageElement = (
        <Link
          to={rest.url.indexOf('/') !== 0 ? `/${rest.url}` : rest.url}
          target={`${rest.url_target}` === '1' ? '_blank' : '_self'}
          onClick={() => {
            NavigationHeaderTracking({
              storeConfiguration,
              key: 'level_3',
              label: `${parent.title} - ${rest.headline}`
            });
            closeSubmenu();
          }}
        >
          {imageElement}
        </Link>
      );
    }
  }

  return (
    <div className={styles.MenuDrawerColumn}>
      <div className={styles.MenuTitle}>{rest.headline}</div>
      <div className={styles.MenuDrawerImage}>{hasUrl ? wrappedImageElement : imageElement}</div>
    </div>
  );
});
