import { PageviewTracking } from './Pageview';

export const CartTracking = ({ storeConfiguration, product, action = 'add', origin, quantity, newQuantity = 0 }) => {
  let category3 = '';
  let eventName = 'add_to_cart';
  let eventKey = 'add_to_cart';
  let eventType = 'Ecommerce - Add to cart';

  if (action === 'remove') {
    eventName = 'remove_from_cart';
    eventKey = 'remove_from_cart';
    eventType = 'Ecommerce - Remove from cart';
  }

  // fallback for product detail page & listing page
  let fallbackPrice, fallbackItemBrand, fallbackCategory2, fallbackCategory4, fallbackItemVariant;
  // fallback PDP
  if (product && product.customAttributes) {
    const customAttributes = product.customAttributes ? JSON.parse(product.customAttributes) : {};
    fallbackPrice = product.price.special ? product.price.special : product.price.regular;
    fallbackItemBrand = `${customAttributes.cosBrand} - ${customAttributes.cosmeticlineValue}`;
    fallbackCategory2 = customAttributes.applicationType;
    fallbackCategory4 = customAttributes.cosContent;
    fallbackItemVariant = customAttributes.farbvariante;
  }
  // fallback PDL
  else {
    fallbackPrice = product.price.special ? product.price.special : product.price.regular;
    fallbackItemBrand = `${product.brand} - ${product.cosmeticlineValue}`;
    fallbackCategory2 = product.applicationType;
    fallbackCategory4 = product.volume;
    fallbackItemVariant = product.options?.[0]?.values?.[0]?.label;
  }

  const dataLayerData = {
    key: `cart_${product.sku}_${newQuantity}_${origin}_${action}_${quantity}`,
    event: eventType,
    event_name: eventName
  };

  if (product.price === 0) {
    category3 = 'free gift';
  } else if (product.discountAmount > 0) {
    category3 = 'on sale';
  } else {
    category3 = 'regular';
  }
  dataLayerData[eventKey] = {
    currency: storeConfiguration?.currency?.options?.default, // e.g. 'EUR' | 'CHF'
    items: [
      {
        item_name: product.name, // e.g. 'Magic Finish Make-up Mousse'
        item_id: product.sku, // e.g. '123456789'
        price: product.priceInclTax || fallbackPrice, // e.g. '24.75'
        item_brand:
          product.cosBrand && product.cosmeticline
            ? `${product.cosBrand} - ${product.cosmeticline}`
            : fallbackItemBrand, // e.g. 'M. Asam - Magic Finish'
        item_category: product.categoryPath, // e.g. 'Make-up'
        item_category_2: product.applicationType || fallbackCategory2, // e.g. 'Make-up Mousse'
        item_category_3: category3, // e.g. 'regular' | 'on sale' | 'free gift'
        item_category_4: product.cosContent || fallbackCategory4, // e.g. '30ml'
        item_variant: product.farbvariante || fallbackItemVariant || undefined, // e.g. 'Ivory'
        item_list_name: '', // e.g. 'Skincare'
        item_list_id: '', // e.g. '987654321'
        index: '',
        quantity
      }
    ]
  };

  dataLayerData[eventKey][`${eventName}_location`] = origin; // e.g. 'product detail page' | 'category page' | 'shopping cart‘ | 'order summary‘

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
