import { PageviewTracking } from './Pageview';

export const RegisterTracking = ({ storeConfiguration, failed = false, errorMessage }) => {
  let eventName = 'sign_up';
  let eventAction = 'sign up';
  let eventKey = 'sign_up';
  let eventLabel = 'successful';

  if (failed) {
    eventName = 'sign_up_failed';
    eventAction = 'sign up';
    eventKey = 'sign_up_failed';
    eventLabel = 'failed';
  }

  const dataLayerData = {
    key: `register_failed_${failed}`,
    event: 'Generic Event',
    event_name: eventName
  };

  dataLayerData[eventKey] = {
    event_category: 'account',
    event_action: eventAction,
    event_label: eventLabel
  };

  if (failed) {
    dataLayerData[eventKey].error_message = errorMessage;
  }

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
