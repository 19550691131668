import React, { createContext, useCallback, useContext, useEffect, useReducer, useRef } from 'react';

function noop() {}

/**
 * Context for the count of active handles.
 */
export const LoadingBarCounterContext = createContext({
  count: 0
});

// Provides increment/decrement functions to handles.
// Split from the counter context to reduce re-rendering.
const LoadingBarHandleContext = createContext({
  incrementCount: noop,
  decrementCount: noop
});

/**
 * Use for any task that should show activity with the global LoadingBar.
 * As long as at least one handle is active, the bar is visible.
 * @param {boolean} initActive initial active state (default: false)
 * @returns {Function} boolean setter for active state
 */
export function useLoadingBarHandle(initActive) {
  const { incrementCount, decrementCount } = useContext(LoadingBarHandleContext);
  const hasIncremented = useRef(null);

  const setActive = useCallback(
    active => {
      if (active && !hasIncremented.current) {
        incrementCount();
        hasIncremented.current = true;
      } else if (!active && hasIncremented.current) {
        decrementCount();
        hasIncremented.current = false;
      }
    },
    [incrementCount, decrementCount]
  );

  useEffect(() => {
    if (hasIncremented.current === null) {
      if (initActive) {
        setActive(true);
      } else {
        hasIncremented.current = false;
      }
    }

    return () => setActive(false);
  }, [setActive]);

  return setActive;
}

// Counter that never goes below 0
function counterReducer(count, addend) {
  return Math.max(count + addend, 0);
}

/**
 * Provides for LoadingBar Context with tracking for active handles.
 * @param {any} params
 * @returns {JSX.Element}
 */
export function LoadingBarContextProvider({ children }) {
  const [count, add] = useReducer(counterReducer, 0);

  const incrementCount = useCallback(() => {
    add(1);
  }, []);
  const decrementCount = useCallback(() => {
    add(-1);
  }, []);

  return (
    <LoadingBarHandleContext.Provider value={{ incrementCount, decrementCount }}>
      <LoadingBarCounterContext.Provider value={{ count }}>{children}</LoadingBarCounterContext.Provider>
    </LoadingBarHandleContext.Provider>
  );
}
