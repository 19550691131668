import React from 'react';
import { extractThemableProps, Box } from '@deity/falcon-ui';
import PropTypes from 'prop-types';
import styles from './Radio.module.scss';

export const Radio = props => {
  const { className, thinLabel, icon, label, value, ...remaining } = props;
  const { themableProps, rest } = extractThemableProps(remaining);

  return (
    <Box {...themableProps} className={[className, styles.Radio].join(' ')}>
      <input {...rest} value={value} id={`radio-${value}`} className={styles.RadioInput} type="radio" />
      <label
        htmlFor={`radio-${value}`}
        className={[styles.RadioLabel, thinLabel ? styles.RadioLabelThin : null].join(' ')}
      >
        {label}
        {icon ? <span className={styles.RadioIcon}>{icon}</span> : null}
      </label>
    </Box>
  );
};

Radio.propTypes = {
  /** Label displayed next to input */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Value of input */
  value: PropTypes.string,
  /** Icon displayed next to label */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Change label font weight to normal */
  thinLabel: PropTypes.bool
};
