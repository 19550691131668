import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import amazonPay from '../../../../../assets/images/payments/amazon-button.png';
import styles from './Button.module.scss';

export const Button = ({
  type,
  types,
  variant,
  size,
  shadow,
  children,
  asLink,
  asStandardLink,
  iconSrc,
  iconBefore,
  disabled,
  disabledOnlyStyle,
  loading,
  smallMobile,
  path,
  showNextIcon,
  transparentBg,
  tracking,
  placeOrder,
  id,
  invisible,
  dataInspect,
  classNames,
  ...rest
}) => {
  const buttonClasses = [];
  let button = null;
  let icon = null;
  let image = null;
  let external = false;

  if (classNames) {
    buttonClasses.push(classNames);
  }

  if (types === 'ghost') {
    buttonClasses.push(styles.ButtonGhost);
  } else if (types === 'facebook') {
    buttonClasses.push(styles.ButtonFacebook);
  } else if (types === 'appleId') {
    buttonClasses.push(styles.ButtonAppleId);
  } else if (types === 'amazon') {
    buttonClasses.push(styles.ButtonAmazon);
  } else if (types === 'amazon_login') {
    buttonClasses.push(styles.ButtonAmazonLogin);
  } else if (types === 'amazon_pay') {
    buttonClasses.push(styles.ButtonAmazonPay);
    image = amazonPay;
  } else if (types === 'paypal') {
    buttonClasses.push(styles.ButtonPayPal);
  } else if (types === 'lessmore') {
    buttonClasses.push(styles.ButtonLessMore);
  } else if (types === 'invisible') {
    buttonClasses.push(styles.ButtonInvisible);
  } else {
    buttonClasses.push(styles.Button);
  }

  if (size === 'small') {
    buttonClasses.push(styles.ButtonSmall);
  } else if (size === 'large') {
    buttonClasses.push(styles.ButtonLarge);
  }

  if (variant === 'secondary') {
    buttonClasses.push(styles.ButtonSecondary);
  }

  if (variant === 'tertiary') {
    buttonClasses.push(styles.ButtonTertiary);
  }

  if (variant === 'tertiaryNoCaps') {
    buttonClasses.push(styles.ButtonTertiaryNoCaps);
  }

  if (variant === 'buttonAsLink') {
    buttonClasses.push(styles.ButtonAsLink);
  }

  if (variant === 'back') {
    buttonClasses.push(styles.ButtonBack, 'before-icon-chevron-left');
  }

  if (shadow === false) {
    buttonClasses.push(styles.ButtonNoShadow);
  }

  if (disabled || disabledOnlyStyle) {
    buttonClasses.push(styles.ButtonDisabled);
  }

  if (smallMobile) {
    buttonClasses.push(styles.ButtonSmallMobile);
  }

  if (iconSrc) {
    if (iconBefore) {
      icon = <i className={[styles.ButtonIconBefore, `before-icon-${iconSrc}`].join(' ')} />;
    } else {
      icon = <i className={[styles.ButtonIconAfter, `after-icon-${iconSrc}`].join(' ')} />;
    }
  }

  if (loading) {
    buttonClasses.push(styles.isLoading);
  }

  if (transparentBg) {
    buttonClasses.push(styles.ButtonTransparentBg);
  }

  if (
    (path && path[0] && path[0].includes('http') && !path[0].includes('asambeauty')) ||
    (path && path.includes('http') && !path.includes('asambeauty'))
  ) {
    external = true;
  }

  const buttonContent = (
    <React.Fragment>
      {icon}
      {children}
      {loading ? <span className={styles.Loading} /> : null}
      {showNextIcon ? <span className={[styles.ButtonNextIcon, 'icon-chevron-right'].join(' ')} /> : null}
      {placeOrder ? <span className={[styles.ButtonLockIcon, 'icon-lock'].join(' ')} /> : null}
    </React.Fragment>
  );

  if (asLink) {
    button = (
      <Link
        id={id}
        to={path}
        className={buttonClasses.join(' ')}
        {...rest}
        style={image ? { backgroundImage: `url('${image}')` } : null}
        rel={external ? 'noopener noreferrer nofollow' : null}
        target={external ? '_blank' : null}
      >
        {buttonContent}
      </Link>
    );
  } else if (asStandardLink) {
    button = (
      <a
        {...rest}
        id={id}
        href={path}
        className={buttonClasses.join(' ')}
        style={image ? { backgroundImage: `url('${image}')` } : null}
        data-tracking-category={tracking && tracking[0] ? tracking[0] : null}
        data-tracking-action={tracking && tracking[1] ? tracking[1] : null}
        data-tracking-label={tracking && tracking[2] ? tracking[2] : null}
        rel={external ? 'noopener noreferrer nofollow' : null}
        target={external ? '_blank' : null}
      >
        {buttonContent}
      </a>
    );
  } else {
    button = (
      // eslint-disable-next-line react/button-has-type
      <button
        id={id}
        type={type}
        className={buttonClasses.join(' ')}
        {...rest}
        disabled={disabled || loading}
        style={image ? { backgroundImage: `url('${image}')` } : null}
        data-inspect={dataInspect || null}
      >
        {buttonContent}
      </button>
    );
  }

  return button;
};

Button.defaultProps = {
  type: 'button',
  size: 'medium'
};

Button.propTypes = {
  /** Set type of button. If not passed, then default purple button will be rendered */
  types: PropTypes.oneOf([
    'ghost',
    'facebook',
    'amazon',
    'amazon_pay',
    'lessmore',
    'invisible',
    'default',
    'appleId',
    'amazon_login'
  ]),
  /** Set variant of button. If not passed, then default purple button will be rendered */
  variant: PropTypes.oneOf(['secondary', 'back', 'tertiary', 'buttonAsLink', 'tertiaryNoCaps']),
  /** Set size of button. If not passed, then default normal size button will be rendered */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** Add or remove shadow from button */
  shadow: PropTypes.bool,
  /** If true button will be rendered as a[href] tag and you will need to pass path prop */
  asLink: PropTypes.bool,
  /** Link to page that should be fired when link will be clicked */
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /** If true button will be smaller on mobile */
  smallMobile: PropTypes.bool,
  /** Set button type */
  type: PropTypes.string,
  /** Set icon that will be visible inside button */
  iconSrc: PropTypes.string,
  /** Set true if icon should be displayed before */
  iconBefore: PropTypes.bool,
  /** Set true if button should be disabled */
  disabled: PropTypes.bool,
  /** Show loading animation */
  loading: PropTypes.bool,
  /** Show "next" icon inside button * */
  showNextIcon: PropTypes.bool,
  /** Show "lock" icon inside button * */
  placeOrder: PropTypes.bool
};
