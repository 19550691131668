import { PageviewTracking } from './Pageview';

export const SmartBannerTracking = ({
  storeConfiguration,
  eventName = 'smartbanner_view',
  eventAction = 'view',
  label
}) => {
  const dataLayerData = {
    key: `smartbanner_tracking_${eventAction}`,
    event: 'Generic Event',
    event_name: eventName, // or smartbanner_click , smartbanner_close
    [eventName]: {
      event_category: 'smartbanner',
      event_action: eventAction, // or 'click', 'close'
      event_label: label // can be a dynamic Value (if Banner changes)
    }
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
