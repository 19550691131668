import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Mutation } from '@deity/falcon-data';

export const REQUEST_PASSWORD_RESET_TOKEN_MUTATION = gql`
  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input)
  }
`;

export class RequestPasswordResetMutation extends Mutation {
  static defaultProps = {
    mutation: REQUEST_PASSWORD_RESET_TOKEN_MUTATION
  };
}

export const useRequestPasswordResetMutation = (options = {}) =>
  useMutation(REQUEST_PASSWORD_RESET_TOKEN_MUTATION, {
    ...options
  });
