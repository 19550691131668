import { BodyElementAttribute } from '../../../../helpers/BodyElementAttribute/BodyElementAttribute';
import { PageviewTracking } from './Pageview';

export const ItemListTracking = ({
  storeConfiguration,
  products,
  area = '',
  category = null,
  location = null,
  shopCountry = null,
  instance,
  slider,
  currentPage = 1,
  isCategory = false,
  actionChange = false
}) => {
  if (typeof window === 'undefined') return null;
  const pageType =
    BodyElementAttribute({
      key: 'data-page-type',
      action: 'get'
    }) || '';

  const pagePath = window?.location?.pathname?.substring(1);

  if (pageType === 'category' && pagePath === 'search') {
    return null;
  }

  const productList = [];
  const initialPerView = !isCategory ? instance?.current?.options?.slides?.perView : null;
  const initialPosition = !isCategory ? instance?.current?.track?.details?.abs + initialPerView : null;
  const isSliderChanged = !isCategory && slider ? Object.keys(slider).length !== 0 : null;
  let sliderPosition;
  let sliderPerView;
  let position = products?.length;
  let perView = products?.length;

  const listName = location ? `${shopCountry} - ${area} - ${location}` : category?.breadcrumbPath;

  if (!isCategory && slider && isSliderChanged) {
    sliderPosition = slider?.track?.details?.abs + slider?.options?.slides?.perView;
    sliderPerView = slider?.options?.slides?.perView;
  }

  if (!isCategory && slider && instance.current.track.details.abs === 0 && !isSliderChanged) {
    position = initialPosition;
    perView = initialPerView;
  } else if (sliderPosition > 0) {
    if (perView - initialPosition < 0) {
      position = Math.abs(position);
      perView = Math.abs(position);
    } else {
      position = sliderPosition;
      perView = sliderPerView;
    }
  }

  if (isCategory) {
    products.forEach((item, index) => {
      productList.push({
        item_name: item?.name, // e.g. 'Magic Finish Make-up Mousse'
        item_id: item?.sku, // e.g. '123456789'
        price: item.price?.special || item.price?.minPrice || item.price?.regular, // e.g. '24.75'
        item_brand: `${item?.brand} - ${item?.cosmeticlineValue}`, // e.g. 'M. Asam - Magic Finish'
        item_category: item?.categoryPath, // e.g. 'Make-up'
        item_category_2: item?.applicationType, // e.g. 'Make-up Mousse'
        item_category_3: item?.price.special ? 'on sale' : 'regular', // e.g. 'regular' | 'on sale' | 'free gift'
        item_category_4: item?.volume, // e.g. '30ml'
        item_variant: item?.options?.[0]?.values?.[0]?.label || undefined, // e.g. 'Ivory'
        item_list_name: area === 'search_list' ? 'Search' : listName || '',
        item_list_id: '',
        index: (currentPage - 1) * 21 + index + 1
      });
    });
  } else {
    for (let i = position - perView; i < position; i++) {
      productList.push({
        item_name: products[i]?.name, // e.g. 'Magic Finish Make-up Mousse'
        item_id: products[i]?.sku, // e.g. '123456789'
        price: products[i]?.price?.special || products[i]?.price?.minPrice || products[i]?.price?.regular, // e.g. '24.75'
        item_brand: `${products[i]?.brand} - ${products[i]?.cosmeticlineValue}`, // e.g. 'M. Asam - Magic Finish'
        item_category: products[i]?.categoryPath, // e.g. 'Make-up'
        item_category_2: products[i]?.applicationType, // e.g. 'Make-up Mousse'
        item_category_3: products[i]?.price.special ? 'on sale' : 'regular', // e.g. 'regular' | 'on sale' | 'free gift'
        item_category_4: products[i]?.volume, // e.g. '30ml'
        item_variant: products[i]?.options?.[0]?.values?.[0]?.label || undefined, // e.g. 'Ivory'
        item_list_name: listName || '',
        item_list_id: '',
        index: i + 1
      });
    }
  }

  const dataLayerData = {
    key: `item_list_${area}_${location}_${pagePath}_${currentPage}`,
    event: 'Ecommerce - Item List Views',
    page_type: pageType,
    event_name: 'view_item_list',
    shop_country: storeConfiguration?.general?.country?.default,
    view_item_list: {
      currency: storeConfiguration?.currency?.options?.default,
      items: productList
    }
  };

  return PageviewTracking({
    storeConfiguration,
    dataLayerData,
    bufferSeconds: isCategory ? 1000 : 30,
    forcePush: actionChange
  });
};
