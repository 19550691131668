import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import { useStoreContext } from '../Store/StoreProvider';

export const COOKIE_TELLJA = 'asam_tellja_ref';

export const TelljaReferrer = withRouter(({ location, history }) => {
  if (typeof window !== 'undefined') {
    const { getStoreConfigFlag } = useStoreContext();
    const telljaIsActive = getStoreConfigFlag('asam_tellja.general.is_active');
    const searchParams = new URLSearchParams(location.search || '');
    const telljaParam = searchParams.get('telljaid');
    const consentTellja = window?.UC_UI?.getServicesBaseInfo()?.find(item => item?.name === 'Tellja');

    if (telljaParam) {
      if (telljaIsActive) {
        window.addEventListener('ucEvent', e => {
          // session cookie is set before consent being given
          // below listen for consent change, as soon as on the same session
          // tellja gets activated, the cookie is set for 30 days

          if (e.detail.action === 'onAcceptAllServices' || e.detail.action === 'onUpdateServices') {
            if (
              window?.UC_UI?.getServicesBaseInfo()?.find(item => item?.name === 'Tellja')?.consent?.status &&
              telljaParam
            ) {
              Cookies.set(COOKIE_TELLJA, telljaParam, { expires: 30 });
            }
          }
        });

        if (consentTellja?.consent?.status) {
          // check for consent and record it for 30 days
          Cookies.set(COOKIE_TELLJA, telljaParam, { expires: 30 });
        } else {
          // still recorded without consent for current session
          Cookies.set(COOKIE_TELLJA, telljaParam);
        }
      }
      searchParams.delete('telljaid');
      history.replace({ search: searchParams.toString() });
    }
  }

  return null;
});
