import { PageviewTracking } from '../Pageview';

export const NavigationShopTracking = ({ storeConfiguration, label }) => {
  const dataLayerData = {
    key: `navigation_shop_${label}`,
    event: 'Generic Event',
    event_name: 'navigation_shop',
    navigation_shop: {
      navigation_bar: 'shop',
      navigation_element: label, // e.g. 'account'

      event_category: 'navigation',
      event_action: 'shop',
      event_label: label // e.g. 'account'
    }
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
