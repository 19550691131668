import { GET_LOADER_STATE } from './LoaderQuery';

export const closeLoaderResolver = (_, _variables, { cache }) => {
  const queryResponse = cache.readQuery({ query: GET_LOADER_STATE });
  const loader = { ...queryResponse.loader };
  loader.isVisible = false;

  cache.writeQuery({
    query: GET_LOADER_STATE,
    data: { loader }
  });

  return null;
};

export const openLoaderResolver = (_, _variables, { cache }) => {
  const data = {
    loader: {
      isVisible: true,
      __typename: 'LoaderStatus'
    }
  };

  cache.writeQuery({ query: GET_LOADER_STATE, data });

  return null;
};
