import React from 'react';
import { Field, getDefaultInputValidators } from '@deity/falcon-front-kit';
import { extractThemableProps } from '@deity/falcon-ui';
import { FormFieldError, FormFieldArea } from '@deity/falcon-ui-kit';
import { Input } from '../Input/Input';
import styles from './FormField.module.scss';

export const FormField = props => {
  const {
    name,
    placeholder,
    noSign,
    className,
    validate,
    required,
    children,
    noMargin,
    textCenter,
    removeStyles,
    textarea,
    showAsError,
    showErrorMessage,
    showWarningMessage,
    variant,
    tooltipMessage,
    modalTooltip,
    hideValidateStyles,
    inputRef,
    onChange,
    onFocus,
    onBlur,
    forceInvalid,
    ...restProps
  } = props;
  const { themableProps, rest } = extractThemableProps(restProps);
  return (
    <Field name={name} validate={getDefaultInputValidators(props)} {...rest}>
      {({ form, field, label, error }) => {
        let onChangeAction = 'onChange' in field ? field.onChange : null;
        let onFocusAction = 'onFocus' in field ? field.onFocus : null;
        let onBlurAction = 'onBlur' in field ? field.onBlur : null;

        if (onChange) {
          onChangeAction = onChange;
        }

        if (onFocus) {
          onFocusAction = onFocus;
        }

        if (onBlur) {
          onBlurAction = onBlur;
        }

        if (forceInvalid) {
          form.touched.telephone = true;
        }

        return (
          <div
            className={[className, tooltipMessage || modalTooltip ? styles.FormFieldWithTooltip : null].join(' ')}
            {...themableProps}
          >
            {children ? (
              children({ form, field: { ...field, gridArea: FormFieldArea.input } })
            ) : (
              <Input
                {...field}
                textarea={textarea}
                removeStyles={removeStyles}
                variant={variant}
                noMargin={noMargin}
                textCenter={textCenter}
                hasError={field.invalid || showAsError}
                hasWarning={showWarningMessage}
                hasSuccess={
                  !field.invalid && !error && field.value !== undefined && field.value !== '' && field.value !== null
                }
                tooltipMessage={tooltipMessage}
                modalTooltip={modalTooltip}
                label={label}
                placeholder={required && !noSign ? `${placeholder}*` : placeholder}
                showErrorMessage={showErrorMessage}
                hideValidateStyles={hideValidateStyles}
                aria-label={name}
                itemRef={inputRef}
                onChange={onChangeAction}
                onFocus={onFocusAction}
                onBlur={onBlurAction}
                dataInspect={name ? `field-${name}` : null}
              />
            )}
            {showWarningMessage ? (
              <div className={styles.FormFieldWarning} style={!showWarningMessage ? { display: 'none' } : {}}>
                {showWarningMessage}
              </div>
            ) : null}
            <FormFieldError style={!showErrorMessage ? { display: 'none' } : {}}>
              {field.invalid ? error : null}
            </FormFieldError>
          </div>
        );
      }}
    </Field>
  );
};
