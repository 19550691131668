import React from 'react';

export const Errors = ({ error, noPadding, additionalClasses = [] }) => {
  return Array.isArray(error) ? (
    error.map((item, index) => (
      <div
        className={['ErrorMessage', noPadding ? 'ErrorMessageNoPadding' : null].concat(additionalClasses).join(' ')}
        /* eslint-disable-next-line react/no-array-index-key */
        key={`error${index}`}
      >
        {item.message}
      </div>
    ))
  ) : (
    <div className={['ErrorMessage', noPadding ? 'ErrorMessageNoPadding' : null].concat(additionalClasses).join(' ')}>
      {error}
    </div>
  );
};
