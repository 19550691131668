import React from 'react';
import { T } from '@deity/falcon-i18n';
import styles from './PriceDate.module.scss';

export const PriceDate = ({ dateFrom, dateTo }) => {
  const startDate = dateFrom ? dateFrom * 1000 : null; // Timestamps in milliseconds
  const endDate = dateTo ? dateTo * 1000 : null;

  if (!endDate) {
    return null;
  }

  const currentDate = new Date();
  const userTimezoneOffset = currentDate.getTimezoneOffset() * 60000;
  const thisStartDate = startDate ? new Date(startDate) : new Date(currentDate.setDate(currentDate.getDate() - 1));
  thisStartDate.setTime(thisStartDate.getTime() + userTimezoneOffset);
  const thisEndDate = new Date(endDate);
  thisEndDate.setTime(thisEndDate.getTime() + 1000 * (24 * 60 * 60 - 1) + userTimezoneOffset); // + 23h59m59s

  /* if there are less than 24h left on price availability, will add special class to alert the price */
  const alertClass =
    thisEndDate - currentDate < 86400000 ? [styles.PriceDateHolder, styles.AlertClass] : [styles.PriceDateHolder];

  return currentDate > thisStartDate && currentDate < thisEndDate ? (
    <React.Fragment>
      <div className={alertClass.join(' ')}>
        <T id="product.priceAvailability" />: {`${thisEndDate.toLocaleDateString()}`}
      </div>
    </React.Fragment>
  ) : null;
};
