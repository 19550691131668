import gql from 'graphql-tag';
import { Query } from '@deity/falcon-data';

export const GET_STORE_CONFIGURATION = gql`
  query StoreConfigKeyValues {
    storeConfigKeyValues {
      path
      key
      value
    }
  }
`;

export class StoreConfigKeyValuesQuery extends Query {
  static defaultProps = {
    query: GET_STORE_CONFIGURATION
  };
}
