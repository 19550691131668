import { PageviewTracking } from './Pageview';

export const ScrollTracking = ({ storeConfiguration, automatically = null, count = null }) => {
  let dataLayerData;

  const pagePath = typeof window !== 'undefined' ? window.location.href : '';

  if (automatically) {
    dataLayerData = {
      key: `scoll_automatically_${count}`,
      event: 'Generic Event',
      event_name: 'scroll_automatically',
      scroll_automatically: {
        page_location: pagePath, // e.g. 'https://www.asambeauty.com/gesicht.html'

        event_category: 'scroll',
        event_action: pagePath, // e.g. 'https://www.asambeauty.com/gesicht.html'
        event_label: `${count} - automatically` // e.g '1 - automatically'
      }
    };
  } else {
    dataLayerData = {
      key: `scoll_manually_${count}`,
      event: 'Generic Event',
      event_name: 'scroll_manually',
      scroll_manually: {
        page_location: pagePath, // e.g. 'https://www.asambeauty.com/gesicht.html'

        event_category: 'scroll',
        event_action: pagePath, // e.g. 'https://www.asambeauty.com/gesicht.html'
        event_label: `${count} - manually` // e.g '3 - manually'
      }
    };
  }

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
