import { PageviewTracking } from './Pageview';

export const SortProductListTracking = ({ storeConfiguration, selectedSorting }) => {
  const dataLayerData = {
    key: `sort_product_list_${selectedSorting}`,
    event: 'Generic Event',
    event_name: 'sort_product_list',
    sort_product_list: {
      sort_element: selectedSorting,

      event_category: 'product list',
      event_action: 'sort',
      event_label: selectedSorting
    }
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
