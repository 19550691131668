import React from 'react';
import { T } from '@deity/falcon-i18n';
import { NavLink } from 'react-router-dom';
import { AccountPath } from '../../../pages/Account/AccountPath';
import { SidebarLogout } from '../../SidebarNavigation/SidebarLogout';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './AccountMenu.module.scss';

export const AccountMenu = withStoreConfiguration(({ storeConfiguration }) => {
  const referActive = parseInt(storeConfiguration?.asam_easymsso?.general?.enabled, 10) || false;
  const hotlineActive = parseInt(storeConfiguration?.contact?.hotline?.active, 10) || false;
  const newsletterActive = parseInt(storeConfiguration?.newsletter?.general?.active, 10) || false;
  const subscriptionsActive = parseInt(storeConfiguration?.asam_subscriptions?.general?.enabled, 10) || false;

  const sidebarNavigationList = [
    AccountPath.dashboard,
    AccountPath.profile,
    AccountPath.orders,
    AccountPath.subscriptions,
    AccountPath.wishlist,
    AccountPath.rating,
    AccountPath.newsletter,
    AccountPath.recommendation,
    AccountPath.help,
    {
      element: <SidebarLogout />
    }
  ];

  const filterItems = item => {
    if (
      (!referActive && item.link === 'accountRecommendation.link') ||
      (!hotlineActive && item.link === 'accountHelp.title') ||
      (!newsletterActive && item.link === 'accountNewsletter.title') ||
      (!subscriptionsActive && item.link === 'accountAbo.title')
    ) {
      return null;
    }

    return item;
  };

  return (
    <div className={styles.AccountMenuHolder}>
      <div className={styles.AccountMenuTitle}>
        <T id="customerAccount.sidebarTitle" />
      </div>
      <ul>
        {sidebarNavigationList.filter(filterItems).map((item, i) => {
          let dataInspect = '';
          if (item.element) {
            dataInspect = 'customer-logout';
          } else {
            dataInspect = item.to ? item.to.substring(1).replace('/', '-') : '';
          }
          return (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={`item${i}`}
              className={item.element ? styles.AccountMenuSpecial : null}
              data-inspect={dataInspect}
            >
              {item.element ? (
                item.element
              ) : (
                <NavLink to={item.to} exact={!!item.exact}>
                  <T id={item.link} />
                </NavLink>
              )}
              {item.subnav ? (
                <ul className={styles.AccountMenuSubnav}>
                  {item.subnav.map((subnavitem, index) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={`item${index}`}>
                        <NavLink to={subnavitem.to} exact={!!subnavitem.exact}>
                          <T id={subnavitem.link} />
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
});
