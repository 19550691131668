import React, { useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import { useInView } from 'react-intersection-observer';
import { ProductCard } from '../ProductCard/ProductCard';
import { ItemListTracking } from '../../Tracking/GoogleTagManager';
import { AddedToCartPopup } from '../AddedToCartPopup/AddedToCartPopup';
import { AddedToCartErrorPopup } from '../AddedToCartPopup/AddedToCartErrorPopup';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import useCheckBreakpoints from '../../../hooks/CheckBreakpoint';
import styles from './ProductSlider.module.scss';

export const ProductSlider = withStoreConfiguration(
  ({
    storeConfiguration,
    title,
    products,
    autoHeight,
    showAddToCart,
    slidesToShow,
    noDots,
    columnIndex,
    location,
    source
  }) => {
    const { isMobile } = useCheckBreakpoints();
    const [showAddedToCartErrorPopup, setShowAddedToCartErrorPopup] = useState(false);
    const [showAddedToCartPopup, setShowAddedToCartPopup] = useState(false);
    const [addedToCartError, setAddedToCartError] = useState('');
    const showError = error => {
      setShowAddedToCartErrorPopup(true);
      setAddedToCartError(error);
    };
    const shopCountry = storeConfiguration?.general?.country?.default;
    const { ref, inView } = useInView({
      rootMargin: '-50px'
    });

    if (products.length === 0) {
      return null;
    }
    const ArrowClasses = [];

    switch (columnIndex) {
      case 0:
        ArrowClasses.push(styles.HideArrowRight);
        break;
      case 1:
        ArrowClasses.push(styles.HideArrowLeft);
        break;
      default:
        break;
    }

    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [productSlider, setProductSlider] = useState(null);
    const [arrowClicked, setArrowClicker] = useState(false);

    const sliderOptions = {
      initial: 0,
      drag: true,
      breakpoints: {
        '(min-width: 768px)': {
          slides: { perView: slidesToShow || 3 },
          drag: true
        },
        '(min-width: 1024px)': {
          slides: { perView: slidesToShow || 4 },
          drag: false
        }
      },
      slides: {
        perView: 2
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      animationStarted() {
        setProductSlider(null);
      },
      animationEnded(slider) {
        setProductSlider(slider);
        setArrowClicker(true);
      },
      dragStarted() {
        setProductSlider(null);
      },
      dragEnded(slider) {
        setProductSlider(slider);
        setArrowClicker(true);
      },
      created(slider) {
        setLoaded(true);
        setProductSlider(slider);
      }
    };

    const [sliderRef, instanceRef] = useKeenSlider({
      ...sliderOptions
    });

    React.useEffect(() => {
      instanceRef.current?.update({
        ...sliderOptions
      });
    }, [instanceRef, products]);

    const Arrow = props => {
      const disabled = props.disabled ? styles.ArrowDisabled : '';
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          onClick={props.onClick}
          className={[styles.Arrow, props.left ? styles.ArrowPrev : styles.ArrowNext, disabled].join(' ')}
          aria-label={props.left ? 'prev' : 'next'}
        />
      );
    };

    const mobileOffset = isMobile ? 1 : 0;

    return (
      <div className={[styles.ProductSlider, autoHeight ? styles.AutoHeight : null, ArrowClasses].join(' ')} ref={ref}>
        {title ? (
          <div className={styles.ProductSliderTitle}>
            <span>{title}</span>
          </div>
        ) : null}
        <div className={styles.SliderHolder}>
          <div className="navigation-wrapper">
            {products?.length ? (
              <div ref={sliderRef} className="keen-slider">
                {products.map(
                  (item, index) =>
                    item && (
                      <div className="keen-slider__slide" key={`item-${item.id}`}>
                        <ProductCard
                          product={item}
                          key={`item-${item.id}`}
                          showAddedToCart={() => setShowAddedToCartPopup(true)}
                          showAddedToCartError={error => showError(error)}
                          showAddToCart={showAddToCart}
                          source={source}
                          location={location}
                          area="ProductSlider"
                          isSlider
                          currentIndex={index}
                        />
                      </div>
                    )
                )}
              </div>
            ) : null}

            {products?.length && loaded && instanceRef.current && (
              <>
                <Arrow
                  left
                  onClick={e =>
                    e.stopPropagation() ||
                    instanceRef.current?.moveToIdx(
                      instanceRef.current.track.details.abs - instanceRef.current.options.slides.perView < 0
                        ? 0
                        : instanceRef.current.track.details.abs - instanceRef.current.options.slides.perView
                    )
                  }
                  disabled={currentSlide === 0}
                />

                <Arrow
                  onClick={e =>
                    e.stopPropagation() ||
                    instanceRef.current?.moveToIdx(
                      instanceRef.current.track.details.abs + 2 * instanceRef.current.options.slides.perView - 1 >=
                      instanceRef.current.track.details.slides.length
                        ? instanceRef.current.track.details.slides.length - instanceRef.current.options.slides.perView
                        : instanceRef.current.track.details.abs + instanceRef.current.options.slides.perView
                    )
                  }
                  disabled={
                    currentSlide >=
                    instanceRef?.current?.track?.details?.slides?.length - instanceRef.current.options.slides.perView
                  }
                />
              </>
            )}
            {products?.length && !noDots && loaded && instanceRef.current && (
              <div className="dots">
                {[
                  ...Array(
                    Math.ceil(
                      instanceRef?.current?.track?.details?.slides?.length /
                        (instanceRef.current.options.slides.perView - mobileOffset)
                    ) >
                      1 + mobileOffset
                      ? Math.ceil(
                          instanceRef?.current?.track?.details?.slides?.length /
                            (instanceRef.current.options.slides.perView - mobileOffset)
                        ) - mobileOffset
                      : 0
                  ).keys()
                ].map(idx => {
                  let activeCssClass =
                    Math.ceil(currentSlide / instanceRef.current.options.slides.perView) *
                      instanceRef.current.options.slides.perView ===
                    idx * instanceRef.current.options.slides.perView
                      ? ' active'
                      : '';
                  let clickIndex =
                    idx * instanceRef.current.options.slides.perView + instanceRef.current.options.slides.perView - 1 >=
                    instanceRef.current.track.details.slides.length
                      ? instanceRef.current.track.details.slides.length - instanceRef.current.options.slides.perView
                      : idx * instanceRef.current.options.slides.perView;
                  if (isMobile) {
                    activeCssClass = currentSlide === idx ? ' active' : '';
                    clickIndex = idx;
                  }

                  return (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <span
                      key={idx}
                      onClick={() => {
                        instanceRef.current?.moveToIdx(clickIndex);
                      }}
                      className={`dot${activeCssClass}`}
                    />
                  );
                })}
                {inView && productSlider ? (
                  <ItemListTracking
                    products={products}
                    area="ProductSlider"
                    location={location}
                    storeConfiguration={storeConfiguration}
                    shopCountry={shopCountry}
                    instance={instanceRef}
                    slider={productSlider}
                    actionChange={arrowClicked}
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
        <AddedToCartPopup on={showAddedToCartPopup} toggle={() => setShowAddedToCartPopup(false)} />
        <AddedToCartErrorPopup
          on={showAddedToCartErrorPopup}
          toggle={() => setShowAddedToCartErrorPopup(false)}
          message={addedToCartError}
        />
      </div>
    );
  }
);
