import React from 'react';
import { Formik } from 'formik';
import { useGetUserError } from '@deity/falcon-data';
import { useSignMutation } from '../../../overrides/Customer/SignInMutation';

export const SignInFormProvider = props => {
  const { onSuccess, initialValues, enableReinitialize, ...formikProps } = props;
  const defaultInitialValues = {
    email: '',
    password: ''
  };

  const [signIn] = useSignMutation();
  const [getUserError] = useGetUserError();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues || defaultInitialValues}
      onSubmit={(values, { setSubmitting, setStatus }) =>
        signIn({ variables: { input: values } })
          .then(data => {
            setSubmitting(false);
            return onSuccess && onSuccess(data);
          })
          .catch(e => {
            const error = getUserError(e);
            if (error.length) {
              setStatus({ error });
              setSubmitting(false);
            }
          })
      }
      {...formikProps}
    />
  );
};
