import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import OcapiAsambeauty from '../../../../../../public/static/js/ocapi-asambeauty';
import { ProductSlider } from '../Product';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { GoogleTagManager4OdoscopeTracking } from '../Tracking/GoogleTagManager/GA4/OdoscopeTracking';
import { ProductListBySkusQuery } from './ProductListBySkusQuery';

const isSSR = typeof window === 'undefined';

export const OdoscopeSlider = withStoreConfiguration(({ title, location, productSku, storeConfiguration }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [odoscopeData, setOdoscopeData] = useState(false);

  if (parseInt(storeConfiguration?.asam_layoutconfiguration?.odoscope?.active, 10)) {
    const getOscCookieParam = cookieKey => {
      const oscCookie = Cookies.get(cookieKey);
      if (oscCookie) {
        return {
          [cookieKey]: oscCookie
        };
      }
      return {};
    };

    const getOdoscopeData = () => {
      let odoscopeRequest;
      const params = {
        maxItems: 12,
        offset: 0,
        isShortAnswer: true
      };

      if (productSku) {
        params.ProdID = productSku;
      }

      switch (location) {
        case 'product_page':
          odoscopeRequest = window.osc.productPageReco.getItems({
            ...params,
            ...getOscCookieParam(`osc-QIUK7`)
          });
          break;
        case 'account_page':
          odoscopeRequest = window.osc.myAccountReco.getItems({
            ...params,
            ...getOscCookieParam(`osc-huVD1`)
          });
          break;
        case 'cart_page':
          odoscopeRequest = window.osc.basketReco.getItems({
            ...params,
            ...getOscCookieParam(`osc-0VU9e`)
          });
          break;
        case 'category_page':
          odoscopeRequest = window.osc.categoryReco.getItems({
            ...params,
            ...getOscCookieParam(`osc-kWNWg`)
          });
          break;
        case 'not_found_page':
          odoscopeRequest = window.osc.notFoundReco.getItems({
            ...params,
            ...getOscCookieParam(`osc-RHo0k`)
          });
          break;
        default:
          odoscopeRequest = window.osc.notFoundReco.getItems(params);
      }

      odoscopeRequest.then(data => {
        setOdoscopeData(data);
      });
    };

    const query = new URLSearchParams(useLocation().search);
    const trackingConsent = query.get('trackingConsent');

    if (trackingConsent === 'false') {
      return null;
    }

    useEffect(() => {
      const existingScript = document.getElementById('odoscopeSdk');

      if (!existingScript) {
        const script = document.createElement('script');
        script.src = OcapiAsambeauty;
        script.async = true;
        script.id = 'odoscopeSdk';
        script.onload = () => {
          setScriptLoaded(true);
          getOdoscopeData();
        };
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      }
    }, []);

    if (!isSSR && scriptLoaded && odoscopeData) {
      const skus = odoscopeData?.variants?.map(item => item.variantID);
      let odoscopeTrackingEvent = null;

      const cookieConsent = Cookies.get('CookieConsent') || [];

      if (cookieConsent?.includes('essential:true')) {
        const oscCookieKey =
          odoscopeData?.oscCookie && !!Object.keys(odoscopeData.oscCookie).length
            ? Object.keys(odoscopeData.oscCookie)[0]
            : null;
        const oscCookieValue =
          odoscopeData?.oscCookie && !!Object.values(odoscopeData.oscCookie).length
            ? Object.values(odoscopeData.oscCookie)[0]
            : null;
        if (oscCookieKey) {
          Cookies.set(oscCookieKey, oscCookieValue, { expires: 30 });
        }
      }

      if (odoscopeData.oscTracking !== undefined && odoscopeData.variants?.length) {
        odoscopeTrackingEvent = (
          <GoogleTagManager4OdoscopeTracking trackingId={odoscopeData.oscTracking} area={`${location}_tracking`} />
        );
      }

      return (
        <ProductListBySkusQuery variables={{ skus }}>
          {({ data }) => {
            if (!data || !data.productListBySkus) {
              return null;
            }

            const productsList = data.productListBySkus;
            return productsList?.items.length ? (
              <React.Fragment>
                <ProductSlider
                  title={title}
                  products={productsList.items}
                  autoHeight
                  location="odoscope"
                  trackingId="odoscope"
                  source="OdoscopeSlider"
                />
                {odoscopeTrackingEvent}
              </React.Fragment>
            ) : null;
          }}
        </ProductListBySkusQuery>
      );
    }
  }
  return null;
});
