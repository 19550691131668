import React from 'react';
import { T } from '@deity/falcon-i18n';
import { Button } from '../../ui/Button/Button';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { Preloader } from '../../ui/Preloader/Preloader';
import { GetFacebookLinkQuery } from './LoginWithFacebookQuery';

export const FacebookLoginButton = withStoreConfiguration(({ storeConfiguration }) => {
  const asamSocialConnect = storeConfiguration?.asam_socialconnect || null;
  const asamFb = asamSocialConnect?.facebook || null;
  const enabled = asamFb?.enabled || null;
  const appId = asamFb?.appId || null;

  const loadFacebook = url => {
    if (typeof window !== 'undefined') {
      window.open(url, '_self', 'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=no');
    }
  };

  if (enabled === '0' || !enabled || !appId) {
    return <React.Fragment />;
  }

  return (
    <GetFacebookLinkQuery>
      {({ data: { getFacebookLink }, loading }) => {
        if (loading) {
          return <Preloader />;
        }

        return (
          <Button
            onClick={() => loadFacebook(getFacebookLink.buttonLink)}
            type="submit"
            types="facebook"
            iconSrc="facebook"
            size="small"
          >
            <div>
              <T id="logInWithFacebook" />
            </div>
          </Button>
        );
      }}
    </GetFacebookLinkQuery>
  );
});
