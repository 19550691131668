const days = [];

days.push({ value: null, label: 'calendar.placeholder.day' });
for (let i = 1; i <= 31; i++) {
  days.push({ value: String(i), label: String(i) });
}

const years = [];
const creditCardYears = [];
const currentYear = new Date().getFullYear();

years.push({ value: null, label: 'calendar.placeholder.year' });
for (let i = currentYear; i > 1900; i--) {
  years.push({ value: String(i), label: String(i) });
}

for (let i = currentYear; i < currentYear + 50; i++) {
  creditCardYears.push({ value: String(i), label: String(i) });
}

export const Day = [...days];
export const Month = [
  { value: null, label: 'calendar.placeholder.month' },
  { value: '01', label: 'calendar.01' },
  { value: '02', label: 'calendar.02' },
  { value: '03', label: 'calendar.03' },
  { value: '04', label: 'calendar.04' },
  { value: '05', label: 'calendar.05' },
  { value: '06', label: 'calendar.06' },
  { value: '07', label: 'calendar.07' },
  { value: '08', label: 'calendar.08' },
  { value: '09', label: 'calendar.09' },
  { value: '10', label: 'calendar.10' },
  { value: '11', label: 'calendar.11' },
  { value: '12', label: 'calendar.12' }
];

export const Year = [...years];
export const CreditCardExpireYears = creditCardYears;

/**
 *
 * @param dateA
 * @param dateB
 * @param equals
 * @returns {boolean}
 */
export const isDateBefore = (dateA, dateB, equals = false) => (equals ? dateA <= dateB : dateA < dateB);

/**
 *
 * @param date
 * @param equals
 * @returns {boolean}
 */
export const isDateBeforeNow = (date, equals = false) => isDateBefore(date, new Date(), equals);

/**
 *
 * @param date
 * @param equals
 * @returns {boolean}
 */
export const isDateAfterNow = (date, equals = false) => isDateBefore(new Date(), date, equals);

/**
 *
 * @param dateA
 * @param dateB
 * @param equals
 * @param explicit
 * @returns {boolean}
 */
export const isNowInRange = (dateA, dateB, equals = true, explicit = false) =>
  (explicit && (dateA && dateB && isDateBeforeNow(dateA, equals) && isDateAfterNow(dateB, equals))) ||
  (!explicit && (!dateA || isDateBeforeNow(dateA, equals)) && (!dateB || isDateAfterNow(dateB, equals)));
