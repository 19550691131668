import React from 'react';
import { Link } from 'react-router-dom';
import { T } from '@deity/falcon-i18n';
import { isItemUrlExternal } from '../MegaMenuHelpers';
import { NavigationMobileTracking } from '../../../elements/Tracking/GoogleTagManager';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { MOBILE_MENU_FIRST, MOBILE_MENU_THIRD } from './MobileMenuLevels';
import styles from './MobileMenu.module.scss';

export const MobileMenuSecondLevel = withStoreConfiguration(
  ({ storeConfiguration, data, onClose, openLevel, selectedFirstLevel, selectedSecondLevel }) => {
    const selectedFirstLevelData = selectedFirstLevel !== null ? data[selectedFirstLevel] : null;
    const selectedData = selectedFirstLevelData ? selectedFirstLevelData.elements[selectedSecondLevel] : null;
    const itemUrl = selectedData?.type_name === 'category' ? selectedData?.data_category?.url : null;
    const itemUrlExternal = itemUrl ? isItemUrlExternal(itemUrl) : null;
    let content = null;
    let itemName = null;

    if (selectedData?.type_name === 'category') {
      itemName = selectedData?.data_category?.name;
      content =
        selectedData?.data_category?.links &&
        selectedData.data_category.links.map((link, linkIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className={styles.MobileMenuSimpleListItem} aria-hidden key={`mobile-menu-second-level-${linkIndex}`}>
            <Link
              to={`/${link.url}`}
              onClick={() => {
                onClose();
                NavigationMobileTracking({
                  storeConfiguration,
                  key: `burger_menu_mobile_${MOBILE_MENU_THIRD}`,
                  label: `${selectedFirstLevelData.title} - ${itemName} - ${link.name}`
                });
              }}
            >
              {link.name}
            </Link>
          </li>
        ));
    }

    if (selectedData?.type_name === 'link') {
      const sectionLinks = selectedData?.data_link?.content ? JSON.parse(selectedData?.data_link?.content) : [];

      itemName = selectedData?.data_link?.headline;
      content =
        sectionLinks.length &&
        sectionLinks.map((link, linkIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className={styles.MobileMenuSimpleListItem} aria-hidden key={`mobile-menu-second-level-${linkIndex}`}>
            {isItemUrlExternal(link.record_url) ? (
              <a
                href={link.record_url}
                onClick={() => {
                  onClose();
                  NavigationMobileTracking({
                    storeConfiguration,
                    key: `burger_menu_mobile_${MOBILE_MENU_THIRD}`,
                    label: `${selectedFirstLevelData.title} - ${itemName} - ${link.record_text}`
                  });
                }}
              >
                {link?.record_text}
              </a>
            ) : (
              <Link
                to={link.record_url.indexOf('/') !== 0 ? `/${link.record_url}` : link.record_url}
                onClick={() => {
                  onClose();
                  NavigationMobileTracking({
                    storeConfiguration,
                    key: `burger_menu_mobile_${MOBILE_MENU_THIRD}`,
                    label: `${selectedFirstLevelData.title} - ${itemName} - ${link.record_text}`
                  });
                }}
              >
                {link?.record_text}
              </Link>
            )}
          </li>
        ));
    }

    return (
      <div className={[styles.MobileMenuSecondLevel, styles.NoTopPadding, styles.MobileMenuContent].join(' ')}>
        {selectedData ? (
          <div>
            <div className={styles.MobileMenuBack} aria-hidden onClick={() => openLevel(MOBILE_MENU_FIRST)}>
              <T id="mobileMenu.back" />
            </div>
            <div className={styles.MobileMenuTitle}>{itemName}</div>
            {itemUrl ? (
              <div className={styles.MobileMenuAll}>
                {itemUrlExternal ? (
                  <a href={itemUrl} onClick={onClose}>
                    + <T id="mobileMenu.showAll" />
                  </a>
                ) : (
                  <Link to={itemUrl} onClick={onClose}>
                    + <T id="mobileMenu.showAll" />
                  </Link>
                )}
              </div>
            ) : null}
            <ul className={styles.MobileMenuSimpleList}>{content}</ul>
          </div>
        ) : null}
      </div>
    );
  }
);
