export const dateFormat = datestring => {
  const dateObj = new Date(datestring);
  const date = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  return `${date <= 9 ? `0${date}` : date}.${month <= 9 ? `0${month}` : month}.${year}`;
};

/**
 * Transform '2021-10-25 08:10:00' -> '2021-10-25T08:10:00Z'
 * @param dateString
 * @returns {string}
 */
export const dateStandardisation = dateString => dateString && `${dateString.replace(' ', 'T')}Z`;

/**
 *
 * @param dateString
 * @returns {Date}
 */
export const convertToDate = dateString => dateString && new Date(dateStandardisation(dateString));
