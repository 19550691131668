import React, { useContext } from 'react';
import { LoadingBarCounterContext } from '../../hooks/LoadingBar';
import styles from './LoadingBar.module.scss';

export const LoadingBar = () => {
  const { count } = useContext(LoadingBarCounterContext);
  const isVisible = count > 0;

  return <div className={[styles.LoadingBar, isVisible ? styles.Visible : null].join(' ')} />;
};
