import { ApolloLink } from 'apollo-link'; // eslint-disable-line import/no-extraneous-dependencies
import Apollo from 'apollo-client';
import { createHttpLink } from 'apollo-link-http'; // eslint-disable-line import/no-extraneous-dependencies
import { InMemoryCache } from 'apollo-cache-inmemory'; // eslint-disable-line import/no-extraneous-dependencies
import fetch from 'node-fetch';
import deepMerge from 'deepmerge'; // eslint-disable-line import/no-extraneous-dependencies
import Cookies from 'js-cookie';

/**
 * @typedef {object} FalconApolloClientConfig
 * @property {boolean} [isBrowser=false] Boolean flag to determine the current environment
 * @property {object} [initialState={}] Object to restore Cache data from
 * @property {string} [serverUri="http://localhost:4000/graphql"] ApolloServer URL
 * @property {FalconApolloClientStateConfig} [clientState={}] Configuration of client state for Apollo
 */

/**
 * @typedef {object} FalconApolloClientStateConfig
 * @property {object} data https://www.apollographql.com/docs/react/essentials/local-state.html#cache-initialization
 * @property {object} resolvers https://www.apollographql.com/docs/react/essentials/local-state.html#local-resolvers
 */

/**
 * Creates an ApolloClient instance with the provided arguments
 * @param {FalconApolloClientConfig} config Falcon configuration for creating ApolloClient instance
 * @returns {Apollo} ApolloClient instance
 */
export function ApolloClient(config = {}) {
  const {
    extraLinks = [],
    isBrowser = false,
    initialState = {},
    clientState = {},
    headers = {},
    apolloClientConfig,
    cache,
    storeCode
  } = config;
  const { httpLink, connectToDevTools, apiVersion, ...restApolloClientConfig } = apolloClientConfig;
  const typeOfNavigator = typeof navigator !== 'undefined';
  const userAgent = typeOfNavigator ? navigator?.userAgent : '';
  const userPlatform = typeOfNavigator ? navigator?.userAgentData?.platform : '';
  const inMemoryCache = cache || new InMemoryCache().restore(initialState);
  inMemoryCache.writeData({ data: clientState.data });

  let httpLinkUri = httpLink.uri;
  if (!isBrowser && clientState.data.config.graphqlUrl) {
    httpLinkUri = clientState.data.config.graphqlUrl;
  }

  if (storeCode) {
    headers['X-ASAM-StoreCode'] = storeCode;
  }
  if (apiVersion) {
    headers['X-ASAM-Version'] = apiVersion;
  }
  if (!isBrowser) {
    headers['X-ASAM-DismissSessionCommit'] = true;
  }

  if (Cookies.get('x-asam-agent')) {
    headers['x-asam-agent'] = Cookies.get('x-asam-agent');
  } else {
    headers['x-asam-agent'] = userAgent;
  }

  if (Cookies.get('x-asam-os-version')) {
    headers['x-asam-os-version'] = Cookies.get('x-asam-os-version');
  } else {
    headers['x-asam-os-version'] = userPlatform;
  }

  if (Cookies.get('x-asam-app-version')) {
    headers['x-asam-app-version'] = Cookies.get('x-asam-app-version');
  } else {
    headers['x-asam-app-version'] = apiVersion;
  }

  const apolloHttpLink = createHttpLink({
    ...httpLink,
    uri: httpLinkUri,
    fetch,
    credentials: 'include',
    headers
  });

  const client = new Apollo(
    deepMerge.all(
      [
        {
          ssrMode: !isBrowser,
          cache: inMemoryCache,
          link: ApolloLink.from([...extraLinks, apolloHttpLink]),
          connectToDevTools: isBrowser && connectToDevTools,
          resolvers: clientState.resolvers,
          defaultOptions: {
            mutate: {
              awaitRefetchQueries: true
            }
          }
        },
        restApolloClientConfig
      ],
      { clone: false }
    )
  );

  client.onResetStore(() => inMemoryCache.writeData({ data: clientState.data }));

  return client;
}
