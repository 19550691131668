export const getFreeOfShipping = (storeConfiguration, priceModificator, product) => {
  // freeShipping calculation
  const freeShippingCategory =
    parseInt(storeConfiguration?.asam_shippinginformation?.free_shipping_category?.id || 0, 10) || null;
  const freeShippingAmount = parseInt(storeConfiguration.asam_shippinginformation.general.free_shipping_amount, 10);

  // check for freeShipping amount
  if (
    storeConfiguration?.asam_shippinginformation?.left_for_freeshipping?.enabled &&
    storeConfiguration?.asam_shippinginformation?.general?.free_shipping_amount
  ) {
    // check if price is higher then freeShippingAmount
    const { special, regular } = product.price || {};
    if (
      (special && special + priceModificator >= freeShippingAmount) ||
      (!special && regular && regular + priceModificator >= freeShippingAmount)
    ) {
      return true;
    }
  }

  // check for freeShippingCategory
  return freeShippingCategory > 0 && !!product.categoryIds?.includes(freeShippingCategory);
};
