import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const GET_SUGGEST_PRODUCTS = gql`
  query SuggestProducts($term: String!, $altTerm: String, $maxProducts: Int) {
    suggest(term: $term, altTerm: $altTerm) {
      productList(input: { pageSize: $maxProducts, preview: false }) {
        items {
          id
          sku
          name
          brand
          type
          productImage {
            default
            retina
            defaultWebp
            retinaWebp
          }
          urlPath
          descriptionHtml
          rating
          reviewsSummary {
            totalCount
            averageRating
          }
          labels {
            entityId
            text
            type
            percent
            isSale
            size
            rotated
            transparent
            badge
            images {
              image
            }
          }
          recommendations {
            items {
              applicationType
              id
              name
              shortName
              sku
              price {
                regular
                special
                minPrice
                maxPrice
                minTier
              }
              productImage {
                default
                retina
                defaultWebp
                retinaWebp
              }
              urlPath
              volume
              popularity
              rating
              reviewsSummary {
                totalCount
                averageRating
              }
              baseprice
              isInStock
              shortDescription
              type
              brand
              options {
                id
              }
              labels {
                entityId
                text
                type
                percent
                isSale
                size
                rotated
                transparent
                badge
                images {
                  image
                }
              }
            }
          }
        }
      }
    }
  }
`;

export class SuggestProductsQuery extends Query {
  static defaultProps = { query: GET_SUGGEST_PRODUCTS };
}
