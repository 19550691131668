import React, { useState } from 'react';
import { Tooltip } from '../Tooltip/Tooltip';
import loadable from '../../../../../components/loadable';
import styles from './Input.module.scss';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../Modal/Modal'));

export const Input = ({
  noMargin,
  removeStyles,
  invalid,
  textCenter,
  hasError,
  hasWarning = false,
  hasSuccess,
  textarea,
  value,
  variant,
  showErrorMessage,
  modalTooltip,
  tooltipMessage,
  itemRef,
  inputIcon,
  showErrorIcon,
  hideValidateStyles,
  dataInspect,
  ...rest
}) => {
  const additionalInputClasses = [];
  const additionalInputWrapperClasses = [];
  let icon = null;

  if (value && value.length) {
    additionalInputClasses.push(styles.InputHasValue);
  }

  if (noMargin) {
    additionalInputClasses.push(styles.InputNoMargin);
  }

  if (textCenter) {
    additionalInputClasses.push(styles.InputTextCenter);
  }

  if (removeStyles) {
    additionalInputClasses.push(styles.WithoutStyles);
  }

  if (textarea) {
    additionalInputClasses.push(styles.TextArea);
  }

  if (hasError && rest.type !== 'hidden' && !hideValidateStyles) {
    additionalInputClasses.push(styles.InputHasError);
    icon = showErrorIcon ? <div className={[styles.ErrorIcon, 'icon-alert-circle'].join(' ')} /> : null;
  }

  if (hasSuccess && rest.type !== 'hidden' && !tooltipMessage && !hideValidateStyles) {
    additionalInputClasses.push(styles.InputHasSuccess);
    icon = <div className={[styles.SuccessIcon, `icon-check`].join(' ')} />;
  }

  if (hasWarning && rest.type !== 'hidden' && !tooltipMessage && !hideValidateStyles) {
    additionalInputClasses.push(styles.InputHasWarning);
  }

  if (variant === 'secondary') {
    additionalInputClasses.push(styles.InputSecondary);
  }

  if (variant === 'notifier') {
    additionalInputWrapperClasses.push(styles.InputWrapperNoShow);
  }

  if (showErrorMessage) {
    additionalInputClasses.push(styles.InputWithVisibleMessage);
  }

  const [showModal, setShowModal] = useState(false);

  return (
    <div className={[styles.InputWrapper, ...additionalInputWrapperClasses].join(' ')}>
      {textarea ? (
        <textarea
          className={[styles.Input, ...additionalInputClasses].join(' ')}
          {...rest}
          value={value || ''}
          invalid={invalid ? 'invalid' : null}
          ref={itemRef}
        />
      ) : (
        <input
          className={[styles.Input, ...additionalInputClasses].join(' ')}
          value={value || ''}
          {...rest}
          invalid={invalid ? 'invalid' : null}
          ref={itemRef}
          data-inspect={dataInspect || null}
        />
      )}

      {rest.label ? (
        <label className={styles.Label} htmlFor={rest.id}>
          {rest.label}
        </label>
      ) : null}
      {icon}
      {modalTooltip ? (
        <>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={[styles.InputTooltip, 'icon-alert-circle', 'paybackBlue'].join(' ')}
            onClick={() => setShowModal(true)}
          />
          <Modal
            content={modalTooltip}
            visible={showModal}
            variant="wrapContent"
            scrollableContent
            close={() => {
              setShowModal(false);
            }}
          />
        </>
      ) : null}
      {tooltipMessage ? (
        <div className={styles.InputTooltip}>
          <Tooltip content={tooltipMessage} id={`${rest.name}Tooltip`} displayRelative placement="top" />
        </div>
      ) : null}
      {inputIcon ? <div className={[styles.InputIcon, `icon-${inputIcon}`].join(' ')} /> : null}
    </div>
  );
};

Input.defaultProps = {
  showErrorIcon: true,
  hideValidateStyles: false
};
