import React from 'react';
import { InView } from 'react-intersection-observer';

export const LazyLoad = ({ children, offset, ...rest }) => {
  return <InView {...rest}>{({ inView, ref }) => <div ref={ref}>{inView && children}</div>}</InView>;
};

LazyLoad.defaultProps = {
  offset: 100,
  triggerOnce: true
};
