import { PageviewTracking } from './Pageview';

export const LastOrderUpdate = ({ storeConfiguration, cart, product, action = 'add' }) => {
  const lastStoredCart = [];
  let dataLayerData = {};
  const bufferSeconds = 45;

  if (!cart) {
    return null;
  }

  let cartCount = 0;
  let cartTotal = 0;

  if (cart?.items) {
    cart.items.forEach(item => {
      const itemItem = {
        name: item?.name,
        id: item?.sku,
        price: item?.priceInclTax || item?.price,
        brand: `${item?.cosBrand || item?.brand} - ${item?.cosmeticline || item?.cosmeticlineValue}`,
        category: item?.categoryPath,
        variant: '',
        quantity: item?.qty
      };

      cartCount += item?.qty;
      cartTotal += (item?.priceInclTax || item?.price) * item?.qty;
      lastStoredCart.push(itemItem);
    });
  }

  dataLayerData = {
    key: `last_stored_cart_${cart.itemsQty || cartCount}`,
    event: 'Update Last Stored Cart',
    cart: {
      action, // remove
      product_id: product?.sku, // current item
      total_quantity: cart.itemsQty || cartCount,
      total_price: parseFloat(cartTotal?.toFixed(2)),
      local_currency: storeConfiguration?.currency?.options?.default,
      product_list: lastStoredCart
    }
  };

  PageviewTracking({ storeConfiguration, dataLayerData, bufferSeconds });
  return null;
};
