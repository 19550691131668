import { PageviewTracking } from './Pageview';

export const ViewItemTracking = ({ storeConfiguration, product, bufferSeconds = 30 }) => {
  const customAttributes = product.customAttributes ? JSON.parse(product.customAttributes) : {};

  const dataLayerData = {
    key: `view_item_${product.sku}`,
    event: 'Ecommerce - Item Detail View',
    event_name: 'view_item',
    shop_country: storeConfiguration?.general?.country?.default,
    view_item: {
      currency: storeConfiguration?.currency?.options?.default, // e.g. 'EUR' | 'CHF'
      items: [
        {
          item_name: product.name, // e.g. 'Magic Finish Make-up Mousse'
          item_id: product.sku, // e.g. '123456789'
          price: product.price.special ? product.price.special : product.price.regular, // e.g. '24.75'
          item_brand: `${customAttributes.cosBrand} - ${customAttributes.cosmeticlineValue}`, // e.g. 'M. Asam - Magic Finish'
          item_category: product.categoryPath, // e.g. 'Make-up'
          item_category_2: customAttributes.applicationType, // e.g. 'Make-up Mousse'
          item_category_3: product.price.special ? 'on sale' : 'regular', // e.g. 'regular' | 'on sale' | 'free gift'
          item_category_4: customAttributes.cosContent, // e.g. '30ml'
          item_variant: customAttributes.farbvariante || undefined, // e.g. 'Ivory'
          item_list_name: '', // e.g. 'Skincare'
          item_list_id: '', // e.g. '987654321'
          index: ''
        }
      ]
    }
  };

  return PageviewTracking({ storeConfiguration, dataLayerData, bufferSeconds });
};
