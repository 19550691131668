import { UrlQuery } from '@deity/falcon-data';
import { Router } from '@deity/falcon-front-kit';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import RouteWithStatus from './RouteWithStatus';

export const DynamicRoute = withStoreConfiguration(({ storeConfiguration, ...props }) => {
  const { components, notFound } = props;

  return (
    <Router>
      {router => {
        const location = props.location || router.location;
        const { pathname: path } = location;

        return (
          <UrlQuery variables={{ path }}>
            {({ data: { url } }) => {
              if (!url || !url.targetPath) {
                const NotFound = notFound;
                return (
                  <RouteWithStatus status={404}>
                    <NotFound location={location} />
                  </RouteWithStatus>
                );
              }

              if (url.redirect) {
                const allowedParameters = [
                  'utm_source',
                  'utm_medium',
                  'utm_term',
                  'utm_content',
                  'utm_campaign',
                  'linksterid',
                  'scrid',
                  'telljaid'
                ];
                const targetPath = new URL(url.targetPath, storeConfiguration?.web?.secure?.base_url);
                const requestPath = new URLSearchParams(location.search);

                allowedParameters.forEach(allowedParameter => {
                  const value = requestPath.get(allowedParameter);
                  if (value) {
                    targetPath.searchParams.append(allowedParameter, value);
                  }
                });

                return (
                  <Redirect
                    to={{
                      pathname: '',
                      fullRedirectUrl: targetPath,
                      state: { status: 301 }
                    }}
                  />
                );
              }

              // flag to show message on product page
              const isRedirect = false;

              const Component = components[url.type];
              if (!Component) {
                throw new Error(`[DynamicRoute]: Please register component for '${url.type}' content type!`);
              }

              return <Component id={url.id} path={url.targetPath} isRedirect={isRedirect} />;
            }}
          </UrlQuery>
        );
      }}
    </Router>
  );
});

DynamicRoute.propTypes = {
  location: PropTypes.any,
  components: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired).isRequired,
  notFound: PropTypes.func.isRequired
};
