import { CartQuery as CartQueryBase } from '@deity/falcon-shop-data';
import gql from 'graphql-tag';

export const GET_CART = gql`
  query Cart {
    cart {
      itemsQty
      quoteCurrency
      couponCode
      paybackNumber
      paybackScrid
      paybackBasePoints
      paybackCoupons
      usedCouponCodes {
        code
        type
      }
      leftForFreeshipping
      virtual
      totals {
        code
        title
        value
      }
      taxLabel {
        percent
        title
      }
      items {
        itemId
        sku
        qty
        name
        price
        originalPrice
        priceInclTax
        rowTotal
        rowTotalInclTax
        discountAmount
        thumbnailUrl
        thumbnailWebpUrl
        cosBrand
        cosContent
        cosmeticline
        applicationType
        categoryPath
        farbvariante
        normPrice
        urlKey
        freeItem
        isFreeItemActive
        shortDescription
        entityId
        categoryIds
        itemOptions {
          label
          value
        }
      }
    }
  }
`;

export class CartQuery extends CartQueryBase {
  static defaultProps = { ...super.defaultProps, query: GET_CART, notifyOnNetworkStatusChange: true, ssr: false };
}
