import React, { useState } from 'react';
import { T } from '@deity/falcon-i18n';
import { useKeenSlider } from 'keen-slider/react';
import { LazyLoad } from '../../helpers/LazyLoad/LazyLoad';
import loadable from '../../../../../components/loadable';
import styles from './Lightbox.module.scss';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../ui/Modal/Modal'));

export const Lightbox = ({ images, isOpen, onClose, currentIndex, changeIndex, showArrows }) => {
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: currentIndex || 0,
    loop: true,
    slideChanged(slider) {
      const idx =
        (slider.track.details.abs + 1) % slider.track.details.slides.length !== 0
          ? Math.abs((slider.track.details.abs + 1) % slider.track.details.slides.length)
          : slider.track.details.slides.length;
      changeIndex(idx);
    },
    created() {
      setLoaded(true);
    }
  });
  const closeGalleryModal = () => {
    onClose();
    instanceRef.current?.destroy();
  };

  const Arrow = props => {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        onClick={props.onClick}
        className={[styles.Arrow, props.left ? styles.ArrowPrev : styles.ArrowNext].join(' ')}
        aria-label={props.left ? 'prev' : 'next'}
      />
    );
  };

  const currentPosition =
    (instanceRef.current?.track.details.abs + 1) % instanceRef.current?.track.details.slides.length !== 0
      ? Math.abs((instanceRef.current?.track.details.abs + 1) % instanceRef.current?.track.details.slides.length)
      : instanceRef.current?.track.details.slides.length;

  const gallerySlider = (
    <LazyLoad>
      <div className="navigation-wrapper">
        <div ref={sliderRef} className="keen-slider">
          {images.map((image, index) => {
            return (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions,react/no-array-index-key
              <div className={['keen-slider__slide', styles.ImageItem].join(' ')} key={`lightBox_${index}`}>
                <img src={`${image.full}`} alt={`${image.originalAlt}`} />
              </div>
            );
          })}
        </div>
        {showArrows && loaded && instanceRef.current && (
          <>
            <Arrow left onClick={e => e.stopPropagation() || instanceRef.current?.prev()} />

            <Arrow onClick={e => e.stopPropagation() || instanceRef.current?.next()} />
          </>
        )}
      </div>
      {loaded && instanceRef.current && (
        <div className={styles.imageCaption}>
          <p>{images[currentPosition - 1]?.title}</p>
          <p>
            <T id="gallery.image" /> {currentPosition} <T id="gallery.from" />{' '}
            {instanceRef.current.track.details.slides.length}
          </p>
        </div>
      )}
    </LazyLoad>
  );

  return isOpen ? (
    <Modal content={gallerySlider} close={closeGalleryModal} visible={isOpen} variant="lightbox" />
  ) : null;
};
