import { PageviewTracking } from '../Pageview';

export const NavigationHeaderTracking = ({ storeConfiguration, key, label }) => {
  const dataLayerData = {
    key: `navigation_header_${key}_${label}`,
    event: 'Generic Event',
    event_name: 'navigation_header',
    navigation_header: {
      navigation_bar: key,
      navigation_element: label,

      event_category: 'navigation',
      event_action: key,
      event_label: label
    }
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
