import { useEffect, useState } from 'react';
import { convertToDate } from '../../helpers/DateFormat/DateFormat';
import { isDateAfterNow, isNowInRange } from '../../helpers/Date/Date';

export const MEGAMENU_LINK = 1;
export const MEGAMENU_CATEGORY = 2;
export const MEGAMENU_SUBMENU = 3;

export const MEGAMENU_ITEM_HIGHLIGHTED = 1;
export const MEGAMENU_ITEM_LIVE = 2;

export const getNavBarUrl = item => {
  let url = '';
  let customUrl = null;
  let categoryUrl = null;

  switch (item.type) {
    case MEGAMENU_LINK:
      ({ url } = item);
      break;
    case MEGAMENU_CATEGORY:
      ({ url } = item?.category_link);
      break;
    case MEGAMENU_SUBMENU:
      customUrl = item?.url;
      categoryUrl = item?.category_link ? item?.category_link?.url : null;

      url = customUrl || categoryUrl;
      break;
    default:
      url = '';
  }

  if (url !== null && url.indexOf('/') !== 0) {
    url = `/${url}`;
  }

  return url;
};

export const isItemUrlExternal = url => url.indexOf('http') !== -1 || url.indexOf('www') !== -1;

export const getNavBarTitle = item => {
  switch (item.type) {
    case MEGAMENU_LINK:
    case MEGAMENU_SUBMENU:
      return item?.custom_title || item?.category_link?.name || item?.name || item?.title;
    case MEGAMENU_CATEGORY:
      return item?.custom_title || item?.category_link?.name;
    default:
      return null;
  }
};

export const updateHighlightItem = ({ item, setIsHighlightedItem, setIsLiveItem }) => {
  const [hlChanged, setHlChanged] = useState(true);

  useEffect(() => {
    if (!hlChanged) {
      return;
    }
    const hlFromDate = convertToDate(item.hl_from_date);
    const hlToDate = convertToDate(item.hl_to_date);
    const isHlDate = isNowInRange(hlFromDate, hlToDate);
    setIsLiveItem(isHlDate && item.highlight === MEGAMENU_ITEM_LIVE);
    setIsHighlightedItem(isHlDate && item.highlight === MEGAMENU_ITEM_HIGHLIGHTED);
    if (hlFromDate && isDateAfterNow(hlFromDate)) {
      setTimeout(() => setHlChanged(true), hlFromDate.getTime() - new Date().getTime() + 2);
    }
    if (hlToDate && isDateAfterNow(hlToDate)) {
      setTimeout(() => setHlChanged(true), hlToDate.getTime() - new Date().getTime() + 2);
    }
    setHlChanged(false);
  }, [hlChanged]);
};
