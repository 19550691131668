import { getClientConfigResolver } from '@deity/falcon-front-kit';
import { openSidebarResolver, closeSidebarResolver } from 'src/components';
import { openLoaderResolver, closeLoaderResolver } from './theme/asam/components/ui/Loader/LoaderResolvers';

/**
 * Defines client-side state resolvers
 */

export default {
  data: {
    sidebar: {
      contentType: null,
      side: 'right',
      isOpen: false,
      __typename: 'SidebarStatus'
    },
    loader: {
      isVisible: false,
      __typename: 'LoaderStatus'
    }
  },

  resolvers: {
    Query: {
      clientConfig: getClientConfigResolver
    },

    Mutation: {
      openSidebar: openSidebarResolver,
      closeSidebar: closeSidebarResolver,
      openLoader: openLoaderResolver,
      closeLoader: closeLoaderResolver
    }
  }
};
