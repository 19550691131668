import { PageviewTracking } from './Pageview';

export const CtaBannerTracking = ({ storeConfiguration, ctaBanner }) => {
  if (ctaBanner) {
    const dataLayerData = {
      event: 'Generic Event',
      event_name: 'ctabanner_click',
      key: `cta_banner_click_id_${ctaBanner.id}`,
      timestamp: Math.floor(new Date().getTime() / 1000),
      ctabanner_click: {
        ctabanner_label: `${ctaBanner.id} - ${ctaBanner.content}`,
        event_category: 'ctabanner',
        event_action: 'click',
        event_label: `${ctaBanner.id} - ${ctaBanner.content}`
      }
    };
    return PageviewTracking({ storeConfiguration, dataLayerData });
  }
};
