import { T } from '@deity/falcon-i18n';
import React from 'react';
import { ErrorSummary } from '@deity/falcon-ui-kit';
import { Button } from '../../../ui/Button/Button';
import loadable from '../../../../../../components/loadable';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../../ui/Modal/Modal'));

const getPopupContent = (message, toggle) => (
  <div>
    <div className="title">
      <T id="product.failedToAddToCart" />
    </div>
    {message && <ErrorSummary errors={message} />}
    <br />
    <Button onClick={toggle}>
      <T id="product.close" />
    </Button>
  </div>
);

export const AddedToCartErrorPopup = ({ on, toggle, message }) => {
  return <Modal variant="secondary" content={getPopupContent(message, toggle)} close={toggle} visible={on} autohide />;
};
