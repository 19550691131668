import { GoogleTagManager4Pageview } from './Pageview';

export const GoogleTagManager4OdoscopeTracking = ({ trackingId, area }) => {
  const dataLayerData = {
    event: 'Generic Event',
    key: `odoscope_tracking_${area}`,
    event_name: 'odoscope',
    odoscope: {
      odoscope_action: 'odoscope',
      odoscope_tracking_id: `${trackingId}`,
      event_category: 'odoscope',
      event_action: 'odoscope',
      event_label: `${trackingId}`
    }
  };

  return GoogleTagManager4Pageview({ dataLayerData });
};
