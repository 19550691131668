import { Locale } from '@deity/falcon-front-kit';
import PropTypes from 'prop-types';
import React from 'react';
import { T } from '@deity/falcon-i18n';
import styles from './Price.module.scss';

// Price formatting based on Intl api, see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
export const Price = ({ value, specialPrice, currency, locale, variant, hideUVP = false }) => (
  <Locale>
    {localeContext => {
      const localeCode = locale || localeContext.locale;
      const localFallback = 'en';
      let special = null;

      if (specialPrice) {
        special = (
          <div className={styles.SpecialPrice}>
            {new Intl.NumberFormat([localeCode, localFallback], {
              style: 'currency',
              currency: currency || localeContext.currency
            }).format(specialPrice)}
          </div>
        );
      }
      return (
        <div
          className={[
            special ? styles.SpecialMode : null,
            variant === 'secondary' ? styles.Secondary : null,
            variant === 'inherit' ? styles.Inherit : null
          ].join(' ')}
        >
          {special && !hideUVP ? (
            <span className={styles.PriceUvp}>
              <T id="product.retailPrice" />
            </span>
          ) : null}

          <div className={styles.Price}>
            {new Intl.NumberFormat([localeCode, localFallback], {
              style: 'currency',
              currency: currency || localeContext.currency
            }).format(value)}
          </div>
          {special}
        </div>
      );
    }}
  </Locale>
);

Price.defaultPops = {
  variant: 'primary'
};

Price.propTypes = {
  /** Set default price */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Set special price */
  specialPrice: PropTypes.number,
  /** Style variant */
  variant: PropTypes.oneOf(['primary', 'secondary'])
};
