import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  getNavBarTitle,
  getNavBarUrl,
  isItemUrlExternal,
  MEGAMENU_SUBMENU,
  updateHighlightItem
} from '../MegaMenuHelpers';
import { NavigationMobileTracking } from '../../../elements/Tracking/GoogleTagManager';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { MOBILE_MENU_FIRST } from './MobileMenuLevels';
import styles from './MobileMenu.module.scss';

export const MobileMenuRootLevel = withStoreConfiguration(
  ({ storeConfiguration, data, onFirstLevelChange, openLevel, onClose }) => {
    return (
      <div className={[styles.MobileMenuContent, styles.MobileMenuRootLevel].join(' ')}>
        <ul className={styles.MobileMenuList}>
          {data.map((item, index) => {
            const navbarItemUrl = getNavBarUrl(item);
            const isUrlExternal = isItemUrlExternal(navbarItemUrl);
            const itemTitle = getNavBarTitle(item);
            const hasSubmenu = item.type === MEGAMENU_SUBMENU && item.elements.length;
            let itemContent = null;
            const [isLiveItem, setIsLiveItem] = useState(false);
            const [isHighlightedItem, setIsHighlightedItem] = useState(false);
            updateHighlightItem({ item, setIsHighlightedItem, setIsLiveItem });

            if (navbarItemUrl && !hasSubmenu) {
              if (isUrlExternal) {
                itemContent = (
                  <a href={navbarItemUrl}>
                    <span>{itemTitle}</span>
                  </a>
                );
              } else {
                itemContent = (
                  <Link to={navbarItemUrl}>
                    <span>{itemTitle}</span>
                  </Link>
                );
              }
            } else {
              itemContent = <span>{itemTitle}</span>;
            }

            return (
              <li
                className={[
                  styles.MobileMenuListItem,
                  hasSubmenu ? styles.MobileMenuListItemHasSub : '',
                  isHighlightedItem ? styles.MobileMenuItemHighlighted : '',
                  isLiveItem ? styles.MobileMenuListItemLive : ''
                ].join(' ')}
                onClick={() => {
                  if (hasSubmenu) {
                    onFirstLevelChange(index);
                    openLevel(MOBILE_MENU_FIRST);
                    NavigationMobileTracking({
                      storeConfiguration,
                      key: `burger_menu_mobile_${MOBILE_MENU_FIRST}`,
                      label: itemTitle
                    });
                  } else {
                    onClose();
                    NavigationMobileTracking({
                      storeConfiguration,
                      key: `burger_menu_mobile_${MOBILE_MENU_FIRST}`,
                      label: itemTitle
                    });
                  }
                }}
                key={`mobile-menu-root-${index}`} // eslint-disable-line react/no-array-index-key
                aria-hidden
              >
                {itemContent}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
);
