import withStoreConfiguration from './StoreConfiguration';

export const StoreDetect = withStoreConfiguration(({ storeConfiguration }) => {
  const storeView = storeConfiguration?.general?.country?.default;
  const bodyElement = typeof window !== 'undefined' && document.getElementsByTagName('body')[0];
  if (bodyElement) {
    bodyElement.setAttribute('data-store', storeView);
  }
  return null;
});
