import React from 'react';
import { InView } from 'react-intersection-observer';
import { PageviewTracking } from './Pageview';

export const PromotionTracking = ({
  storeConfiguration,
  product,
  trackingCategory,
  trackingAction,
  promotionName,
  creativeName,
  creativeSlot,
  trackingLabel,
  children
}) => {
  if (typeof window !== 'undefined') {
    let itemData = {};
    let productSku;

    if (product) {
      let price;
      productSku = product.sku;

      if (product.price && product.price.special) {
        price = product.price.special;
      } else if (product.price && product.price.minPrice) {
        price = product.price.minPrice;
      } else {
        price = '';
      }

      itemData = {
        item_name: product.name, // e.g. 'Magic Finish Make-up Mousse'
        item_id: product.sku, // e.g. '123456789'
        price, // e.g. '24.75'
        item_brand: `${product.brand} - ${product.cosmeticlineValue}`, // e.g. 'M. Asam - Magic Finish'
        item_category: product.categoryPath, // e.g. 'Make-up'
        item_category_2: product.applicationType, // e.g. 'Make-up Mousse'
        item_category_3: product.price && product.price.special ? 'on sale' : 'regular', // e.g. 'regular' | 'on sale' | 'free gift'
        item_category_4: product.volume, // e.g. '30ml'
        item_variant:
          product.options &&
          product.options[0] &&
          product.options[0].values &&
          product.options[0].values[0] &&
          product.options[0].values[0].label
            ? product.options[0].values[0].label
            : '', // e.g. 'Ivory'
        item_list_name: '',
        item_list_id: '', // e.g. '987654321'
        index: ''
      };
    }

    const dataLayerDataView = {
      key: `promotion_tracking_view_${productSku}_${trackingLabel}_${trackingCategory}_${trackingAction}`,
      event: 'Ecommerce - Promotion view',
      event_name: 'view_promotion',
      shop_country: storeConfiguration?.general?.country?.default,
      view_promotion: {
        items: [
          {
            promotion_id: '',
            promotion_name: promotionName,
            creative_name: creativeName,
            creative_slot: creativeSlot,
            location_id: '',
            ...itemData
          }
        ]
      }
    };

    const dataLayerDataClick = {
      key: `promotion_tracking_click_${productSku}_${trackingLabel}`,
      event: 'Ecommerce - Promotion click',
      event_name: 'select_promotion',
      shop_country: storeConfiguration?.general?.country?.default,
      select_promotion: {
        items: [
          {
            promotion_id: '',
            promotion_name: promotionName,
            creative_name: creativeName,
            creative_slot: creativeSlot,
            location_id: '',
            ...itemData
          }
        ]
      }
    };

    const trackView = inView => {
      if (inView) {
        PageviewTracking({
          storeConfiguration,
          dataLayerData: dataLayerDataView,
          clearTagmanager: false,
          bufferSeconds: 3600
        });
      }
    };

    const trackClick = () => {
      PageviewTracking({ storeConfiguration, dataLayerData: dataLayerDataClick });
    };

    return (
      <InView as="div" onChange={inView => trackView(inView)} onClick={() => trackClick()}>
        {children}
      </InView>
    );
  }
  return <div>{children}</div>;
};
