import React, { useState } from 'react';
import { I18n } from '@deity/falcon-i18n';
import { Form, Formik } from 'formik';
import { FormField } from '../../../ui/Forms/FormField';
import { Radio } from '../../../ui/Radio/Radio';
import { Dropdown } from '../../../ui/Dropdown/Dropdown';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { replaceMarkdown } from '../../../helpers/Markdown/Markdown';
import loadable from '../../../../../../components/loadable';
import styles from './ProductSubscribe.module.scss';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../../ui/Modal/Modal'));

export const ProductSubscribe = withStoreConfiguration(
  ({ storeConfiguration, product, setAboSelect, aboInterval, setAboInterval, tR }) => {
    const [showAboInfoOverlay, setShowAboInfoOverlay] = useState(false);
    const sOneTimeLabel = storeConfiguration?.asam_subscriptions?.labels?.one_time_purshase;
    const sOneTimeDescription = storeConfiguration?.asam_subscriptions?.labels?.one_time_description;
    const sAboLabel = storeConfiguration?.asam_subscriptions?.labels?.subscription_purshase;
    const sAboLabelInfo = storeConfiguration?.asam_subscriptions?.labels?.subscription_info;
    const sAboDescription = storeConfiguration?.asam_subscriptions?.labels?.subscription_description;
    const sAboBenefits = storeConfiguration?.asam_subscriptions?.labels?.subscription_benefits;
    const sMoreInfoUrl = storeConfiguration?.asam_subscriptions?.labels?.subscription_info_url;
    const sDefaultValue = product?.subscriptionOffer?.defaultSet || false;
    const [isSubscription, setIsSubscription] = useState(sDefaultValue);
    const aboIntervals = product?.subscriptionOffer?.intervals || [];
    const searchParameters = (typeof window !== 'undefined' && window.location.search) || '';
    const queryParams = new URLSearchParams(searchParameters);
    const defaultInterval = parseInt(product?.subscriptionOffer?.defaultInterval, 10);
    const paramAboInterval = aboIntervals?.includes(parseInt(queryParams.get('aboInterval'), 10))
      ? parseInt(queryParams.get('aboInterval'), 10)
      : null;
    const preSelectedInterval = paramAboInterval || aboInterval || defaultInterval;
    const highlightBestsellerInterval = product?.subscriptionOffer?.highlightBestsellerInterval;

    const intervals = aboIntervals.map(item => ({
      value: item.toString(),
      label: item > 1 ? `${item} ${tR('subscription.intervalMonths')}` : `${item}  ${tR('subscription.intervalMonth')}`,
      bestSellerLabel: item === defaultInterval && highlightBestsellerInterval ? ` ${tR('product.bestSeller')}` : null
    }));

    return (
      <I18n>
        {t => (
          <div className={styles.AboWrapper}>
            <Formik
              onSubmit={() => {
                // console.log('misha');
              }}
              initialValues={{ aboSelect: isSubscription, aboInterval: preSelectedInterval }}
            >
              {({ values }) => {
                setAboSelect(values.aboSelect);
                setAboInterval(values.aboInterval);
                return (
                  <Form id="aboSelect">
                    <FormField name="aboSelect" key="aboSelect" required className={styles.AboSelection}>
                      {({ form, field }) => {
                        return (
                          <>
                            <Radio
                              key="nameAboSelect-0"
                              name="nameAboSelect"
                              value="0"
                              checked={!isSubscription}
                              onChange={() => {
                                form.setFieldValue(field.name, !values.aboSelect);
                              }}
                              onClick={() => {
                                setIsSubscription(false);
                              }}
                              label={sOneTimeLabel}
                            />
                            <Radio
                              key="nameAboSelect-1"
                              name="nameAboSelect"
                              value="1"
                              checked={isSubscription}
                              onChange={() => {
                                form.setFieldValue(field.name, !values.aboSelect);
                              }}
                              onClick={() => {
                                setIsSubscription(true);
                              }}
                              label={sAboLabel}
                              icon={sAboLabelInfo}
                            />
                          </>
                        );
                      }}
                    </FormField>
                    {isSubscription ? (
                      <>
                        <div className={styles.TextWrapper}>
                          <p>{replaceMarkdown(sAboDescription)}</p>
                        </div>
                        <div className={styles.DisplayIntervals}>
                          <span>{t('subscription.displayInterval')}</span>

                          <FormField name="aboInterval" key="aboInterval">
                            {({ form, field }) => {
                              return (
                                <Dropdown
                                  preSelected={preSelectedInterval?.toString()}
                                  options={intervals}
                                  type="secondary"
                                  onChange={value => {
                                    form.setFieldValue(field.name, parseInt(value, 10));

                                    setAboInterval(parseInt(value, 10));
                                  }}
                                  highlightBestsellerInterval={highlightBestsellerInterval}
                                />
                              );
                            }}
                          </FormField>
                        </div>
                        <div className={styles.TextWrapper}>
                          {sAboBenefits ? (
                            <ul>
                              {sAboBenefits.split('|').map((benefit, index) => {
                                // eslint-disable-next-line react/no-array-index-key
                                return <li key={`benefit_${index}`}>{replaceMarkdown(benefit)}</li>;
                              })}
                            </ul>
                          ) : null}
                          <span
                            className={styles.MoreLink}
                            onClick={() => setShowAboInfoOverlay(true)}
                            role="button"
                            tabIndex={0}
                          >
                            {t('subscription.mehrInfoAbo')}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className={styles.TextWrapper}>
                        <p>{replaceMarkdown(sOneTimeDescription)}</p>
                        <span
                          className={styles.MoreLink}
                          onClick={() => setShowAboInfoOverlay(true)}
                          role="button"
                          tabIndex={0}
                        >
                          {t('subscription.mehrInfo')}
                        </span>
                      </div>
                    )}
                  </Form>
                );
              }}
            </Formik>
            {showAboInfoOverlay ? (
              <Modal
                pageContent={sMoreInfoUrl?.replace('/', '')}
                variant="tertiary"
                scrollableContent
                visible={showAboInfoOverlay}
                close={() => {
                  setShowAboInfoOverlay(false);
                }}
              />
            ) : null}
          </div>
        )}
      </I18n>
    );
  }
);
