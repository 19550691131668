import React from 'react';
import { Link } from '@deity/falcon-ui';
import { T } from '@deity/falcon-i18n';
import { SignOutMutation } from '@deity/falcon-shop-data';
import { withRouter } from 'react-router-dom';

export const SidebarLogout = withRouter(({ history }) => {
  return (
    <SignOutMutation>
      {signOut => (
        <Link className="after-icon-logout" onClick={() => signOut().then(() => history.push('/logout'))}>
          <T id="accountProfile.logout" />
        </Link>
      )}
    </SignOutMutation>
  );
});
