import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@deity/falcon-ui';
import { TotalRow } from '../../TotalRow/TotalRow';

const TOTALS = {
  SHIPPING: 'shipping',
  SUBTOTAL: 'subtotal',
  OVERALL_SUBTOTAL: 'overall_subtotal',
  GRAND_TOTAL: 'grand_total',
  DISCOUNT: 'discount',
  OVERALL_DISCOUNT: 'overall_discount',
  TAX: 'tax',
  GIFTCERT: 'ugiftcert',
  OTHER_TAX: 'order_fee'
};

// helper that returns particular total by its code
const getTotalByCode = (totals, code) => totals.filter(total => total.code === code);
// prepare to save total of product discounts without coupon

const CartTotals = ({
  taxLabel,
  couponCode,
  totalsData,
  totalsToDisplay = [],
  pink = [],
  bold = [],
  grey = [],
  showShippingTooltip,
  ...props
}) => {
  // check for overall_discount
  const overallDiscount = totalsData.find(total => total.code === TOTALS.OVERALL_DISCOUNT);
  // if overall_discount remove discount and subtotal
  if (overallDiscount) {
    const remove = [TOTALS.DISCOUNT, TOTALS.SUBTOTAL];
    totalsToDisplay = totalsToDisplay.filter(totals => !remove.includes(totals));
  }
  // if no overall_discount remove overall_subtotal
  if (!overallDiscount) {
    const remove = [TOTALS.OVERALL_SUBTOTAL];
    totalsToDisplay = totalsToDisplay.filter(totals => !remove.includes(totals));
  }

  return (
    <Box {...props}>
      {totalsToDisplay.map((totalCode, index) => {
        if (totalCode === 'divider') {
          return <Divider key={`divider-${index}`} />; // eslint-disable-line react/no-array-index-key
        }

        const totalObj = getTotalByCode(totalsData, totalCode);
        const outputTotalRow = [];

        if (totalObj.length) {
          // eslint-disable-next-line array-callback-return
          totalObj.map(total => {
            outputTotalRow.push(
              <TotalRow
                key={totalCode}
                taxLabel={taxLabel}
                {...total}
                pink={pink.indexOf(totalCode) !== -1}
                bold={bold.indexOf(totalCode) !== -1}
                grey={grey.indexOf(totalCode) !== -1}
                showShippingTooltip={showShippingTooltip}
                couponCode={couponCode}
              />
            );
          });

          return outputTotalRow;
        }

        return null;
      })}
    </Box>
  );
};

CartTotals.propTypes = {
  totalsData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.number
    })
  ),
  originalTotal: PropTypes.number,
  hasDiscount: PropTypes.bool,
  totalsToDisplay: PropTypes.arrayOf(PropTypes.string),
  bold: PropTypes.arrayOf(PropTypes.string)
};

CartTotals.TOTALS = TOTALS;

export default CartTotals;
