import React from 'react';
import { NavbarItemMenu } from '@deity/falcon-ui';
import window from 'global';
import useCheckBreakpoints from '../../../hooks/CheckBreakpoint';
import { Special } from './Special/Special';
import { Image } from './Image/Image';
import { Category } from './Category/Category';
import { Link } from './Link/Link';
import styles from './MegaMenuSubmenu.module.scss';

const CATEGORY_SECTION_KEY = 'category';
const LINK_SECTION_KEY = 'link';
const SPECIAL_SECTION_KEY = 'special';
const IMAGE_SECTION_KEY = 'image';

export const MegaMenuSubmenu = ({ data, subMenuId, subMenuClass, closeSubmenu }) => {
  const { isDesktop } = useCheckBreakpoints();
  let listSection = null;
  const columns = {};

  data.elements.forEach(element => {
    columns[`column${element.column}`] = [];
  });

  data.elements.forEach(element => {
    columns[`column${element.column}`].push(element);
  });

  function isTouch() {
    return 'ontouchstart' in window;
  }

  return (
    <NavbarItemMenu className={styles.MenuDrawer} openClassName={styles.MenuDrawerVisible}>
      <div className={styles.MenuDrawerList}>
        {Object.keys(columns).map(key => (
          <div className={styles.MenuDrawerColumnGroup} key={key}>
            {columns[key].map((element, index) => {
              switch (element.type_name) {
                case LINK_SECTION_KEY:
                  // eslint-disable-next-line react/no-array-index-key
                  listSection = (
                    <Link
                      {...element.data_link}
                      key={`submenu-link-${index}`} /* eslint-disable-line */
                      subMenuId={subMenuId}
                      subMenuClass={subMenuClass}
                      parent={data}
                    />
                  );
                  break;
                case CATEGORY_SECTION_KEY:
                  // eslint-disable-next-line react/no-array-index-key
                  listSection = (
                    <Category
                      {...element.data_category}
                      key={`submenu-category-${index}`} /* eslint-disable-line */
                      subMenuId={subMenuId}
                      subMenuClass={subMenuClass}
                      parent={data}
                    />
                  );
                  break;
                case IMAGE_SECTION_KEY:
                  // eslint-disable-next-line react/no-array-index-key
                  listSection = isDesktop && (
                    <React.Fragment key={`submenu-image-${index}`} /* eslint-disable-line */>
                      <Image
                        {...element.data_image}
                            key={`submenu-image-${index}`} /* eslint-disable-line */
                        subMenuId={subMenuId}
                        subMenuClass={subMenuClass}
                        parent={data}
                      />
                    </React.Fragment>
                  );
                  break;
                default:
                  listSection = null;
              }

              return listSection;
            })}
          </div>
        ))}
      </div>
      {data.elements.map((element, index) => {
        if (element?.type_name === SPECIAL_SECTION_KEY) {
          // eslint-disable-next-line react/no-array-index-key
          return <Special {...element.data_special} key={`submenu-special-${index}`} parent={data} />;
        }

        return null;
      })}
      {isTouch() ? (
        <button
          className={[styles.MenuDrawerClose, 'icon-chevron-up'].join(' ')}
          type="button"
          onClick={() => closeSubmenu()}
        />
      ) : null}
    </NavbarItemMenu>
  );
};
