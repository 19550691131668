import React from 'react';

const OnSSR = () => {
  return null;
};

const addPropsToChildren = (children, props) => {
  return React.Children.map(children, child =>
    React.isValidElement(child) ? React.cloneElement(child, props) : child
  );
};

export default class NoSsrRoute extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isClient: false
    };
  }

  componentDidMount() {
    this.setState({ isClient: true });
  }

  render() {
    const { isClient } = this.state;
    const { children, onSsr = <OnSSR />, ...rest } = this.props;
    return addPropsToChildren(isClient ? children : onSsr, rest);
  }
}
