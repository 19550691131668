import { PageviewTracking } from './Pageview';

export const OutboundLink = ({ storeConfiguration, url }) => {
  const dataLayerData = {
    key: `outbound_link_${url}`,
    event: 'Generic Event',
    event_category: 'outbound click',
    event_action: url,
    event_label: undefined
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
