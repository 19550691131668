import React from 'react';
import { Link } from 'react-router-dom';
import { T, I18n } from '@deity/falcon-i18n';
import { BackendConfigQuery } from '@deity/falcon-shop-data';
import { CustomerQuery } from '../../../overrides/Customer/CustomerQuery';
import { Button } from '../../../ui/Button/Button';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { LanguageSwitcher } from '../../../elements/LanguageSwitcher/LanguageSwitcher';
import { SidebarLogout } from '../../../elements/SidebarNavigation/SidebarLogout';
import headerSvg from '../../../../../../assets/images/sprites/headerSprite.svg';
import styles from './MobileMenu.module.scss';

export const MobileMenuAdditional = withStoreConfiguration(({ storeConfiguration, onClose }) => {
  let languageSwitch = true;
  if (storeConfiguration?.asam_store?.language_switch?.enabled === '0') {
    languageSwitch = false;
  }
  const paybackActive = storeConfiguration?.payback?.general?.enabled === '1';

  return (
    <I18n>
      {t => (
        <BackendConfigQuery>
          {({
            data: {
              backendConfig: {
                shop: { activeStore, stores },
                activeLocale
              }
            }
          }) => {
            const locale = [];
            const storeCode = activeLocale;
            stores.forEach(item => {
              if (item.code === activeStore) {
                locale.push(t('domain.storeShortCode'));
              }
            });
            return (
              <div className={styles.MobileMenuAdditionalContent}>
                <ul className={styles.MobileMenuList}>
                  {paybackActive ? (
                    <li className={[styles.MobileMenuListItem, styles.MobileMenuListItemPayback].join(' ')} aria-hidden>
                      <Link to={`/${t('cms.payback.link')}`}>
                        <img src={`${headerSvg}#PaybackLogoMobile`} alt="Payback Logo" width={21} height={21} />
                        <T id="mobileMenu.payback" />
                      </Link>
                    </li>
                  ) : null}
                  {storeCode !== 'fr-FR' && (
                    <li
                      className={[styles.MobileMenuListItem, styles.MobileMenuListItemCustomer].join(' ')}
                      onClick={onClose}
                      aria-hidden
                    >
                      <Link to={`/${t('cms.contact.link')}`}>
                        <T id="mobileMenu.customer" />
                      </Link>
                    </li>
                  )}

                  <li
                    className={[styles.MobileMenuListItem, styles.MobileMenuListItemFaq].join(' ')}
                    aria-hidden
                    onClick={onClose}
                  >
                    <Link to={`/${t('cms.helpfaq.link')}`}>
                      <T id="mobileMenu.faq" />
                    </Link>
                  </li>

                  {languageSwitch && <LanguageSwitcher mobileView />}
                </ul>

                <CustomerQuery>
                  {({ data: { customer } }) => (
                    <>
                      <div className={styles.LoginButton}>
                        <Button
                          size="small"
                          variant="secondary"
                          asLink
                          path={customer ? '/account' : '/sign-in'}
                          onClick={onClose}
                        >
                          <T id={customer ? 'mobileMenu.account' : 'mobileMenu.login'} />
                        </Button>
                      </div>

                      {customer ? (
                        <div className={styles.LogoutButton}>
                          <SidebarLogout />
                        </div>
                      ) : null}
                    </>
                  )}
                </CustomerQuery>
              </div>
            );
          }}
        </BackendConfigQuery>
      )}
    </I18n>
  );
});
