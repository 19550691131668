import { ApolloConsumer } from '@apollo/react-common'; // eslint-disable-line
import React from 'react';
import { I18n } from '@deity/falcon-i18n';
import { BackendConfigQuery } from '@deity/falcon-shop-data';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { LoaderContext } from '../../ui/Loader/LoaderContext';
import mobileStyles from '../../sections/MegaMenu/MobileMenu/MobileMenu.module.scss';
import styles from './LanguageSwitcher.module.scss';

const { Consumer: LoaderConsumer } = LoaderContext;

export const LanguageSwitcher = withStoreConfiguration(({ storeConfiguration, mobileView }) => (
  <BackendConfigQuery>
    {({
      data: {
        backendConfig: {
          shop: { activeStore, stores }
        }
      }
    }) => {
      if (storeConfiguration?.asam_store?.language_switch?.enabled === '0') {
        return null;
      }

      const storeLocale = [];
      let showWidget = false;
      const storesConf =
        storeConfiguration?.asam_store?.language_switch?.external_systems &&
        Object.values(JSON.parse(storeConfiguration.asam_store.language_switch.external_systems));

      const magazine = storeConfiguration?.asam_tracking?.googletagmanager?.magazine_url_identifier;

      return (
        <I18n>
          {(t, i18n) => (
            <ApolloConsumer>
              {() => (
                <LoaderConsumer>
                  {({ showGlobalLoader }) => {
                    const changeLanguage = storeViewCode => {
                      let currentSuffix;

                      storesConf.forEach(storeItem => {
                        if (storeItem.storecode === activeStore) {
                          currentSuffix = storeItem.suffix;
                        }
                      });

                      showGlobalLoader(true);
                      const { locale, suffix } = storesConf.find(({ storecode }) => storecode === storeViewCode);
                      i18n.changeLanguage(locale);
                      const target = document.querySelectorAll(`[hreflang="${locale}"]`);
                      const validateTarget = target && target[0] ? target[0].href : null;
                      if (storesConf) {
                        let redirectUrl = `/${suffix}/${window.location.pathname}`
                          .replace('//', '/')
                          .replace('///', '/');
                        if (validateTarget === null && currentSuffix !== '/' && currentSuffix !== '') {
                          redirectUrl = redirectUrl
                            .replace(currentSuffix, '')
                            .replace('//', '/')
                            .replace('///', '/');
                        }
                        if (validateTarget !== null) {
                          redirectUrl = validateTarget;
                        }
                        if (validateTarget === null && redirectUrl?.includes(magazine)) {
                          redirectUrl = `/${suffix}`;
                        }
                        window.location.href = redirectUrl;
                      }
                    };

                    const results = stores.map(item => {
                      let lang = '';
                      let languagesCount = 0;
                      if (item.code === activeStore) {
                        storeLocale.push(<I18n key={item.code}>{m => m('domain.storeShortCode')}</I18n>);
                        const storeWebsite = item.website;

                        if (stores.filter(itemSite => itemSite.website === storeWebsite).length > 1) {
                          languagesCount = stores.filter(itemSite => itemSite.website === storeWebsite).length;
                          lang = stores
                            .filter(itemSite => itemSite.website === storeWebsite)
                            .map(itemSite => {
                              if (
                                storesConf.filter(
                                  itemConf => itemConf.storecode === itemSite.code && itemConf.active === '1'
                                ).length
                              ) {
                                return (
                                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                  <li
                                    key={`item-${itemSite.code}`}
                                    onClick={() => changeLanguage(itemSite.code)}
                                    className={itemSite.code === activeStore ? 'active' : ''}
                                  >
                                    {itemSite.name}
                                  </li>
                                );
                              }
                              languagesCount--;

                              return null;
                            });
                        }
                      }

                      if (languagesCount > 1) {
                        showWidget = true;
                      }

                      return lang;
                    });

                    return (
                      showWidget && (
                        <>
                          {mobileView ? (
                            <li
                              className={[
                                mobileStyles.MobileMenuListItem,
                                mobileStyles.MobileMenuListItemLanguage
                              ].join(' ')}
                              aria-hidden
                            >
                              <ul className={styles.LanguageSwitcherOptions}>{results}</ul>
                            </li>
                          ) : (
                            <div className={styles.LanguageSwitcher}>
                              <ul className={styles.LanguageSwitcherOptions}>{results}</ul>
                            </div>
                          )}
                        </>
                      )
                    );
                  }}
                </LoaderConsumer>
              )}
            </ApolloConsumer>
          )}
        </I18n>
      );
    }}
  </BackendConfigQuery>
));
