import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const GET_BANNERS = gql`
  query CtaBanners($path: String, $categoryId: String, $platform: String) {
    ctaBanners(path: $path, categoryId: $categoryId, platform: $platform) {
      items {
        id
        active
        clickable
        closeable
        name
        url
        content
        start_date
        end_date
        banner_url
      }
    }
  }
`;

export class CtaBannersQuery extends Query {
  static defaultProps = {
    query: GET_BANNERS
  };
}
