import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const GET_PRODCTLIST_BY_SKUS = gql`
  query GetProductsByIds($skus: [String]) {
    productListBySkus(skus: $skus) {
      items {
        id
        sku
        name
        productImage {
          retina
          default
          defaultWebp
          retinaWebp
        }
        urlPath
        volume
        popularity
        rating
        type
        reviewsSummary {
          totalCount
          averageRating
        }
        baseprice
        price {
          regular
          special
          minPrice
          maxPrice
          minTier
        }
        applicationType
        categoryPath
        brand
        hasCustomOptions
        cosmeticlineValue
        labels {
          text
          type
          percent
          isSale
          size
          rotated
          transparent
          badge
          images {
            image
          }
        }
        stock {
          isInStock
          qty
        }
      }
    }
  }
`;

export class ProductListBySkusQuery extends Query {
  static defaultProps = {
    query: GET_PRODCTLIST_BY_SKUS
  };
}
