import React from 'react';
import { I18n } from '@deity/falcon-i18n';
import { Helmet } from 'react-helmet-async';
import Container from '../../layout/Container/Container';
import { ProductInfoListing } from '../../elements/Product/ProductInfo/ProductInfo';
import { Loader } from '../../ui/Loader/Loader';
import { ProductSlider } from '../../elements/Product';
import { CmsPageQuery } from '../../elements/Cms/CmsQuery';
import loadable from '../../../../../components/loadable';
import { OdoscopeSlider } from '../../elements/Odoscope/Slider';
import { SuggestProductsQuery } from './SuggestProductsQuery';
import styles from './NotFound.module.scss';

const Row = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ '../../elements/Cms/Row'));

const REGEX = /[^a-zA-ZÀ-Ÿ]+/g;
const ALT_TERM = '';

const MAX_PRODUCTS = 2;
const MAX_RECOMMENDATIONS = 8;

const getRecommendations = products => {
  const productIds = products.map(({ id }) => id);
  return products.reduce((res, { recommendations }) => {
    if (res.length >= MAX_RECOMMENDATIONS || (!recommendations || !recommendations.items || !recommendations.items)) {
      return res;
    }
    recommendations.items.forEach(item => {
      if (!productIds.includes(item.id)) {
        res.push(item);
        productIds.push(item.id);
      }
    });
    return res.slice(0, MAX_RECOMMENDATIONS);
  }, []);
};

const NotFoundPage = ({ location }) => {
  const term = decodeURIComponent(location.pathname)
    .replace(/\.html?$/, '')
    .replace(REGEX, ' ')
    .trim();
  const cap = term.charAt(0).toUpperCase();
  const titleTerm = cap + term.slice(1);
  return (
    <I18n>
      {t => (
        <React.Fragment>
          <Helmet>
            <title>{t('notFound.title', { term: titleTerm, suffix: t('domain.storeShortCode') })}</title>
            <meta name="description" content={t('notFound.description', { term: titleTerm })} />
            <meta name="robots" content="noindex" />
          </Helmet>
          <Container>
            <SuggestProductsQuery
              variables={{
                term,
                altTerm: ALT_TERM,
                maxProducts: MAX_PRODUCTS
              }}
              passLoading
            >
              {({ data: { suggest }, loading }) => {
                if (loading) {
                  return <Loader />;
                }
                const { items = [] } = suggest.productList || {};
                const recommendations = getRecommendations(items);
                if (recommendations.length === 0) {
                  return (
                    <CmsPageQuery variables={{ id: '2KFO2AqgeWShnvHNHPjsdL', path: '/404' }}>
                      {({ data: { contentfulPage } }) => {
                        return (
                          <React.Fragment>
                            {contentfulPage?.contentData?.modules &&
                              contentfulPage.contentData.modules.map((item, index) => (
                                <Row
                                  key={`Row -'${index}'`} // eslint-disable-line
                                  row={index}
                                  item={item.fields}
                                  contentType={item.sys.contentType.sys.id}
                                />
                              ))}
                          </React.Fragment>
                        );
                      }}
                    </CmsPageQuery>
                  );
                }
                return (
                  <React.Fragment>
                    <div className={styles.NotFoundTitleInfo}>{t('notFound.titleInfo')}</div>
                    <ProductInfoListing products={items} />
                    <div className={styles.ProductSliderWrapper}>
                      <OdoscopeSlider title={t('notFound.odoscopeSliderTitle')} location="not_found_page" />
                    </div>
                    <div className={styles.ProductSliderWrapper}>
                      <ProductSlider
                        title={t('notFound.moreProductsTitle')}
                        products={recommendations}
                        autoHeight
                        location="not_found_page"
                        source="NotFoundPage"
                      />
                    </div>
                  </React.Fragment>
                );
              }}
            </SuggestProductsQuery>
          </Container>
        </React.Fragment>
      )}
    </I18n>
  );
};

export default NotFoundPage;
