import { useQuery } from '@deity/falcon-data';
import { GET_BACKEND_CONFIG } from '../../../../overrides/BackendConfig/BackendConfigQuery';
import { PageviewTracking } from './Pageview';

export const PageMetaTracking = ({ storeConfiguration, customer }) => {
  const { data: storeData } = useQuery(GET_BACKEND_CONFIG);
  const activeStore = storeData ? storeData?.backendConfig?.shop?.activeStore : 'asambeauty_eur_de';
  const storeInfo = storeData ? storeData?.backendConfig?.shop?.stores?.filter(item => item.code === activeStore) : [];

  const dataLayerData = {
    key: `page_meta_${customer?.id}`,
    event: 'Page Meta',
    user_id: customer ? customer.id : '', // e.g. '123456789'
    country: storeConfiguration?.general?.country?.default, // The country the user resides in
    language: storeInfo[0]?.name, // The language the store is set to
    environment:
      storeConfiguration?.asam_tracking?.googletagmanager?.live_environment === '1' ? 'production' : 'development',
    login_status: customer ? 'logged_in' : 'logged_out',
    newsletter_subscriber: customer?.newsletterSubscriber,
    shop_country: storeConfiguration?.general?.country?.default // e.g. 'de'
  };

  return PageviewTracking({
    storeConfiguration,
    dataLayerData,
    bufferSeconds: 7200
  });
};
