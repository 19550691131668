/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 * @returns {string}
 */
export const getMobileOperatingSystem = () => {
  const userAgent =
    typeof navigator !== 'undefined' &&
    typeof window !== 'undefined' &&
    (navigator.userAgent || navigator.vendor || window.opera);

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  if (/iPhone/.test(userAgent) && typeof window !== 'undefined' && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};
