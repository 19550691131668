import { PageviewTracking } from './Pageview';

export const LoginTracking = ({ storeConfiguration, failed = false, errorMessage, loginMethod = 'asambeauty' }) => {
  let eventName = 'login_success';
  let eventAction = 'login';
  let eventKey = 'login_success';
  let eventLabel = 'successful';

  if (failed) {
    eventName = 'login_failed';
    eventAction = 'login';
    eventKey = 'login_failed';
    eventLabel = 'failed';
  }

  const dataLayerData = {
    key: `login_failed_${failed}`,
    event: 'Generic Event',
    event_name: eventName
  };

  dataLayerData[eventKey] = {
    event_category: 'account',
    event_action: eventAction,
    event_label: eventLabel,
    login_method: loginMethod
  };

  if (failed) {
    dataLayerData[eventKey].error_message = errorMessage;
  }

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
