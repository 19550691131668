export const GoogleTagManagerUsercentrics = () => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    if (typeof window.UC_UI !== 'undefined' && window.UC_UI?.getServicesBaseInfo()) {
      const marketing = window.UC_UI?.getServicesBaseInfo()?.find(item => item.categorySlug === 'marketing');
      if (marketing?.consent?.status === true) {
        window.dataLayer.push({ event: 'usercentrics_marketing', usercentrics_marketing: true });
      }

      const functional = window.UC_UI?.getServicesBaseInfo()?.find(item => item.categorySlug === 'functional');
      if (functional?.consent?.status === true) {
        window.dataLayer.push({ event: 'usercentrics_functional', usercentrics_functional: true });
      }
    }
  }
  return null;
};
