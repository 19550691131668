import { PageviewTracking } from './Pageview';

export const WishlistTracking = ({ storeConfiguration, product, action = 'add', origin }) => {
  let eventName = 'add_to_wishlist';
  let eventAction = 'add product';
  let eventKey = 'add_to_wishlist';
  let eventLocation = 'add_to_wishlist_location';

  if (action === 'remove') {
    eventName = 'remove_from_wishlist';
    eventAction = 'remove product';
    eventKey = 'remove_from_wishlist';
    eventLocation = 'remove_from_wishlist_location';
  }

  const productPrice = product.price.special
    ? product.price.special
    : product.price.regular || product.finalPrice || product.priceInclTax || product.price;

  const dataLayerData = {
    key: `wishlist_${product.sku}_${origin}_${action}`,
    event: 'Generic Event',
    event_name: eventName
  };

  dataLayerData[eventKey] = {
    product_id: product.sku,
    price: productPrice,

    event_category: 'wishlist',
    event_action: eventAction,
    event_label: product.sku
  };
  dataLayerData[eventKey][eventLocation] = origin;

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
