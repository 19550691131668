import Cookies from 'js-cookie';
import { PageviewTracking } from './Pageview';

export const ReferrerTracking = ({ storeConfiguration }) => {
  if (typeof window !== 'undefined') {
    if (parseInt(storeConfiguration?.asam_tracking?.googletagmanager?.active, 10) && window.dataLayer) {
      const allowedParameters = ['utm_source', 'utm_medium', 'utm_term', 'utm_content', 'utm_campaign'];

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      allowedParameters.forEach(allowedParameter => {
        const parameter = urlParams.get(allowedParameter);
        if (parameter) {
          Cookies.set(`asam_${allowedParameter}`, parameter);
        }
      });
    }

    const dataLayerData = {
      event: 'referrer_fix',
      key: `original_location`,
      timestamp: 0,
      originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`
    };

    return PageviewTracking({ storeConfiguration, dataLayerData });
  }
  return null;
};
