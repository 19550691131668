export const supportWebP = () => {
  if (typeof window !== 'undefined') {
    const elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) { // eslint-disable-line
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    return false;
  }
  return true;
};
