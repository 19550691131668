import React from 'react';
import PropTypes from 'prop-types';
import { getStoreViewByCode, supportedStoreCodes } from '../../../../../storesConfig';
import { useStoreContext } from './StoreProvider';

export const Store = ({ children }) => {
  const StoreContext = useStoreContext();

  return children({
    ...StoreContext
  });
};

export const UseStore = ({ storeCode, children }) => {
  const StoreContext = useStoreContext();
  return storeCode === StoreContext.storeCode ? children({ ...StoreContext }) : null;
};

UseStore.propTypes = {
  storeCode: PropTypes.string.isRequired
};

export const UseStores = ({ storeCodes, children }) => {
  const StoreContext = useStoreContext();
  return storeCodes.includes(StoreContext.storeCode) ? children({ ...StoreContext }) : null;
};

UseStores.propTypes = {
  storeCodes: PropTypes.array.isRequired
};

export const StoreIteration = ({ storeCodes, children }) => {
  const StoreContext = useStoreContext();
  const storeCodeIterations = storeCodes?.length ? storeCodes : supportedStoreCodes;
  return (
    <>
      {storeCodeIterations.map(storeCode => {
        const storeView = getStoreViewByCode(storeCode);
        return storeView ? children({ storeView, ...StoreContext }) : null;
      })}
    </>
  );
};

StoreIteration.propTypes = {
  storeCodes: PropTypes.array
};

export { storeCodeMap } from '../../../../../storesConfig';
