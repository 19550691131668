import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown as DropList, DropdownLabel, DropdownMenu, DropdownMenuItem } from '@deity/falcon-ui';
import { Input } from '../Input/Input';
import { DropdownList } from './DropdownList';
import styles from './Dropdown.module.scss';

const getSelectedLabel = (options, selectedOption) => {
  let selectedLabel = null;

  options.forEach(item => {
    if (item.value === selectedOption) {
      selectedLabel = item.label;
    }
  });

  return selectedLabel;
};

export const Dropdown = ({
  options,
  invalid,
  preSelected,
  title,
  onChange,
  size,
  listFullWidth,
  boldLabel,
  separator,
  variant,
  withMargin,
  type,
  colorType,
  firstOptionAsLabel,
  narrowerMobile,
  disabled,
  inspect,
  highlightBestsellerInterval
}) => {
  const scrollbars = useRef(null);
  const dropdownRef = useRef(null);

  if (!preSelected && options[0]) {
    preSelected = options[0].value;
  }

  const additionalClasses = [];
  const [selectedOption, setSelectedOption] = useState(preSelected);

  useEffect(() => {
    setSelectedOption(preSelected);
  }, [preSelected]);

  if (size === 'tiny') {
    additionalClasses.push(styles.DropdownTiny);
  }

  if (size === 'small') {
    additionalClasses.push(styles.DropdownSmall);
  }

  if (size === 'large') {
    additionalClasses.push(styles.DropdownLarge);
  }

  if (type === 'secondary') {
    additionalClasses.push(styles.DropdownSecondary);
  }

  if (type === 'tertiary') {
    additionalClasses.push(styles.DropdownMenuTertiary);
    additionalClasses.push(styles.DropdownMenuItemTertiary);
    additionalClasses.push(styles.DropdownMenuItemSelectedIconTertiary);
  }

  if (colorType === 'secondary') {
    additionalClasses.push(styles.DropdownColorSecondary);
  }

  if (listFullWidth) {
    additionalClasses.push(styles.DropdownListFullWidth);
  }

  if (boldLabel) {
    additionalClasses.push(styles.DropdownBoldLabel);
  }

  if (separator) {
    additionalClasses.push(styles.DropdownSeparator);
  }

  if (variant === 'number') {
    additionalClasses.push(styles.DropdownNumber);
  }

  if (invalid) {
    additionalClasses.push(styles.DropdownInvalid);
  }

  if (withMargin) {
    additionalClasses.push(styles.DropdownWithMargin);
  }

  if (narrowerMobile) {
    additionalClasses.push(styles.DropdownNarrowerMobile);
  }

  if (highlightBestsellerInterval) {
    additionalClasses.push(styles.DropDownWithExtraLabel);
  }

  const handleOnChange = value => {
    setSelectedOption(value);
    dropdownRef.current.classList.remove('dropdownDragEnd');
    if (onChange !== null) {
      onChange(value);
    }
  };

  const handleScrollToSelected = () => {
    if (scrollbars.current) {
      const selected = options.findIndex(item => item.value === selectedOption);
      scrollbars.current.scrollTop(selected * 32);
    }
  };

  if (disabled) {
    return <Input label={title} disabled={disabled || false} value={getSelectedLabel(options, preSelected)} />;
  }

  return (
    <div ref={dropdownRef}>
      <DropList
        dropdownRef={dropdownRef}
        onChange={handleOnChange}
        className={[styles.Dropdown, styles.Select, ...additionalClasses].join(' ')}
        data-inspect={inspect}
      >
        {title ? <div className={styles.DropdownTitle}>{title}</div> : null}
        <DropdownLabel
          className={[
            styles.DropdownLabel,
            firstOptionAsLabel && selectedOption === null ? styles.DropdownFirstAsLabel : null
          ].join(' ')}
        >
          {getSelectedLabel(options, selectedOption)}
        </DropdownLabel>
        <DropdownMenu className={[styles.DropdownMenu, ...additionalClasses].join(' ')}>
          <DropdownList scrollTo={handleScrollToSelected}>
            {options.map((item, index) => {
              let selectedClass = null;

              if (selectedOption === item.value) {
                selectedClass = styles.DropdownMenuItemSelected;
              }

              return firstOptionAsLabel && index === 0 ? null : (
                <DropdownMenuItem
                  key={item.value}
                  value={item.value}
                  className={[styles.DropdownMenuItem, selectedClass, ...additionalClasses].join(' ')}
                >
                  {item.label}
                  {item?.bestSellerLabel ? (
                    <span className={styles.DropDownMenuBestSellerItem}>{item?.bestSellerLabel}</span>
                  ) : null}
                </DropdownMenuItem>
              );
            })}
          </DropdownList>
        </DropdownMenu>
      </DropList>
    </div>
  );
};

Dropdown.defaultProps = {
  variant: 'default',
  colorType: 'default'
};

Dropdown.propTypes = {
  /** Change size of dropdown element */
  size: PropTypes.oneOf(['tiny', 'small', 'large']),
  /** Variant of dropdown */
  variant: PropTypes.oneOf(['default', 'number']),
  /** Type of dropdown -- background color */
  type: PropTypes.oneOf(['secondary', 'tertiary']),
  /** If true, dropdown list will have 100% width of dropdown, otherwise dropdown list will be little bigger  */
  listFullWidth: PropTypes.bool,
  /** Array of object that indicates available options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  /** Pass option value that should be preselected */
  preSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Function that will be called when user change option from dropdown */
  onChange: PropTypes.func,
  /** Color variant for dropdown */
  colorType: PropTypes.oneOf(['default', 'secondary']),
  /** Narrower padding on mobile */
  narrowerMobile: PropTypes.bool
};
