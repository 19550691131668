import { PageviewTracking } from './Pageview';

export const PageViewActiveItemTracking = ({ storeConfiguration, product, bufferSeconds = 30 }) => {
  const customAttributes = product.customAttributes ? JSON.parse(product.customAttributes) : {};

  const dataLayerData = {
    page_type: 'product',
    page_title: product?.contentData?.meta_title,
    currency: storeConfiguration?.currency?.options?.default,
    product: {
      name: product.name, // e.g. 'Lipstick red ...'
      brand: `${product.brand} - ${customAttributes?.cosmeticlineValue}`, // e.g. 'M. Asam - Magic Finish'
      category: product.categoryPath, // e.g. 'M. Asam/Geschenke/Limitierte Editionen'
      price: product.price?.special || product.price?.regular, // e.g. '24.75'
      sku: product.sku, // e.g. '41250'
      imageURL: product?.gallery[0]?.full
    }
  };

  return PageviewTracking({ storeConfiguration, dataLayerData, bufferSeconds });
};
