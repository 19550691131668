import React, { useState } from 'react';
import { LazyLoad } from '../../../../helpers/LazyLoad/LazyLoad';
import withStoreConfiguration from '../../../../helpers/StoreConfiguration/StoreConfiguration';
import { VideoTracking } from '../../../Tracking/GoogleTagManager/GA4/Video';
import { VideoPlayer } from '../../../VideoPlayer/VideoPlayer';
import styles from './CmsVideo.module.scss';

export const CmsCfVideo = withStoreConfiguration(
  ({
    storeConfiguration,
    videoUrl,
    videoThumb,
    videoTitle,
    isAutoplayActive = false,
    isGallery = false,
    clickHandler
  }) => {
    let currentProgressIndex = 0;
    const [isPlaying, setIsPlaying] = useState(false);

    const loadVideo = () => {
      const videoElm = document.getElementById(`CmsCfVideo-${videoUrl}`);
      videoElm.play();
      setIsPlaying(true);
    };

    const handleVideoEvent = event => {
      const currentIndexIncrement = (setToEnd = false) => {
        if (setToEnd) {
          currentProgressIndex = undefined;
        } else {
          currentProgressIndex++;
        }
      };

      VideoTracking({
        storeConfiguration,
        event,
        currentProgressIndex,
        currentIndexIncrement,
        isAutoplayActive,
        videoUrl,
        videoTitle
      });
    };

    const videoHolder = (
      <VideoPlayer
        src={videoUrl}
        videoThumb={videoThumb}
        isGallery={isGallery}
        title={videoTitle}
        videoUrl={videoUrl}
        isAutoplayActive={isAutoplayActive}
        clickHandler={clickHandler || loadVideo}
        isPlaying={isPlaying}
        handleVideoEvent={handleVideoEvent}
      />
    );

    return (
      videoUrl &&
      (isGallery ? (
        videoHolder
      ) : (
        <LazyLoad>
          <div className={styles.VideoHolder}>{videoHolder}</div>
        </LazyLoad>
      ))
    );
  }
);
