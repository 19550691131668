import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Overlay.module.scss';

export const Overlay = ({
  overlayHidden,
  overlaySide,
  overlayWidth,
  overlayYPosition,
  overlayXPosition,
  overlayArrowXPosition,
  overlayDropdownContent,
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const additionalClasses = [];
  const additionalContentClasses = [];
  let overlayXAlign = { left: overlayXPosition };

  if (isOpen) {
    additionalClasses.push(styles.OverlayVisible);
  }

  if (overlaySide === 'right') {
    additionalContentClasses.push(styles.OverlayRight);
    overlayXAlign = { right: overlayXPosition };
  }

  useEffect(() => {
    if (overlayHidden) {
      setIsOpen(false);
    }
  }, []);

  const overlayDropdown = !overlayHidden ? (
    <div
      className={[styles.Overlay, ...additionalClasses].join(' ')}
      style={{ ...overlayXAlign, top: overlayYPosition, width: overlayWidth }}
    >
      <div className={styles.OverlayArrow} style={{ left: overlayArrowXPosition }}>
        <div className={styles.OverlayArrowBg} />
      </div>
      <div className={[styles.OverlayContent, ...additionalContentClasses].join(' ')}>
        <div className={['OverlayPreventBar', styles.OverlayPreventBar].join(' ')} />
        {overlayDropdownContent({ setIsOpen })}
      </div>
    </div>
  ) : null;

  return children({ overlayDropdown, setIsOpen });
};

Overlay.defaultProps = {
  overlayYPosition: '0',
  overlayXPosition: '0',
  overlayArrowXPosition: '20px',
  overlayHidden: false
};

Overlay.propTypes = {
  /** Content that will be displayed in overlay */
  overlayDropdownContent: PropTypes.func.isRequired,
  /** Overlay width */
  overlayWidth: PropTypes.string,
  /** Overlay position from left */
  overlayYPosition: PropTypes.string,
  /** Overlay position from top */
  overlayXPosition: PropTypes.string,
  /** Overlay arrow position from left */
  overlayArrowXPosition: PropTypes.string,
  overlaySide: PropTypes.oneOf(['right', 'left']).isRequired
};
