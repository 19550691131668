import { T } from '@deity/falcon-i18n';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../ui/Button/Button';
import { CartQuery } from '../../../overrides/Cart/CartQuery';
import { LeftForFreeshipping } from '../../LeftForFreeshipping/LeftForFreeshipping';
import { CheckoutTracking } from '../../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import loadable from '../../../../../../components/loadable';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../../ui/Modal/Modal'));

const getPopupContent = withStoreConfiguration(({ storeConfiguration }) => (
  <CartQuery>
    {({ data: { cart } }) => (
      <div>
        <div className="title">
          <T id="product.addToCartTitlePopup" />
        </div>
        <LeftForFreeshipping amount={cart.leftForFreeshipping} />
        <br />
        <Button asLink path="/checkout" onClick={() => CheckoutTracking({ storeConfiguration, cart, step: 2 })}>
          <T id="product.addToCartButtonPopup" />
        </Button>
        <Link to="/cart" className="Link LinkPurple LinkLarge">
          <T id="product.toCartLinkPopup" />
        </Link>
      </div>
    )}
  </CartQuery>
));

export const AddedToCartPopup = ({ on, toggle }) => {
  return <Modal variant="secondary" content={getPopupContent()} close={toggle} visible={on} autohide />;
};
