import React from 'react';
import { I18n } from '@deity/falcon-i18n';
import PropTypes from 'prop-types';
import styles from './ReviewsSummary.module.scss';

const MAX_REVIEW_STARS = 5;

export const ReviewsSummary = ({ summary, onlyStars, scrollToForm, starGray }) => (
  <I18n>
    {t => {
      const reviewSummaryMessage = (
        <span className={styles.ReviewsSummaryMessage}>
          {summary.totalCount > 0 ? (
            t('reviews.productReviews', { count: summary.totalCount })
          ) : (
            <React.Fragment>
              {scrollToForm ? (
                <span className="Link LinkLarge LinkPurple LinkUnderline">{t('reviews.addProductReview')}</span>
              ) : (
                t('reviews.productReviews', { count: 0 })
              )}
            </React.Fragment>
          )}
        </span>
      );

      const rateAsPercent = summary.averageRating ? (100 * summary.averageRating) / MAX_REVIEW_STARS : 0;

      const reviewSummaryContent = (
        <React.Fragment>
          <span className={[styles.ReviewsStarsHtml, starGray ? styles.starGray : ''].join(' ')}>
            <span className={styles.ReviewsStarsPercent} style={{ width: `${rateAsPercent}%` }} />
          </span>
          {!onlyStars ? reviewSummaryMessage : null}
        </React.Fragment>
      );

      return (
        <div className={styles.ReviewsSummary}>
          {scrollToForm ? (
            <a
              href="#reviews"
              onClick={event => {
                event.preventDefault();
                document.getElementById('reviews')?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              {reviewSummaryContent}
            </a>
          ) : (
            reviewSummaryContent
          )}
        </div>
      );
    }}
  </I18n>
);

ReviewsSummary.propTypes = {
  /** Object contains review summary data */
  summary: PropTypes.shape({
    totalCount: PropTypes.number,
    averageRating: PropTypes.number
  }).isRequired,
  /** If true it will display only stars element */
  onlyStars: PropTypes.bool
};
