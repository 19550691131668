import { AddToCartFormProvider } from '@deity/falcon-front-kit';
import { I18n } from '@deity/falcon-i18n';
import { Form } from '@deity/falcon-ui-kit';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { forceCheck } from 'react-lazyload';
import { Link } from 'react-router-dom';
import { LazyLoad } from '../../../helpers/LazyLoad/LazyLoad';
import productPlaceholder from '../../../../../../assets/images/product/placeholder.png';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { Button } from '../../../ui/Button/Button';
import { Badge } from '../../Badge';
import { ReviewsSummary } from '../../Reviews/ReviewsSummary/ReviewsSummary';
import { AddToWishlist } from '../../Wishlist/AddToWishlist/AddToWishlist';
import { ProductImageAlternative } from '../ProductImage/ProductImage';
import { CartTracking, SelectItemTracking } from '../../Tracking/GoogleTagManager';
import { Variants, VariantContext } from '../Variants/Variants';
import { ProductLabels } from '../ProductLabels/ProductLabels';
import { LastOrderUpdateTracking } from '../../LastOrderUpdateTracking/LastOrderUpdateTracking';
import styles from './ProductCard.module.scss';
import { ProductCardPrice } from './ProductCardPrice';

export const ProductCard = withStoreConfiguration(
  ({
    storeConfiguration,
    product,
    area,
    location,
    category,
    currentIndex,
    showAddedToWishlist,
    showAddedToCart,
    showAddedToCartError,
    onClick,
    source,
    isSlider
  }) => {
    const [isCartUpdated, setIsCartUpdated] = useState(false);
    const shopCountry = storeConfiguration?.general?.country?.default;

    // SoldOut enabled in Magento for Black Friday
    const soldOutActive = storeConfiguration?.asam_productlabel.sold_out?.enabled === '1';
    const soldOutText = storeConfiguration?.asam_productlabel.sold_out?.banner_text;

    const additionalClasses = [];
    const isProductListing = area === 'productListing';
    const isCoupon = product?.sku === 'GUTSCHEIN';

    if (isProductListing) {
      additionalClasses.push(styles.ProductListing);
    }

    if (currentIndex % 3 === 1) {
      additionalClasses.push(styles.ProductCardDesktop);
    }

    if (currentIndex % 4 === 1) {
      additionalClasses.push(styles.ProductCardTablet);
      additionalClasses.push(styles.ProductCardTabletFirst);
    }

    if (currentIndex % 4 === 2) {
      additionalClasses.push(styles.ProductCardTablet);
      additionalClasses.push(styles.ProductCardTabletSecond);
    }

    const isProductOutOfStock = !product.stock?.isInStock && !product.isInStock;
    if (isProductOutOfStock) {
      additionalClasses.push(styles.ProductCardOutOfStock);
    }

    if (currentIndex < 5) {
      forceCheck();
    }

    const productRating = product?.reviewsSummary?.averageRating || (5 * product.rating) / 100;

    return (
      <I18n>
        {t => (
          <div className={[styles.ProductCard, ...additionalClasses].join(' ')} key={product.id}>
            {isProductListing &&
            product.hasCustomOptions === false &&
            product.price.regular > 0 &&
            product.type !== 'configurable' ? (
              <span role="button" tabIndex={0}>
                <AddToWishlist
                  productId={product.id}
                  onSuccess={showAddedToWishlist}
                  origin="product card"
                  product={product}
                />
              </span>
            ) : null}

            <div className={styles.ProductCardInner} data-inspect={product.id ? `product-item-${product.id}` : null}>
              <div>
                <div
                  className={styles.ProductCardImage}
                  data-soldout={soldOutActive ? soldOutText : t('product.soldOut')}
                >
                  <div className={styles.ProductCardLabels}>
                    <ProductLabels labels={product.labels} product={product} config="category" />
                  </div>
                  <LazyLoad>
                    <ProductImageAlternative
                      product={product}
                      alternativeImage={product.thumbnail || productPlaceholder}
                      width={160}
                      height={160}
                    />
                  </LazyLoad>
                </div>
                <div className={styles.ProductCardBadge}>
                  {product.labels
                    ? product.labels
                        .filter(item => item.badge === true)
                        .slice(0, 1)
                        .map((item, index) => {
                          // eslint-disable-next-line react/no-array-index-key
                          return <Badge text={item.text} type={item.type} key={`badge-${index}`} />;
                        })
                    : null}
                </div>
                <div className={styles.ProductCardProducer}>{product.brand}</div>
                <div className={styles.ProductCardName}>{product.shortName ? product.shortName : product.name}</div>
                {/* TODO: check for variants */}
                {product?.options?.length ? <Variants options={product.options} context={VariantContext.plp} /> : null}
                <div className={styles.ProductCardVolume}>
                  <span className="Volume__Amount">{product.volume}</span>
                  <span>
                    {t('product.sku')} {product.sku}
                  </span>
                </div>
                {product.applicationType ? (
                  <div className={styles.ProductCardApplicationType}>
                    {product.applicationType.lastIndex >= 1 ? (
                      <React.Fragment>
                        {product.applicationType.map((type, index) => {
                          // eslint-disable-next-line react/no-array-index-key
                          return <span key={`type-${index}`}>{type}</span>;
                        })}
                      </React.Fragment>
                    ) : (
                      <span>{product.applicationType}</span>
                    )}
                  </div>
                ) : null}
                {isProductListing && product.shortDescription ? (
                  <div className={styles.ProductCardDescription}>{product.shortDescription}</div>
                ) : null}
                <div className={styles.ProductCardStars}>
                  <ReviewsSummary onlyStars summary={{ averageRating: productRating }} />
                </div>
              </div>

              {!isCoupon ? <ProductCardPrice product={product} t={t} storeConfiguration={storeConfiguration} /> : null}

              <Link
                to={product.urlPath ? product.urlPath : '/'}
                className="whole-holder"
                onClick={() => {
                  if (typeof onClick === 'function') {
                    onClick();
                  }
                  if (!isSlider) SelectItemTracking({ storeConfiguration, product, category });
                  else
                    SelectItemTracking({
                      storeConfiguration,
                      product,
                      category,
                      area,
                      location,
                      shopCountry,
                      currentIndex
                    });
                }}
              >
                {product.shortName ? product.shortName : product.name}
              </Link>
              {product.price.regular > 0 && product.type !== 'configurable' && !product?.subscriptionOffer?.enabled ? (
                <AddToCartFormProvider
                  quantity={1}
                  product={product}
                  onSuccess={() => {
                    showAddedToCart();
                    CartTracking({
                      storeConfiguration,
                      product,
                      action: 'add',
                      origin: 'product card',
                      quantity: 1
                    });
                    setIsCartUpdated(true);
                  }}
                  onError={error => showAddedToCartError(error)}
                >
                  {({ isSubmitting }) => (
                    <Form id={`productForm${product.sku}_${source}`} i18nId="product">
                      <div className={styles.ProductCardAddToCart}>
                        {product.isInStock !== false ? (
                          <Button
                            iconSrc="basket-plus"
                            type="submit"
                            loading={isSubmitting}
                            dataInspect="add-to-cart-item"
                          />
                        ) : (
                          <Button iconSrc="basket-plus" types="ghost" disabled />
                        )}
                      </div>
                    </Form>
                  )}
                </AddToCartFormProvider>
              ) : (
                <div className={styles.ProductCardAddToCart}>
                  <Button iconSrc="chevron-right" asLink to={product.urlPath ? product.urlPath : '/'} />
                </div>
              )}
              {isCartUpdated && <LastOrderUpdateTracking product={product} />}
            </div>
          </div>
        )}
      </I18n>
    );
  }
);

ProductCard.propTypes = {
  /** Define area where product card will be shown */
  area: PropTypes.string,
  /** Function called after add to wishlist click */
  showAddedToWishlist: PropTypes.func,
  /** Function called after add to cart click */
  showAddedToCart: PropTypes.func
};
