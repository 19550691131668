import React from 'react';
import styles from '../Gallery.module.scss';
import additionalStyles from '../../../Cms/Modules/Components/CmsVideo.module.scss';
import { YouTubeNoCookie } from '../../../YouTubeNoCookie/YouTubeNoCookie';
import useYouTubePlaceholder from '../../../../hooks/YouTubePlaceholder';
import { CmsCfVideo } from '../../../Cms/Modules/Components/CmsCfVideo';

const TYPE_YOUTUBE = 'youtube';
const YOUTUBE_EXPLODE = 'watch?v=';
const TYPE_CONTENTFUL = 'videos.ctfassets';

export const Video = ({ url, videoTitle, isGallery }) => {
  if (url.indexOf(TYPE_YOUTUBE) >= 0) {
    const { loadPlaceholder } = useYouTubePlaceholder();
    const youtubeUrlSplit = url.split(YOUTUBE_EXPLODE);
    let youtubeId = youtubeUrlSplit[youtubeUrlSplit.length - 1];
    if (youtubeId.includes('&')) {
      youtubeId = youtubeId.substr(0, youtubeId.indexOf('&'));
    }

    return (
      <div
        className={[
          styles.GalleryVideo,
          additionalStyles.VideoHeight,
          loadPlaceholder ? additionalStyles.VideoHeightYouTube : ''
        ].join(' ')}
      >
        <YouTubeNoCookie videoId={youtubeId} autoPlay className={additionalStyles.CmsVideo} />
      </div>
    );
  }

  if (url.indexOf(TYPE_CONTENTFUL) >= 0) {
    return (
      <div className={styles.GalleryVideo}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <CmsCfVideo videoUrl={url} videoTitle={videoTitle} isAutoplayActive isGallery={isGallery} />
      </div>
    );
  }

  return null;
};
