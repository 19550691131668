import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { T } from '@deity/falcon-i18n';
import { CartQuery } from '../../overrides/Cart/CartQuery';
import { Overlay } from '../../ui/Overlay/Overlay';
import { BREAKPOINTS } from '../../helpers/CheckBreakpoint/CheckBreakpoint';
import { AddedToWishlistPopup } from '../Wishlist/AddedToWishlistPopup/AddedToWishlistPopup';
import { NavigationShopTracking } from '../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { MiniCart } from './MiniCart';
import styles from './MiniCartIcon.module.scss';

export const MiniCartIcon = withStoreConfiguration(({ storeConfiguration, itemsQty }) => {
  const [showAddedToWishlist, setShowAddedToWishlist] = useState(false);

  return (
    <CartQuery passLoading>
      {({ data }) => (
        <React.Fragment>
          <Overlay
            overlayHidden={!(data.cart && data.cart.items.length)}
            overlaySide="right"
            overlayWidth="600px"
            overlayYPosition="58px"
            overlayArrowXPosition="560px"
            overlayDropdownContent={({ setIsOpen }) => (
              <MiniCart {...data} setIsOpen={setIsOpen} onAddedToWishlist={() => setShowAddedToWishlist(true)} />
            )}
          >
            {({ overlayDropdown, setIsOpen }) => (
              <div
                data-inspect="mini-cart"
                className={styles.MiniCartIconWrapper}
                onMouseEnter={
                  itemsQty > 0
                    ? e => {
                        return e.view.innerWidth > BREAKPOINTS.tablet ? setIsOpen(true) : null;
                      }
                    : null
                }
                onMouseLeave={
                  itemsQty > 0
                    ? e => {
                        return e.view.innerWidth > BREAKPOINTS.tablet ? setIsOpen(false) : null;
                      }
                    : null
                }
              >
                <div className={styles.MiniCartIcon}>
                  <Link
                    to="/cart"
                    data-inspect="MiniCart"
                    onClick={() => {
                      NavigationShopTracking({ storeConfiguration, label: 'mini_cart' });
                    }}
                  >
                    <span className={styles.MiniCartIndicator}>{itemsQty}</span>
                    <span className={styles.MiniCartLabel}>
                      <T id="header.minicart" />
                    </span>
                  </Link>
                </div>
                {overlayDropdown}
              </div>
            )}
          </Overlay>
          {showAddedToWishlist ? (
            <AddedToWishlistPopup on={showAddedToWishlist} toggle={() => setShowAddedToWishlist(false)} />
          ) : null}
        </React.Fragment>
      )}
    </CartQuery>
  );
});
