import { Image } from '@deity/falcon-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from '@deity/falcon-i18n';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { Price } from '../Product';
import styles from './Labels.module.scss';

export const Labels = withStoreConfiguration(
  ({
    type,
    position,
    percent,
    cms,
    text,
    price,
    specialPrice,
    pricePerUnit,
    size,
    rotated,
    transparent,
    product,
    image,
    storeConfiguration
  }) => {
    const storeCode = storeConfiguration?.general?.country?.default;
    const additionalClasses = [];
    const additionalContentTextClasses = [];
    const additionalCurrencyClasses = [];
    let upToPercentText = null;
    let fromPriceText = null;

    // TODO: remove as soon as android solved the label issue on their side
    if (text?.trim() === '') {
      text = false;
    }

    return (
      <I18n>
        {t => {
          /*
          check if it is a configurable product and has a maxPrice/minPrice.
          In case max/min prices are different, show additional labels
          if the regular price is filled for configurables, all subproducts have the same regular price
          */
          if (
            product &&
            product.price &&
            typeof product.price.minPrice !== 'undefined' &&
            product.price.minPrice !== null &&
            typeof product.price.maxPrice !== 'undefined' &&
            product.price.maxPrice !== null
          ) {
            if (product.price.minPrice !== product.price.maxPrice) {
              fromPriceText = typeof price !== 'undefined' ? t('product.fromText') : null;
              upToPercentText = percent ? t('product.upToText') : null;
            }

            if (typeof price !== 'undefined') {
              if (product.price.minPrice !== product.price.regular && product.price.regular > 0) {
                specialPrice = product.price.minPrice;
                price = product.price.regular;
              } else {
                price = product.price.minPrice;
              }
            }
          }

          if (storeCode === 'CH') {
            additionalCurrencyClasses.push(styles.LabelContentPricesChf);
          }

          if (typeof price !== 'undefined' || specialPrice) {
            additionalClasses.push(styles.LabelPrice);
          }

          if (text) {
            additionalClasses.push(styles.LabelText);

            if (!percent && text.length <= 4) {
              additionalContentTextClasses.push(styles.LabelEnlargeContentText);
            }
          }

          if (percent) {
            additionalClasses.push(styles.LabelPercent);
          }

          if (size === 'large' && !pricePerUnit) {
            additionalClasses.push(styles.LabelLarge);
          }

          if (rotated) {
            additionalClasses.push(styles.LabelRotated);
          }

          if (transparent) {
            additionalClasses.push(styles.LabelTransparent);
          }

          if (pricePerUnit) {
            additionalClasses.push(styles.LabelPricePerUnit);
          }

          switch (position) {
            case 'top_left':
              additionalClasses.push(styles.LabelTopLeft);
              break;
            case 'top_right':
              additionalClasses.push(styles.LabelTopRight);
              break;
            case 'bottom_left':
              additionalClasses.push(styles.LabelBottomLeft);
              break;
            case 'bottom_right':
              additionalClasses.push(styles.LabelBottomRight);
              break;
            case 'relative':
              additionalClasses.push(styles.LabelRelative);
              break;
            default:
              additionalClasses.push(styles.LabelRelative);
          }

          switch (type) {
            case 'orange':
              additionalClasses.push(styles.LabelOrange);
              break;
            case 'purple':
              additionalClasses.push(styles.LabelPurple);
              break;
            case 'green':
              additionalClasses.push(styles.LabelGreen);
              break;
            case 'skin':
              additionalClasses.push(styles.LabelSkin);
              break;
            case 'black':
              additionalClasses.push(styles.LabelBlack);
              break;
            case 'dark green':
              additionalClasses.push(styles.LabelDarkGreen);
              break;
            case 'none':
              additionalClasses.push(styles.LabelNone);
              break;
            default:
              additionalClasses.push(styles.LabelGrey);
          }

          return (
            <div className={[styles.Label, ...additionalClasses, 'Label'].join(' ')}>
              <div className={styles.LabelContent}>
                {cms ? (
                  <>
                    {fromPriceText ? <span className={styles.fromText}>{fromPriceText}</span> : null}
                    {upToPercentText ? <span className={styles.upToText}>{upToPercentText}</span> : null}
                    {typeof price !== 'undefined' ? (
                      <div
                        className={[
                          styles.LabelContentPrices,
                          specialPrice ? styles.LabelContentPricesSpecial : null,
                          ...additionalCurrencyClasses
                        ].join(' ')}
                      >
                        <Price value={price} specialPrice={specialPrice} />
                        {pricePerUnit ? <span className={styles.LabelPricePerUnitValue}>({pricePerUnit})</span> : null}
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    {text && percent ? <span className={styles.upToText}>{text}</span> : null}
                    {percent ? `-${percent}%` : null}
                    {text && !percent ? (
                      <div className={[styles.LabelContentText, ...additionalContentTextClasses].join(' ')}>{text}</div>
                    ) : null}
                    {image ? <Image src={image} className={styles.LabelImage} /> : null}
                  </>
                )}
              </div>
            </div>
          );
        }}
      </I18n>
    );
  }
);

Labels.propDefaults = {
  size: 'normal'
};

Labels.propTypes = {
  /** Set position of label (4 options available) */
  position: PropTypes.oneOf(['top_left', 'top_right', 'bottom_left', 'bottom_right', 'relative']),
  /** Set type of label */
  type: PropTypes.oneOf(['grey', 'orange', 'purple', 'green', 'skin', 'none']),
  /** Percent discount of product that will be visible inside label */
  percent: PropTypes.number,
  /** Product is in sale category */
  isSale: PropTypes.bool,
  /** Product price without any discount that will be visible inside label */
  price: PropTypes.number,
  /** Product special price that will be visible inside label */
  specialPrice: PropTypes.number,
  /** Product price per unit that will be visible inside label */
  pricePerUnit: PropTypes.string,
  /** Text that will be visible inside label */
  text: PropTypes.string,
  /** Set size of label */
  size: PropTypes.oneOf(['normal', 'large']),
  /** Set rotation of label */
  rotated: PropTypes.bool,
  /** Set opacity of label */
  transparent: PropTypes.bool,
  /** Display image instead of label */
  image: PropTypes.string
};
