import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_CMS_PAGE = gql`
  query CmsPageContent($id: String!, $path: String) {
    contentfulPage(id: $id, path: $path) {
      id
      createdAt
      updatedAt
      locale
      previewMode
      storeUrls {
        storeCode
        url
      }
      contentData
    }
  }
`;

export class CmsPageQuery extends Query {
  static defaultProps = {
    query: GET_CMS_PAGE,
    passLoading: true
  };
}

export const useCmsPageContentQuery = (options = {}) => useQuery(GET_CMS_PAGE, { ...options });
