import { PageviewTracking } from '../Pageview';

export const NewsletterCancellationTracking = ({ storeConfiguration }) => {
  const dataLayerData = {
    key: `newsletter_cancellation`,
    event: 'Generic Event',
    event_name: 'newsletter_cancellation',
    newsletter_cancellation: {
      event_category: 'newsletter',
      event_action: 'cancellation'
    }
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
