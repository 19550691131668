import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useGlobalStateValue } from '../helpers/GlobalState/GlobalState';

const useYouTubePlaceholder = () => {
  const [loadPlaceholder, setLoadPlaceholder] = useState(false);
  const [{ youtubeCookie }] = useGlobalStateValue();
  const cookieConsent = Cookies.get('CookieConsent');

  useEffect(() => {
    if (
      typeof Cookies.get('youtubePlaceholder') !== 'undefined' ||
      (!!cookieConsent?.includes('essential:true') &&
        !!cookieConsent?.includes('functional:true') &&
        !!cookieConsent?.includes('marketing:true'))
    ) {
      setLoadPlaceholder(true);
    }
  }, [youtubeCookie, cookieConsent]);

  return { loadPlaceholder, setLoadPlaceholder };
};

export default useYouTubePlaceholder;
