import { Link } from 'react-router-dom';
import { I18n } from '@deity/falcon-i18n';
import React from 'react';
import { isString, isArray, flatten } from 'lodash';

const PARSERS = [];

const getNextParser = text => {
  const next = { pos: text.length };
  PARSERS.forEach(parser => {
    const textArray = text.split(parser.regexp, 2);
    if (textArray.length === 2 && textArray[0].length < next.pos) {
      next.pos = textArray[0].length;
      next.parser = parser;
    }
  });

  return next.parser;
};

const parse = text => {
  const parser = getNextParser(text);
  if (parser) {
    const [first, second] = text.split(parser.regexp);
    return [first, parser.func(second), parse(text.slice(first.length + second.length))];
  }
  return [text];
};

/**
 * Bold parser
 *
 * **some text** -> <b>some text</b>
 */
PARSERS.push({
  regexp: /(\*\*.*?\*\*)/g,
  func: text => {
    return <b>{parse(text.slice(2, -2))}</b>;
  }
});

/**
 * Underline parser
 *
 * __some text__ -> <u>some text</u>
 */
PARSERS.push({
  regexp: /(__.*?__)/g,
  func: text => {
    return <u>{parse(text.slice(2, -2))}</u>;
  }
});

/**
 *  Link parser
 *
 * [/simple-link] ->  <a href="/simple-link">/simple-link</a>
 * [/some-link|with title] -> <a href="/some-link">with title</a>
 */
PARSERS.push({
  regexp: /(\[.*?\])/g,
  func: text => {
    const [link, ...title] = text.slice(1, -1).split('|');
    const content = title.length ? parse(title.join('|')) : link;
    return <Link to={link}>{content}</Link>;
  }
});

export const replaceMarkdown = text => {
  if (!isArray(text)) {
    text = [text];
  }

  return flatten(
    text.map(x => {
      return isString(x) ? parse(x) : x;
    })
  );
};

export const i18nMarkdown = (id, args = {}) => {
  return (
    <I18n>
      {t => {
        const translation = t(id, args);
        return translation ? replaceMarkdown(translation) : null;
      }}
    </I18n>
  );
};
