import React from 'react';
import styles from './Preloader.module.scss';

export const Preloader = ({ pagePosition, cover, transparent }) => {
  return (
    <div
      className={[
        styles.Preloader,
        cover ? styles.PreloaderCover : null,
        transparent ? styles.PreloaderTransparent : null
      ].join(' ')}
    >
      <div className={styles.PreloaderIconWrapper} style={{ top: `${pagePosition}px` }} />
    </div>
  );
};
