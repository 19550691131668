import PropTypes from 'prop-types';
import React from 'react';
import styles from './Badge.module.scss';

export const Badge = ({ type, text, size }) => {
  const additionalClasses = [];

  if (size === 'large') {
    additionalClasses.push(styles.BadgeLarge);
  }

  if (size === 'small') {
    additionalClasses.push(styles.BadgeSmall);
  }

  switch (type) {
    case 'orange':
      additionalClasses.push(styles.BadgeOrange);
      break;
    case 'purple':
      additionalClasses.push(styles.BadgePurple);
      break;
    case 'green':
      additionalClasses.push(styles.BadgeGreen);
      break;
    case 'skin':
      additionalClasses.push(styles.BadgeSkin);
      break;
    case 'black':
      additionalClasses.push(styles.BadgeBlack);
      break;
    case 'dark green':
      additionalClasses.push(styles.BadgeDarkGreen);
      break;
    default:
      additionalClasses.push(styles.BadgeGrey);
  }

  return (
    <div className={[styles.Badge, ...additionalClasses].join(' ')}>
      <div className={styles.BadgeText}>{text}</div>
    </div>
  );
};

Badge.propTypes = {
  /** Set type of label */
  type: PropTypes.oneOf(['grey', 'orange', 'purple', 'green', 'skin', 'black']),
  /** Text that will be visible inside label */
  text: PropTypes.string,
  /** Set size of label */
  size: PropTypes.oneOf(['small', 'large'])
};

Badge.defaultProps = {
  type: 'skin',
  size: 'small'
};
