import React from 'react';
import styles from './CmsRowHeadline.module.scss';

export const CmsRowHeadline = ({ item, bgColor = '#FFF' }) => {
  // eslint-disable-next-line camelcase
  const { row_headline, row_headline_h2, name } = item;
  return (
    <React.Fragment>
      {/* eslint-disable-next-line camelcase */}
      {row_headline === true ? (
        <div className={styles.CmsRowHeadline}>
          {/* eslint-disable-next-line camelcase */}
          {row_headline_h2 ? (
            <h2 style={{ backgroundColor: `${bgColor}` }}>{name}</h2>
          ) : (
            <span style={{ backgroundColor: `${bgColor}` }}>{name}</span>
          )}
          <hr />
        </div>
      ) : null}
    </React.Fragment>
  );
};
