import { PageviewTracking } from '../Pageview';

export const NavigationFooterTracking = ({ storeConfiguration, label }) => {
  const dataLayerData = {
    key: `navigation_footer_${label}`,
    event: 'Generic Event',
    event_name: 'navigation_footer',
    navigation_footer: {
      navigation_bar: 'footer',
      navigation_element: label,

      event_category: 'navigation',
      event_action: 'footer',
      event_label: label
    }
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
