import React from 'react';
import { isNetworkError } from '@deity/falcon-data';
import { T } from '@deity/falcon-i18n';
import { Button } from '../../ui/Button/Button';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import headerSvg from '../../../../../assets/images/sprites/headerSprite.svg';
import styles from './ErrorBoundary.module.scss';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /** @param {Error} error */
  getMessageId(error) {
    const { networkError } = error;
    const code = (isNetworkError(error) && networkError?.result?.code) || 'UNKNOWN';

    if (
      code === 'ECONNREFUSED' ||
      (networkError && 'message' in networkError && networkError.message === 'Failed to fetch')
    ) {
      return 'failedToFetch';
    }

    return 'unknown';
  }

  /** @param {Error} error */
  errorInsights(error) {
    if (process.env.NODE_ENV !== 'production') {
      return JSON.stringify(error, null, 2);
    }

    return '';
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    if (!error) {
      return this.props.children;
    }

    function switchButton() {
      const ErrorBoundaryButtons = document.getElementById('ErrorBoundaryButtons');
      const ErrorBoundaryLoader = document.getElementById('ErrorBoundaryLoader');
      ErrorBoundaryButtons.classList.add(styles.ErrorBoundarySwitch);
      ErrorBoundaryLoader.classList.add('Hide');
    }

    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'error_boundary',
        current_page: window.location.href
      });
    }

    setTimeout(() => {
      switchButton();
    }, 8000);

    setTimeout(() => {
      window.location.reload();
    }, 12000);

    const storeLogo = withStoreConfiguration(({ storeConfiguration }) => {
      const storeView = storeConfiguration?.general?.country?.default;
      switch (storeView) {
        case 'DE':
          return (
            <img
              src={`${headerSvg}#logo`}
              alt="asambeauty - M. Asam Kosmetik und ahuhu Haarpflege"
              width={240}
              height={60}
            />
          );
        case 'CH':
          return (
            <img
              src={`${headerSvg}#logo`}
              alt="asambeauty - M. Asam Kosmetik und ahuhu Haarpflege"
              width={240}
              height={60}
            />
          );
        case 'US':
        case 'PL':
          return (
            <img
              src={`${headerSvg}#logoUs`}
              alt="asambeauty - M. Asam Skincare And Beauty Cosmetics"
              className="us-logo"
              width={240}
              height={60}
            />
          );
        default:
          return (
            <img
              src={`${headerSvg}#logoNoClaim`}
              alt="asambeauty - M. Asam Skincare And Beauty Cosmetics"
              className="logo-noclaim"
              width={240}
              height={50}
            />
          );
      }
    });

    return (
      <div className={styles.ErrorBoundaryContainer}>
        <div className={styles.ErrorBoundaryWrapper}>
          <div className={styles.ErrorBoundaryLogo}>{storeLogo()}</div>
          <div className={styles.ErrorBoundaryContent}>
            <div>
              <h2>
                <T id="errorBoundary.headline" />
              </h2>
              <p>
                <T id="errorBoundary.content" />
              </p>
              <div id="ErrorBoundaryButtons" className={styles.ErrorBoundaryButton}>
                <Button disabled>
                  <T id="errorBoundary.refresh" />
                </Button>
                <Button onClick={() => window.location.reload()}>
                  <T id="errorBoundary.refresh" />
                </Button>
              </div>
            </div>
            <div id="ErrorBoundaryLoader" className={styles.ErrorBoundaryLoader}>
              <svg viewBox="0 0 50 50">
                <path
                  d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
                  transform="rotate(241.969 25 25)"
                  fill="#c92976"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
