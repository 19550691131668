import { PageviewTracking } from './Pageview';

export const CategoryFilterTracking = ({ storeConfiguration, filterName, filterElement }) => {
  const dataLayerData = {
    key: `category_filter_${filterName}_${filterElement}`,
    event: 'Generic Event',
    event_name: 'filter_product_list',
    filter_product_list: {
      filter_selection: `${filterName}: ${filterElement}`, // e.g. 'Skintype: Normal Skin'
      hit_count: undefined, // i.e. number of products listed after filter is applied

      event_category: 'product list',
      event_action: 'filter',
      event_label: `${filterName}: ${filterElement}` // e.g. 'Skintype: Normal Skin'
    }
  };
  return PageviewTracking({ storeConfiguration, dataLayerData });
};
