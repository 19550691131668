import React from 'react';
import PropTypes from 'prop-types';
import { Value } from 'react-powerplug';
import Container from '../../layout/Container/Container';
import styles from './Tabs.module.scss';

const getHeadersSection = (headers, value, set) => (
  <div className={styles.TabHeadersContainer}>
    <Container>
      <div className={styles.TabHeaders}>
        {headers.map((item, index) => {
          const key = `tab${index}`;
          const activeClass = value === key ? styles.Active : null;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div
              className={[styles.TabHeader, activeClass].join(' ')}
              onClick={() => (value === key ? set(null) : set(key))}
              key={key}
              aria-hidden
              data-header={item}
            >
              {item}
            </div>
          );
        })}
      </div>
    </Container>
  </div>
);

const getContentsSection = (contents, value) => (
  <div className={styles.TabContents}>
    <Container>
      {contents.map((item, index) => {
        const key = `tabcontent${index}`;
        const activeClass = value === `tab${index}` ? styles.Active : null;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className={[styles.TabContent, activeClass].join(' ')} key={key} id={`tabcontent${index}`}>
            {contents[index]}
          </div>
        );
      })}
    </Container>
  </div>
);

export const Tabs = ({ headers, contents, openedTabIndex }) => {
  if (!headers || !contents) {
    return null;
  }

  return (
    <div className={styles.TabGroup}>
      <Value initial={openedTabIndex ? `tab${openedTabIndex}` : null}>
        {({ value, set }) => (
          <React.Fragment>
            {getHeadersSection(headers, value, set)}
            {getContentsSection(contents, value)}
          </React.Fragment>
        )}
      </Value>
    </div>
  );
};

Tabs.propTypes = {
  /** Array of header labels */
  headers: PropTypes.PropTypes.arrayOf(PropTypes.string),
  /** Array with content for each tab */
  contents: PropTypes.PropTypes.arrayOf(PropTypes.node),
  /** Tab with that index will be opened after component render */
  openedTabIndex: PropTypes.string
};
