import { I18n, T } from '@deity/falcon-i18n';
import { Form } from '@deity/falcon-ui-kit';
import { Formik } from 'formik';
import React, { useRef } from 'react';
import { State } from 'react-powerplug';
import { withRouter } from 'react-router-dom';
import { FormField } from '../../ui/Forms/FormField';
import { Button } from '../../ui/Button/Button';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import useCheckBreakpoints from '../../hooks/CheckBreakpoint';
import { SearchAutocomplete } from './SearchAutocomplete';
import styles from './SearchBar.module.scss';

const ENTER_KEYCODE = 13;

export const SearchBar = withRouter(
  withStoreConfiguration(({ asWidget, history, storeConfiguration }) => {
    const maxQuery = storeConfiguration?.catalog?.search?.max_query_length || 128;
    const { isMobile } = useCheckBreakpoints();
    const closeSearch = setState => {
      setState({ showAutocomplete: false });
      setTimeout(() => setState({ showSearch: false }), 200);
    };

    const redirectToUrl = (urlPath, setState) => {
      if (!urlPath) {
        return;
      }

      closeSearch(setState);
      history.push(urlPath);
    };

    const handleSearchSubmit = (query, setState) => {
      // do nothing if no real search term is provided
      if (query.replace(/[_\W]\x7f-\xff+/g, '').length < 3) {
        return;
      }

      setState({ query });
      const encodedQuery = encodeURIComponent(query);
      redirectToUrl(`/search?q=${encodedQuery}`, setState, history);
    };

    const handleSearchBar = (setState, evt) => {
      if (evt.target.value.length >= 3) {
        setState({ showAutocomplete: true });
      } else {
        setState({ showAutocomplete: false });
      }
    };

    const inputElement = useRef(null);
    const focusInput = () => {
      inputElement.current.focus();
    };

    return (
      <State
        initial={{
          showSearch: asWidget || false,
          showAutocomplete: false,
          query: '',
          suggestList: []
        }}
      >
        {({ state, setState }) => (
          <div
            className={[
              styles.SearchBar,
              state.showSearch ? styles.Active : null,
              asWidget ? styles.AsWidget : null
            ].join(' ')}
          >
            <div
              data-inspect="search-buttons"
              className={styles.SearchBarTrigger}
              onFocus={() => {
                setState({ showAutocomplete: false });
              }}
            >
              <span
                className={styles.SearchBarLabel}
                onClick={() => {
                  setState({ showSearch: true });
                  focusInput();
                }}
                aria-hidden
              >
                <T id="searchBar.search" />
              </span>
            </div>
            <div className={styles.SearchBarForm}>
              <I18n>
                {t => (
                  <Formik
                    initialValues={{ query: state.query }}
                    onSubmit={values => {
                      setState({ showAutocomplete: false });
                      handleSearchSubmit((values && values.query) || '', setState);
                      setState({ query: '' });
                    }}
                    enableReinitialize
                  >
                    {() => (
                      <Form id="search-bar">
                        <Button iconSrc="search" className={styles.SearchBarIcon} type="submit" aria-label="Search" />
                        <FormField
                          placeholder={t('searchBar.placeholder')}
                          name="query"
                          value={state.query}
                          type="text"
                          maxLength={maxQuery}
                          autoComplete="off"
                          noMargin
                          removeStyles
                          onInput={evt => {
                            if (evt.target.value.length >= 3) {
                              setState({ query: evt.target.value });
                              if (evt.keyCode !== ENTER_KEYCODE) {
                                handleSearchBar(setState, evt);
                              }
                            } else {
                              setState({ showAutocomplete: false, query: evt.target.value });
                            }
                          }}
                          onSubmit={() => setState({ showAutocomplete: false })}
                          inputRef={inputElement}
                          onFocus={() => asWidget && setState({ showAutocomplete: state.query.length > 2 })}
                        />
                        {isMobile && state.query.length ? (
                          <button
                            type="button"
                            className={[styles.SearchBarReset, 'after-icon-x'].join(' ')}
                            aria-label="Reset"
                            onClick={() => {
                              setState({ query: '', showAutocomplete: false });
                            }}
                          />
                        ) : null}
                      </Form>
                    )}
                  </Formik>
                )}
              </I18n>
              {asWidget !== true ? (
                <div className={styles.SearchBarClose} onClick={() => closeSearch(setState)} aria-hidden>
                  <T id="searchBar.close" />
                </div>
              ) : null}
              {asWidget === true && state.query.length > 2 && state.showAutocomplete ? (
                <div
                  className={styles.SearchBarClose}
                  onClick={() => setState({ showAutocomplete: false })}
                  aria-hidden
                >
                  <T id="searchBar.close" />
                </div>
              ) : null}
            </div>
            {state.showAutocomplete && (
              <SearchAutocomplete
                isVisible={state.showAutocomplete}
                close={() => setState({ showAutocomplete: false })}
                query={state.query}
                submitSearch={query => {
                  handleSearchSubmit(query, setState);
                  setState({ query: '' });
                }}
                selectProduct={({ urlPath }) => {
                  let urlPathWithParameter = urlPath;
                  if (state.query) {
                    urlPathWithParameter = `${urlPath}?q=${state.query}`;
                  }
                  redirectToUrl(urlPathWithParameter, setState, history);
                  setState({ query: '' });
                }}
                asWidget={asWidget}
              />
            )}
          </div>
        )}
      </State>
    );
  })
);
