import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import useCheckBreakpoints from '../../hooks/CheckBreakpoint';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { getMobileOperatingSystem } from '../../helpers/MobileDeviceDetection/MobileDeviceDetection';
import assets from '../../../../../assets/images/sprites/headerSprite.svg';
import { isAppMobile } from '../../helpers/AppMobile/AppMobile';
import { SmartBannerTracking } from '../Tracking/GoogleTagManager/GA4/SmartBannerTracking';
import styles from './SmartBanner.module.scss';

const SmartBanner = withStoreConfiguration(({ storeConfiguration }) => {
  const { isMobile } = useCheckBreakpoints();
  const location = useLocation();
  const currentDevice = getMobileOperatingSystem();
  const checkCookie = !!Cookies.get('smart_banner');
  let enabled, text, button, url, prefix, appLink;
  const [showBanner, setShowBanner] = useState(false);
  const browserSupport = ['EdgiOS', 'FxiOS', 'CriOS', 'Chrome', 'Chromium'];
  // eslint-disable-next-line default-case
  switch (currentDevice) {
    case 'iOS':
      enabled = !!parseInt(storeConfiguration?.asam_mobile?.smart_banner?.enabled_ios, 10);
      url = storeConfiguration?.asam_mobile?.smart_banner?.sb_url_ios || '';
      text = storeConfiguration?.asam_mobile?.smart_banner?.sb_text_ios || '';
      button = storeConfiguration?.asam_mobile?.smart_banner?.sb_button_ios || 'Anzeigen';
      prefix = storeConfiguration?.asam_mobile?.smart_banner?.sb_prefix_ios || '';
      appLink = !!parseInt(storeConfiguration?.asam_mobile?.smart_banner?.sb_deeplink_ios, 10);
      // id = storeConfiguration?.asam_mobile?.smart_banner?.sb_id_ios || '';
      if (
        typeof window !== 'undefined' &&
        window.navigator?.userAgent &&
        !browserSupport.some(browser => window.navigator.userAgent.includes(browser))
      ) {
        // temporary fix for ios safari
        appLink = false;
      }
      break;

    case 'Android':
      enabled = !!parseInt(storeConfiguration?.asam_mobile?.smart_banner?.enabled_android, 10);
      url = storeConfiguration?.asam_mobile?.smart_banner?.sb_url_android || '';
      text = storeConfiguration?.asam_mobile?.smart_banner?.sb_text_android || '';
      button = storeConfiguration?.asam_mobile?.smart_banner?.sb_button_android || 'Anzeigen';
      prefix = storeConfiguration?.asam_mobile?.smart_banner?.sb_prefix_android || '';
      appLink = !!parseInt(storeConfiguration?.asam_mobile?.smart_banner?.sb_deeplink_android, 10);
      // id = storeConfiguration?.asam_mobile?.smart_banner?.sb_id_android || '';
      break;
  }
  const [deepLinkSchema, setDeepLinkSchema] = useState(prefix + location.pathname + location.search);

  const uriSchemeWithHyperlinkFallback = (appUrl, storeUrl) => {
    if (typeof window !== 'undefined') {
      if (appLink) {
        window.location.replace(appUrl);
        setTimeout(() => {
          window.location.replace(storeUrl);
        }, 2000);
      } else {
        window.location.replace(storeUrl);
      }
    }
  };

  useEffect(() => {
    setShowBanner(!!(isMobile && !checkCookie && !isAppMobile && enabled && text && url));

    if (appLink) {
      setDeepLinkSchema(prefix + location.pathname + location.search);
    }
  }, [isMobile, enabled, location]);

  return showBanner ? (
    <div className={styles.SmartBanner}>
      <button
        type="button"
        onClick={() => {
          SmartBannerTracking({
            storeConfiguration,
            eventName: 'smartbanner_close',
            eventAction: 'close',
            label: text
          });
          setShowBanner(false);
          Cookies.set('smart_banner', true, { expires: 7 });
        }}
        className={styles.SmartBannerClose}
      >
        Close
      </button>
      <div className={styles.SmartBannerIcon}>
        <img src={`${assets}#appLogo`} alt="AsambeautyApp" />
      </div>
      <p className={styles.SmartBannerText}>{text}</p>
      <a
        href="javascript:void(0);" /* eslint-disable-line */
        onClick={e => {
          SmartBannerTracking({
            storeConfiguration,
            eventName: 'smartbanner_click',
            eventAction: 'click',
            label: text
          });
          uriSchemeWithHyperlinkFallback(deepLinkSchema, url);
          e.preventDefault();
        }}
        rel="noopener nofollow noreferrer"
        data-app-link={appLink ? deepLinkSchema : null}
        className={styles.SmartBannerButton}
      >
        {button}
      </a>
      {SmartBannerTracking({
        storeConfiguration,
        label: text
      })}
    </div>
  ) : null;
});

export default SmartBanner;
