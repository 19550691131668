import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './Checkbox.module.scss';

export const Checkbox = ({
  label,
  invalid,
  renderAsRadio,
  buttonOnTop,
  variant,
  tooltipMessage,
  onAction,
  smallLabel,
  ...rest
}) => {
  return (
    <div className={[styles.Checkbox, variant === 'secondary' ? styles.Secondary : null].join(' ')}>
      <input
        type={renderAsRadio ? 'radio' : 'checkbox'}
        className={styles.CheckboxInput}
        {...rest}
        invalid={invalid ? 'invalid' : null}
      />
      {label ? (
        <label className={[styles.CheckboxLabel, smallLabel ? styles.Small : null].join(' ')} htmlFor={rest.id}>
          <div className={[styles.CheckboxStyled, 'icon-check', buttonOnTop ? styles.ButtonOnTop : null].join(' ')} />
          {label}
          {tooltipMessage ? (
            <div className={styles.CheckboxTooltip}>
              <Tooltip content={tooltipMessage} id={`${rest.name}Tooltip`} displayRelative />
            </div>
          ) : null}
        </label>
      ) : null}
    </div>
  );
};

Checkbox.defaultProps = {
  variant: 'primary'
};

Checkbox.propTypes = {
  /** Label displayed next to input */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** Variant of checkbox background */
  variant: PropTypes.oneOf(['primary', 'secondary'])
};
