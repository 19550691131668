import React from 'react';
import { H4 } from '@deity/falcon-ui';
import parse from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { T } from '@deity/falcon-i18n';
import { ProductImage } from '../ProductImage/ProductImage';
import { ProductLabels } from '../ProductLabels/ProductLabels';
import productPlaceholder from '../../../../../../assets/images/product/placeholder.png';
import { Button } from '../../../ui/Button/Button';
import { ReviewsSummary } from '../../Reviews/ReviewsSummary/ReviewsSummary';
import styles from './ProductInfo.module.scss';

export const ProductInfo = ({ product }) => {
  return (
    <div className={styles.ProductInfo}>
      <div className={styles.ProductInfoTop}>
        <div className={styles.ProductInfoTitle}>
          <span>{product.brand}</span>
          <H4>{product.name}</H4>
        </div>
        <ReviewsSummary summary={product.reviewsSummary} />
        <div className={styles.ProductInfoImage}>
          <ProductLabels labels={product.labels} product={product} config="category" />
          <ProductImage product={product} alternativeImage={product.thumbnail || productPlaceholder} />
        </div>
        <div className={styles.ProductInfoContent}>
          <div className={styles.ProductInfoDescription}>
            {parse(product.descriptionHtml || '', {
              replace: node => {
                if ('name' in node && node.name === 'li') {
                  return <li className="before-icon-check">{domToReact(node.children)}</li>;
                }
              }
            })}
          </div>
        </div>
      </div>
      <div className={styles.ProductInfoBottom}>
        <Button asLink path={product.urlPath}>
          <T id="productInfo.exploreNow" />
        </Button>
      </div>
    </div>
  );
};

export const ProductInfoListing = ({ products }) => {
  return (
    <div className={styles.ProductInfoListing}>
      {products.map(product => (
        <ProductInfo key={product.sku} product={product} />
      ))}
    </div>
  );
};
