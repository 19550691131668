import React from 'react';
import { Link } from 'react-router-dom';
import { T } from '@deity/falcon-i18n';
import { getNavBarTitle, getNavBarUrl, isItemUrlExternal } from '../MegaMenuHelpers';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { NavigationMobileTracking } from '../../../elements/Tracking/GoogleTagManager';
import { MOBILE_MENU_SECOND, MOBILE_MENU_ROOT } from './MobileMenuLevels';
import styles from './MobileMenu.module.scss';

export const MobileMenuFirstLevel = withStoreConfiguration(
  ({ storeConfiguration, data, selectedFirstLevel, openLevel, onClose, onSecondLevelChange }) => {
    const selectedData = selectedFirstLevel !== null ? data[selectedFirstLevel] : null;
    const itemUrl = selectedData ? getNavBarUrl(selectedData) : null;
    const itemUrlExternal = itemUrl ? isItemUrlExternal(itemUrl) : null;

    return (
      <div className={[styles.MobileMenuFirstLevel, styles.NoTopPadding, styles.MobileMenuContent].join(' ')}>
        {selectedData ? (
          <div>
            <div
              className={styles.MobileMenuBack}
              aria-hidden
              onClick={() => {
                openLevel(MOBILE_MENU_ROOT);
              }}
            >
              <T id="mobileMenu.back" />
            </div>
            <div className={styles.MobileMenuTitle}>{getNavBarTitle(selectedData)}</div>
            {itemUrl ? (
              <div className={styles.MobileMenuAll}>
                {itemUrlExternal ? (
                  <a href={itemUrl} onClick={onClose}>
                    + <T id="mobileMenu.showAll" />
                  </a>
                ) : (
                  <Link to={itemUrl} onClick={onClose}>
                    + <T id="mobileMenu.showAll" />
                  </Link>
                )}
              </div>
            ) : null}
            {selectedData?.elements?.length ? (
              <ul className={styles.MobileMenuSimpleList}>
                {selectedData.elements.map((item, index) => {
                  if (index > 0) {
                    return null;
                  }
                  if (item?.type_name === 'category') {
                    return (
                      item?.data_category?.links &&
                      item.data_category.links.map((link, linkIndex) => (
                        <li
                          className={styles.MobileMenuSimpleListItem}
                          key={`mobile-menu-first-level-all-${linkIndex}`} // eslint-disable-line react/no-array-index-key
                          aria-hidden
                        >
                          <Link
                            to={`/${link.url}`}
                            onClick={() => {
                              onClose();
                              NavigationMobileTracking({
                                storeConfiguration,
                                key: `burger_menu_mobile_${MOBILE_MENU_SECOND}`,
                                label: `${selectedData.title} - ${link.name}`
                              });
                            }}
                          >
                            {link?.name}
                          </Link>
                        </li>
                      ))
                    );
                  }

                  if (item?.type_name === 'link') {
                    const sectionLinks = item?.data_link?.content ? JSON.parse(item.data_link.content) : [];
                    return (
                      sectionLinks?.length &&
                      sectionLinks.map((link, linkIndex) => (
                        <li
                          className={styles.MobileMenuSimpleListItem}
                          key={`mobile-menu-first-level-all-${linkIndex}`} // eslint-disable-line react/no-array-index-key
                          aria-hidden
                        >
                          {isItemUrlExternal(link.record_url) ? (
                            <a href={link.record_url} onClick={onClose}>
                              {link?.record_text}
                            </a>
                          ) : (
                            <Link
                              to={link.record_url.indexOf('/') !== 0 ? `/${link.record_url}` : link.record_url}
                              onClick={onClose}
                            >
                              {link?.record_text}
                            </Link>
                          )}
                        </li>
                      ))
                    );
                  }

                  return null;
                })}
              </ul>
            ) : null}

            {selectedData?.elements?.length > 1 ? (
              <ul className={styles.MobileMenuList}>
                {selectedData.elements.map((item, index) => {
                  let subItemName = null;

                  if (index === 0) {
                    return null;
                  }

                  if (item?.type_name !== 'category' && item?.type_name !== 'link') {
                    return null;
                  }

                  if (item?.type_name === 'category') {
                    subItemName = item?.data_category?.name;
                  }

                  if (item?.type_name === 'link') {
                    subItemName = item?.data_link?.headline;
                  }

                  return (
                    <li
                      className={[styles.MobileMenuListItem, styles.MobileMenuListItemHasSub].join(' ')}
                      aria-hidden
                      key={`mobile-menu-first-level-${index}`} // eslint-disable-line react/no-array-index-key
                      onClick={() => {
                        onSecondLevelChange(index);
                        openLevel(MOBILE_MENU_SECOND);
                        NavigationMobileTracking({
                          storeConfiguration,
                          key: `burger_menu_mobile_${MOBILE_MENU_SECOND}`,
                          label: `${selectedData.title} - ${subItemName}`
                        });
                      }}
                    >
                      <span>{subItemName}</span>
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
);
