import gql from 'graphql-tag';
import { Query } from '@deity/falcon-data';

export const GET_MEGAMENU = gql`
  query MegaMenu {
    megaMenu {
      id
      identifier
      title
      custom_title
      type
      url
      highlight
      hl_from_date
      hl_to_date
      category
      category_link {
        category_id
        name
        url
      }
      elements {
        type
        type_name
        data_category {
          category_id
          name
          url
          links {
            category_id
            name
            url
          }
        }
        data_link {
          id
          headline
          content
        }
        data_special {
          id
          headline
          content
        }
        data_image {
          id
          headline
          image
          image_mobile
          url
          url_target
        }
        sort_order
        column
      }
    }
  }
`;

export class MegaMenuQuery extends Query {
  static defaultProps = {
    query: GET_MEGAMENU
  };
}
