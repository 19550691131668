import { PageviewTracking } from './Pageview';

export const CheckoutTracking = ({ storeConfiguration, cart, step, additionalData = null, isAmazonPay = false }) => {
  const items = [];
  let dataLayerData;
  let counter = 0;
  let bufferSeconds = 30;

  if (!cart) {
    return null;
  }

  if (cart && cart.items) {
    cart.items.forEach(item => {
      counter++;
      const itemItem = {
        item_name: item.name,
        item_id: item.sku,
        price: item.priceInclTax, // e.g. '24.75'
        coupon: cart.couponCode?.toUpperCase(), // i.e. coupon code used for the purchase
        discount: item.discountAmount, // i.e. the absolute difference between the regular price and the discounted price
        item_brand: `${item.cosBrand} - ${item.cosmeticline}`, // e.g. 'M. Asam - Magic Finish'
        item_category: item.categoryPath, // e.g. 'Make-up'
        item_category_2: item.applicationType, // e.g. 'Make-up Mousse'
        item_category_3: item.isFreeItemActive ? 'free gift' : 'regular', // e.g. 'regular' | 'on sale' | 'free gift'
        item_category_4: item.cosContent, // e.g. '30ml'
        item_variant: item.farbvariante || undefined, // e.g. 'Ivory'
        item_list_name: '', // e.g. 'Skincare'
        item_list_id: '', // e.g. '987654321'
        index: counter,
        quantity: item.qty
      };

      items.push(itemItem);
    });
  }

  if (step === 1) {
    dataLayerData = {
      key: `checkout_step_1_${cart.itemsQty}`,
      event: 'Ecommerce - View cart',
      event_name: 'view_cart',
      view_cart: {
        currency: cart.quoteCurrency, // e.g. 'EUR' | 'CHF'
        step_number: '1',
        items
      }
    };
  } else if (step === 2) {
    dataLayerData = {
      key: `checkout_step_2_${cart.itemsQty}`,
      event: 'Ecommerce - Begin Checkout',
      event_name: 'begin_checkout',
      begin_checkout: {
        currency: cart.quoteCurrency, // e.g. 'EUR' | 'CHF'
        step_number: '2',
        checkout_type: isAmazonPay ? 'amazonPay' : 'standard',
        items
      }
    };
  } else if (step === 3) {
    if (!additionalData?.checkoutData?.shippingMethod && !isAmazonPay) {
      return null;
    }
    const shippingMethod = additionalData?.checkoutData?.shippingMethod;
    bufferSeconds = 3600;

    dataLayerData = {
      key: `checkout_step_3_${shippingMethod?.data?.carrierTitle || 'Standard'}`,
      event: 'Ecommerce - Add shipping info',
      event_name: 'add_shipping_info',
      add_shipping_info: {
        shipping_tier: shippingMethod?.data?.carrierTitle || 'Standard',
        currency: cart.quoteCurrency, // e.g. 'EUR' | 'CHF'
        step_number: '3',
        checkout_type: isAmazonPay ? 'amazonPay' : 'standard',
        items
      }
    };
  } else if (step === 4) {
    if (!additionalData?.checkoutData?.paymentMethod && !isAmazonPay) {
      return null;
    }
    const paymentMethod = additionalData?.checkoutData?.paymentMethod;
    bufferSeconds = 3600;

    dataLayerData = {
      key: `checkout_step_4_${isAmazonPay ? 'amazon_pay' : paymentMethod.method}`,
      event: 'Ecommerce - Add payment info',
      event_name: 'add_payment_info',
      add_payment_info: {
        payment_type: isAmazonPay ? 'Amazon Pay' : paymentMethod.method, // e.g. 'PayPal'
        currency: cart.quoteCurrency, // e.g. 'EUR' | 'CHF'
        step_number: '4',
        checkout_type: isAmazonPay ? 'amazonPay' : 'standard',
        items
      }
    };
  }

  PageviewTracking({ storeConfiguration, dataLayerData, bufferSeconds });
  return null;
};
