import React from 'react';
import { T } from '@deity/falcon-i18n';
import { Button } from '../../../ui/Button/Button';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';

export const AppleIdLoginButton = withStoreConfiguration(({ storeConfiguration }) => {
  const enabled = storeConfiguration?.amsociallogin?.apple?.enabled || null;

  if (enabled === '0' || !enabled) {
    return null;
  }

  return (
    <Button
      asLink
      path="/amsociallogin/social/login/?type=apple"
      type="submit"
      types="appleId"
      iconSrc="apple"
      size="small"
    >
      <div>
        <T id="loginWithAppleId" />
      </div>
    </Button>
  );
});
