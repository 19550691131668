import { Locale } from '@deity/falcon-front-kit';
import React, { useState } from 'react';
import { T } from '@deity/falcon-i18n';
import { getFreeOfShipping } from '../../../helpers/FreeShipping/FreeShipping';
import loadable from '../../../../../../components/loadable';
import styles from './ProductCardPrice.module.scss';
import { BasePrice } from '..';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../../ui/Modal/Modal'));

export const ProductCardPrice = ({ product, t, storeConfiguration }) => {
  if (!product || !product.price) {
    return null;
  }

  const { minPrice, maxPrice, regular, special } = product.price;
  const fromPriceText = minPrice !== maxPrice ? t('product.fromText') : null;
  const [showDeliveryOverlay, setShowDeliveryOverlay] = useState(false);
  const freeOfShipping = getFreeOfShipping(storeConfiguration, 0, product);

  // Don't display price when not defined
  if (Number.isNaN(Number(regular))) {
    return null;
  }

  return (
    <div>
      {/* min price for gutschein can also be displayed with ticket ITW-2213 ? */}
      {product.hasCustomOptions === true ? null : (
        <React.Fragment>
          <Locale>
            {({ priceFormat }) => {
              if (priceFormat) {
                if (special) {
                  return (
                    <div className={styles.ProductCardPriceBox}>
                      <div className={styles.ProductCardPriceSpecial}>
                        <span className={styles.ProductCardPriceUvp}>
                          <T id="product.retailPrice" />
                        </span>
                        <span className={styles.ProductCardPriceSpecialShow}>{priceFormat(regular)}</span>
                      </div>
                      <div className={styles.ProductCardPrice}>
                        {fromPriceText} {priceFormat(special)}
                      </div>
                    </div>
                  );
                }

                return (
                  <div className={styles.ProductCardPriceBox}>
                    {special ? (
                      <React.Fragment>
                        <div className={styles.ProductCardPriceSpecial}>{priceFormat(regular)}</div>
                        <div className={styles.ProductCardPrice}>
                          <span className={styles.ProductCardPriceUvp}>
                            <T id="product.retailPrice" />
                          </span>
                          <span className={styles.ProductCardPriceSpecialShow}>
                            {fromPriceText} {priceFormat(special)}
                          </span>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className={styles.ProductCardPrice}>
                          {fromPriceText} {priceFormat(regular)}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                );
              }
              return null;
            }}
          </Locale>
          <div className={styles.ProductCardPricePerVolume}>
            {product.baseprice && product.baseprice !== 'false' && <BasePrice info={product.baseprice} />}
          </div>
        </React.Fragment>
      )}
      {!product.hasCustomOptions ? (
        <div className={styles.ProductCardAdditional}>
          {freeOfShipping ? (
            <div>
              ({t('product.inclTax')}
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <span onClick={() => setShowDeliveryOverlay(true)}>{t('product.deliveryshipping')}</span>
              {t('product.inclTaxOne')})
            </div>
          ) : (
            <div>
              ({t('product.exclShipping')}
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <span onClick={() => setShowDeliveryOverlay(true)}>{t('product.deliveryshipping')}</span>
              {t('product.exclShippingOne')})
            </div>
          )}
        </div>
      ) : null}
      {showDeliveryOverlay ? (
        <Modal
          pageContent={t('cms.deliveryshipping.link')}
          variant="tertiary"
          scrollableContent
          visible={showDeliveryOverlay}
          close={() => {
            setShowDeliveryOverlay(false);
          }}
        />
      ) : null}
    </div>
  );
};
