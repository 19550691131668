import React from 'react';
import { StoreConfigKeyValuesQuery } from './StoreConfigKeyValuesQuery';

const formatKeyValues = storeConfigKeyValues =>
  storeConfigKeyValues.reduce((result, keyValue) => {
    result[keyValue.key] = keyValue.value;
    return result;
  }, {});

export default Component => {
  return props => (
    <StoreConfigKeyValuesQuery passLoading>
      {({ data: { storeConfigKeyValues } }) => {
        return storeConfigKeyValues ? (
          <Component {...props} storeConfigKeyValues={formatKeyValues(storeConfigKeyValues)} />
        ) : null;
      }}
    </StoreConfigKeyValuesQuery>
  );
};
