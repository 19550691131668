import React from 'react';
import { Link as LinkTo } from 'react-router-dom';
import { List, ListItem } from '@deity/falcon-ui';
import { isItemUrlExternal } from '../../MegaMenuHelpers';
import styles from '../MegaMenuSubmenu.module.scss';
import withStoreConfiguration from '../../../../helpers/StoreConfiguration/StoreConfiguration';
import { NavigationHeaderTracking } from '../../../../elements/Tracking/GoogleTagManager';

export const Link = withStoreConfiguration(
  ({ storeConfiguration, headline, content, subMenuId, subMenuClass, parent }) => {
    const sectionLinks = content ? JSON.parse(content) : [];
    function closeSubmenu() {
      const navItem = document.querySelector(`[data-nav=${subMenuId}]`);
      navItem.classList.remove(subMenuClass);
    }
    return (
      <div className={styles.MenuDrawerColumn}>
        <div className={styles.MenuTitle}>{headline}</div>
        {sectionLinks?.length ? (
          <List className={styles.MenuDrawerLinks}>
            {sectionLinks.map(link => {
              if (link?.record_url) {
                link.record_url = link.record_url.trim();
                return (
                  <ListItem key={link?.record_id}>
                    {isItemUrlExternal(link?.record_url) ? (
                      <a href={link.record_url} rel="noopener noreferrer nofollow" onClick={() => closeSubmenu()}>
                        {link?.record_text}
                      </a>
                    ) : (
                      <LinkTo
                        to={link.record_url.indexOf('/') !== 0 ? `/${link.record_url}` : link.record_url}
                        onClick={() => {
                          NavigationHeaderTracking({
                            storeConfiguration,
                            key: 'level_3',
                            label: `${parent.title} - ${headline} - ${link.record_text}`
                          });
                          closeSubmenu();
                        }}
                      >
                        {link?.record_text}
                      </LinkTo>
                    )}
                  </ListItem>
                );
              }
              return null;
            })}
          </List>
        ) : null}
      </div>
    );
  }
);
