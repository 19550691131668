import React from 'react';
import { SearchConsumer } from '@deity/falcon-front-kit';
import { ViewItemTracking, PageViewActiveItemTracking } from '../../Tracking/GoogleTagManager';

export const ActiveProduct = ({
  storeConfiguration,
  product,
  trackChange,
  setTrackChange,
  children: renderChildren
}) => {
  const { children, options } = product || {};
  if (!children?.length || !options?.length) {
    return renderChildren({ activeProduct: product, selectedOptions: [], selectActiveProduct: () => {} });
  }

  return (
    <SearchConsumer>
      {({ state, setFilter }) => {
        const setActiveConfigurableOptionValue = (option, value) =>
          setFilter(option.attributeCode, [encodeURIComponent(value.valueIndex)]);

        const getChildrenByAttributeValue = (productChildren, attributeCode, valueIndex) =>
          productChildren.filter(child =>
            child.configurations?.find(
              configuration => configuration.attributeCode === attributeCode && configuration.value === valueIndex
            )
          );

        // for old URLs if label is used instead of value
        const getValueByLabel = (attributeCode, label) =>
          options.find(option => option.attributeCode === attributeCode)?.values?.find(value => value.label === label)
            ?.valueIndex || label;

        const getSelectedOptions = () => {
          if (!state.filters?.length) {
            return children[0]?.configurations?.reduce((result, { attributeCode, value }) => {
              result[attributeCode] = value;
              return result;
            }, {});
          }
          const selectedOptions =
            (state.filters &&
              state.filters.length &&
              state.filters.reduce(
                (result, { field, value }) => ({ ...result, [field]: getValueByLabel(field, value[0]) }),
                {}
              )) ||
            {};
          options.reduce((result, { attributeCode, values }) => {
            if (result[attributeCode]) {
              return result;
            }
            const availableValue = values.find(
              ({ valueIndex, isInStock }) =>
                isInStock && getChildrenByAttributeValue(children, attributeCode, valueIndex).length
            );
            if (availableValue) {
              result[attributeCode] = availableValue.valueIndex;
            }
            return result;
          }, selectedOptions);
          return selectedOptions;
        };

        const getActiveProduct = selectedOptions => {
          if (selectedOptions) {
            return Object.entries(selectedOptions).reduce((result, [field, value]) => {
              const filtered = getChildrenByAttributeValue(result, field, value);
              return filtered.length ? filtered : result;
            }, children)[0];
          }
          return children[0];
        };

        const selectedOptions = getSelectedOptions();
        const activeProduct = getActiveProduct(selectedOptions);

        if (trackChange) {
          ViewItemTracking({ storeConfiguration, product: activeProduct, bufferSeconds: 1 });
          PageViewActiveItemTracking({ storeConfiguration, product: activeProduct, bufferSeconds: 1 });
          setTrackChange(false);
        }

        return renderChildren({
          activeProduct,
          selectedOptions,
          selectActiveProduct: setActiveConfigurableOptionValue,
          state
        });
      }}
    </SearchConsumer>
  );
};
