import React from 'react';
import { I18n } from '@deity/falcon-i18n';
import { Link } from 'react-router-dom';
import { Locale } from '@deity/falcon-front-kit';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import headerSvg from '../../../../../assets/images/sprites/headerSprite.svg';
import styles from './PreHeaderStatic.module.scss';

const getConsList = withStoreConfiguration(({ storeConfiguration }) => (
  <ul className={styles.PreHeaderStaticItems}>
    <Locale>
      {localeContext => {
        const price = new Intl.NumberFormat(localeContext.locale, {
          style: 'currency',
          currency: localeContext.currency,
          minimumFractionDigits: 0
        }).format(storeConfiguration?.asam_shippinginformation?.general?.free_shipping_amount);
        const minDelivery = storeConfiguration?.asam_shippinginformation?.general?.min_delivery;
        const maxDelivery = storeConfiguration?.asam_shippinginformation?.general?.max_delivery;
        const paybackActive = storeConfiguration?.payback?.general?.enabled === '1';

        return (
          <React.Fragment>
            <I18n>
              {t =>
                []
                  .concat(
                    t('shopConsAndContact.cons', {
                      returnObjects: true,
                      freeShippingAmount: price,
                      minDelivery,
                      maxDelivery
                    })
                  )
                  .map((x, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li className={styles.PreHeaderStaticItem} key={`cons-${index}`}>
                      {x}
                    </li>
                  ))
              }
            </I18n>
            {paybackActive ? (
              <I18n>
                {t => (
                  <li className={styles.PaybackLogo}>
                    <Link to={`/${t('cms.payback.link')}`}>
                      <img src={`${headerSvg}#PaybackLogoDesktop`} alt="Payback Logo" width={60} height={24} />
                    </Link>
                  </li>
                )}
              </I18n>
            ) : null}
          </React.Fragment>
        );
      }}
    </Locale>
  </ul>
));

export const PreHeaderStatic = () => {
  return <div className={styles.PreHeaderStatic}>{getConsList()}</div>;
};
