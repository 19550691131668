import { I18n, T } from '@deity/falcon-i18n';
import React from 'react';
import { BackendConfigQuery } from '@deity/falcon-shop-data';
import { LanguageSwitcherContent } from '../../../elements/LanguageSwitcher/LanguageSwitcherContent';
import styles from './MobileMenu.module.scss';
import { MOBILE_MENU_ROOT } from './MobileMenuLevels';

export const MobileMenuStore = ({ openLevel }) => (
  <div className={[styles.MobileMenuStore, styles.NoTopPadding, styles.MobileMenuContent].join(' ')}>
    <div
      className={styles.MobileMenuBack}
      onClick={() => {
        openLevel(MOBILE_MENU_ROOT);
      }}
      aria-hidden
    >
      <T id="mobileMenu.back" />
    </div>
    <div className={styles.MobileMenuTitle}>
      <BackendConfigQuery>
        {({
          data: {
            backendConfig: {
              shop: { activeStore, stores }
            }
          }
        }) => {
          const locale = [];
          let website = null;

          stores.forEach(item => {
            if (item?.code === activeStore) {
              website = item;
              locale.push(<I18n key={item?.code}>{t => t('domain.storeShortCode')}</I18n>);
            }
          });

          return (
            <span>
              Country - {locale} | {website ? <I18n>{t => t('domain.currency')}</I18n> : null}
            </span>
          );
        }}
      </BackendConfigQuery>
    </div>
    <div className={styles.MobileMenuStoreContent}>
      <LanguageSwitcherContent section="menu" />
    </div>
  </div>
);
