import React from 'react';
import { Form } from '@deity/falcon-ui-kit';
import { Formik } from 'formik';
import { FormField } from '../../../ui/Forms/FormField';
import { Radio } from '../../../ui/Radio/Radio';
import { Button } from '../../../ui/Button/Button';
import { ProductVariantTracking } from '../../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './Variants.module.scss';

const CONTEXT_PLP = 'plp';
const CONTEXT_PDP = 'pdp';

const PLP_INITIAL_SIZE = 4;
// const PDP_INITIAL_SIZE = 10;

const renderSwatch = value => {
  if (!value || !value.swatch) {
    return null;
  }

  const disabled = !value.isInStock;
  switch (value.swatch.type) {
    case 'image':
      return (
        <div className={[styles.VariantItemImage, disabled ? styles.VariantItemDisabled : null].join(' ')}>
          <img src={value.swatch.value} alt="" />
        </div>
      );
    case 'color':
      return (
        <div
          className={[styles.VariantItemColor, disabled ? styles.VariantItemDisabled : null].join(' ')}
          style={{ backgroundColor: value.swatch.value }}
        />
      );
    default:
      return null;
  }
};

const renderVariantLabel = (value, selected, setVariantTitle) => {
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      className={[
        styles.VariantItem,
        !value.isInStock ? styles.VariantDisabled : null,
        selected === value.label ? styles.Active : null
      ].join(' ')}
      onMouseOver={() => setVariantTitle(value.label)}
      onMouseLeave={() => setVariantTitle(selected)}
    >
      {value?.price?.special && value.price.special < value.price.regular && (
        <span className={styles.VariantDiscountLabel}>
          -{((value.price.regular - value.price.special) / value.price.regular) * 100}%
        </span>
      )}
      {renderSwatch(value)}
    </div>
  );
};

export const VariantContext = {
  plp: CONTEXT_PLP,
  pdp: CONTEXT_PDP
};

export const Variants = withStoreConfiguration(
  ({ storeConfiguration, options, context, selectVariant, selectedOptions, activeProduct, setTrackChange }) => {
    if (!options || !options.length || !options[0].values || !options[0].values.length) {
      return null;
    }

    if (context === CONTEXT_PLP) {
      return options.map(option => {
        const variantsToShow = option.values.length > PLP_INITIAL_SIZE + 1 ? PLP_INITIAL_SIZE : PLP_INITIAL_SIZE + 1;

        return (
          <div className={styles.VariantItemListWrapper}>
            {option.values.slice(0, variantsToShow).map((value, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`variant_${index}`} className={styles.VariantItem}>
                {renderSwatch(value)}
              </div>
            ))}
            {option.values.length > variantsToShow && (
              <div className={styles.ButtonWrapper}>
                <Button types="lessmore">
                  <span className="icon-plus" />
                </Button>
              </div>
            )}
          </div>
        );
      });
    }
    if (context === CONTEXT_PDP) {
      const getActiveConfigurationByAttributeCode = attributeCode =>
        activeProduct?.configurations?.find(configuration => configuration.attributeCode === attributeCode);

      const getOptionValueByConfiguration = ({ values }, { value }) =>
        value && values?.find(({ valueIndex }) => valueIndex === value);

      return (
        <div className={styles.VariantWrapper}>
          {options.map(option => {
            const { attributeCode } = option;
            const selectedOptionValue =
              getOptionValueByConfiguration(option, { value: selectedOptions?.[attributeCode] }) ||
              getOptionValueByConfiguration(option, getActiveConfigurationByAttributeCode(attributeCode) || {}) ||
              option.values[0];

            const { label: selectedLabel, valueIndex: selectedIndex } = selectedOptionValue;
            return (
              <Formik
                key={attributeCode}
                initialValues={{ variant: selectedLabel, title: selectedLabel, index: selectedIndex }}
                enableReinitialize
              >
                {({ values, setValues }) => {
                  const updateTitle = title => setValues({ ...values, title });
                  return (
                    <Form id={`variantSelection-${attributeCode}`}>
                      <FormField name="variant">
                        {({ form, field }) => {
                          return (
                            <React.Fragment>
                              <h3>{values.title}</h3>
                              <div className={styles.VariantItemWrapper}>
                                {option.values.map(optionValue => (
                                  <Radio
                                    key={optionValue.valueIndex}
                                    name="variantItem"
                                    value={optionValue.valueIndex}
                                    checked={field.value === optionValue.valueIndex}
                                    // disabled={!optionValue.isInStock}
                                    onChange={x => {
                                      form.setFieldValue(field.name, x.target.value);
                                      selectVariant(option, optionValue);
                                      ProductVariantTracking({ storeConfiguration, variant: optionValue });
                                      setTrackChange(true);
                                    }}
                                    label={renderVariantLabel(optionValue, values.variant, updateTitle)}
                                  />
                                ))}
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </FormField>
                    </Form>
                  );
                }}
              </Formik>
            );
          })}
        </div>
      );
    }
  }
);
