import React from 'react';
import { T } from '@deity/falcon-i18n';
import { Multiselect } from '../../../ui/Multiselect/Multiselect';
import { Tabs } from '../../../ui/Tabs/Tabs';
import Container from '../../../layout/Container/Container';
import { CmsRichtext } from '../../Cms/Modules/Components/CmsRichText';
import { Button } from '../../../ui/Button/Button';
import { LazyLoad } from '../../../helpers/LazyLoad/LazyLoad';
import { ProductContentTracking } from '../../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import loadable from '../../../../../../components/loadable';
import useCheckBreakpoints from '../../../hooks/CheckBreakpoint';
import styles from './Details.module.scss';

const Row = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ '../../Cms/Row'));

function openContent(target) {
  const contentTarget = document.getElementById(target);
  const buttonTarget = document.querySelector(`[data-id="${target}"]`);
  contentTarget.classList.toggle(styles.Active);
  buttonTarget.classList.toggle(styles.Active);
  if (!contentTarget.classList.contains(styles.Active) && window.innerWidth <= 1023) {
    contentTarget?.parentElement?.parentElement?.parentElement?.scrollIntoView({ behavior: 'smooth' });
  }
}

export const Details = withStoreConfiguration(({ storeConfiguration, content, t }) => {
  const headers = [],
    preparedContents = [];
  const { isDesktop } = useCheckBreakpoints();

  if (content.long_description !== null) {
    headers.push(t('product.description'));
    preparedContents.push(
      <React.Fragment>
        {content.long_description.slice(0, 1).map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row key={`rowItem-'${index}'`} item={item.fields} contentType={item.sys.contentType.sys.id} />
        ))}
        {content.long_description[1] ? (
          <LazyLoad>
            <div className={styles.ExpandableContent} id="expandableContentDesc">
              {content.long_description.slice(1, 10).map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Row key={`rowItem-'${index}'`} item={item.fields} contentType={item.sys.contentType.sys.id} />
              ))}
            </div>
            <div className={styles.ButtonWrapper} data-id="expandableContentDesc">
              <Button
                types="lessmore"
                onClick={event => {
                  const wrapperTarget = event.currentTarget.parentNode;
                  openContent('expandableContentDesc');
                  ProductContentTracking({ storeConfiguration, open: wrapperTarget.classList.contains(styles.Active) });
                }}
              >
                <span className="before-icon-minus">
                  <T id="showLess" />
                </span>
                <span className="before-icon-plus">
                  <T id="showMore" />
                </span>
              </Button>
            </div>
          </LazyLoad>
        ) : null}
      </React.Fragment>
    );
  }
  if (content.directions !== null) {
    headers.push(t('product.directions'));
    preparedContents.push(
      <React.Fragment>
        {content.directions.slice(0, 1).map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row key={`rowItem-'${index}'`} item={item.fields} contentType={item.sys.contentType.sys.id} />
        ))}
        {content.directions[1] ? (
          <React.Fragment>
            <div className={styles.ExpandableContent} id="expandableContentDir">
              {content.directions.slice(1, 10).map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Row key={`rowItem-'${index}'`} item={item.fields} contentType={item.sys.contentType.sys.id} />
              ))}
            </div>
            <div className={styles.ButtonWrapper} data-id="expandableContentDir">
              <Button types="lessmore" onClick={() => openContent('expandableContentDir')}>
                <span className="before-icon-minus">
                  <T id="showLess" />
                </span>
                <span className="before-icon-plus">
                  <T id="showMore" />
                </span>
              </Button>
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
  if (content.ingredients !== null) {
    headers.push(t('product.ingredients'));
    preparedContents.push(
      <>
        <CmsRichtext text={content.ingredients} />
        <div className={styles.IngredientsNote}>
          <T id="cms.ingredients.note" />
        </div>
      </>
    );
  }

  return (
    <div className={styles.Details}>
      {isDesktop ? (
        <Tabs headers={headers} contents={preparedContents} openedTabIndex="0" />
      ) : (
        <Container>
          <Multiselect
            headers={headers}
            contents={preparedContents}
            removePaddingOnMobile
            openedTabIndex="0"
            titleVariant="secondary"
            iconVariant="secondary"
            scrollToHead
          />
        </Container>
      )}
    </div>
  );
});
