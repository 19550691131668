import React, { memo, useCallback, useMemo } from 'react';
import { BrowserRouter, Prompt } from 'react-router-dom';
import { useLoadingBarHandle } from '../hooks/LoadingBar';

// ITW-6234
// react-router v5.3.3 doesn't expose an obvious API to delay navigation from a Link click.
// We (ab)use the user confirmation feature here that is usually used to prevent navigating away from forms.
// That feature can be configured with a custom handler, with a callback for when to proceed with navigation.

// We need a react-router Prompt in the VDOM with a (fake) message in order for the handler to be called.
// We prepend one to the children that are passed in.
const FAKE_PROMPT_MESSAGE = '__BROWSER_ROUTER_PROMPT__';
function childrenWithPromptFactory(children) {
  return (
    <>
      <Prompt message={FAKE_PROMPT_MESSAGE} />
      {children}
    </>
  );
}

// Re-render optimization
// This memoization prevents the BrowserRouter from re-rendering when the LoadingBarContext is updated.
const BrowserRouterMemo = memo(BrowserRouter);

/**
 * BrowserRouter optimized for the Web Vitals INP metric.
 * Delays any in-app route change to immediately show the global LoadingBar.
 * @param {object} props BrowserRouter props (Do not pass getUserConfirmation)
 * @returns {BrowserRouter}
 */
export default function OptimizedBrowserRouter({ children, ...props }) {
  const setLoading = useLoadingBarHandle(false);

  // Will be called on every navigation because of the fake Prompt above.
  const delayedConfirmer = useCallback((prompt, callback) => {
    if (prompt !== FAKE_PROMPT_MESSAGE) {
      // Another Prompt could override our fake prompt.
      // In that case replicate the native behavior with window.confirm
      const userConfirmed = window.confirm(prompt);
      if (!userConfirmed) {
        callback(false);
        return;
      }
    }
    setLoading(true);
    setTimeout(() => {
      callback(true);
      setLoading(false);
    }, 0);
  }, []);

  // Re-render optimization
  // We need childrenWithPrompt to be stable as long as children doesn't update, otherwise BrowserRouterMemo would re-render.
  const childrenWithPrompt = useMemo(() => childrenWithPromptFactory(children), [children]);

  return (
    <BrowserRouterMemo {...props} getUserConfirmation={delayedConfirmer}>
      {childrenWithPrompt}
    </BrowserRouterMemo>
  );
}
