import React from 'react';
import styles from './Container.module.scss';

const Container = props => {
  const { className, relative, clearOnTablet, clearOnMobile, noPadding, flex, background, ...restProps } = props;
  const inlineStyles = {
    maxWidth: '1108px'
  };

  if (background) {
    inlineStyles.backgroundImage = `url('${background}')`;
  }

  return (
    <div
      className={[
        styles.Container,
        className,
        clearOnTablet ? styles.ClearTablet : null,
        clearOnMobile ? styles.ClearMobile : null,
        noPadding ? styles.ContainerNoPadding : null,
        flex ? styles.ContainerFlex : null,
        relative ? styles.ContainerRelative : null
      ].join(' ')}
      {...restProps}
      style={inlineStyles}
    >
      {props.children}
    </div>
  );
};

export default Container;
