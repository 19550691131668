import { PageviewTracking } from './Pageview';

export const CategoryTracking = ({ storeConfiguration, products, category }) => {
  if (category && products) {
    // build category path with names
    let categoryPathName = '';
    if (category.breadcrumbs) {
      categoryPathName = category.breadcrumbs.map(breadcrumb => {
        return `${breadcrumb.name} > `;
      });
      categoryPathName += category.name;
    }

    const productList = [];
    products.forEach(item => {
      productList.push({
        name: item.name, // e.g. 'Lisptick ...'
        brand: `${item.brand} - ${item.cosmeticlineValue}`, // e.g. 'M. Asam - Magic Finish'
        sku: item.sku, // e.g. '41250'
        price: item.price?.special || item.price?.regular, // e.g. '24.75'
        category: item.categoryPath, // e.g. 'M. Asam/Geschenke/ Limitierte Editionen',
        categoryPathName, // e.g. 'Maske > korper'
        imageURL: item?.productImage?.default
      });
    });

    const dataLayerData = {
      page_type: 'category',
      page_title: category?.contentData?.meta_title,
      currency: storeConfiguration?.currency?.options?.default,
      products: productList
    };

    return PageviewTracking({ storeConfiguration, dataLayerData });
  }
  return null;
};
