import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from '@deity/falcon-ui';
import styles from '../MegaMenuSubmenu.module.scss';
import { NavigationHeaderTracking } from '../../../../elements/Tracking/GoogleTagManager';
import withStoreConfiguration from '../../../../helpers/StoreConfiguration/StoreConfiguration';

export const Special = withStoreConfiguration(({ storeConfiguration, headline, content, parent }) => {
  const sectionLinks = content ? JSON.parse(content) : [];

  return (
    <div className={styles.MenuDrawerSpecial}>
      <div className={styles.MenuTitle}>{headline}</div>
      <ul className={[styles.MenuDrawerLinks, styles.Secondary].join(' ')}>
        {sectionLinks.map((link, index) => {
          const hasImage = link?.record_image?.length === 1;
          const image = hasImage ? link?.record_image?.[0]?.url : null;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <li className={!hasImage ? styles.NoImage : null} key={`special-${index}`}>
              <Link
                className={styles.Sale}
                to={link.record_url.indexOf('/') !== 0 ? `/${link.record_url}` : link.record_url}
                onClick={() => {
                  NavigationHeaderTracking({
                    storeConfiguration,
                    key: 'level_3',
                    label: `${parent.title} - ${headline} - ${link.record_text}`
                  });
                }}
              >
                {image ? <Image src={image} /> : null}
                {link?.record_text}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
});
