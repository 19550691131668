import React from 'react';

export const BREAKPOINTS = {
  tablet: 1023,
  mobile: 767
};

const useCheckBreakpoints = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  const [isTablet, setIsTablet] = React.useState(false);
  const [isDesktop, setIsDesktop] = React.useState(false);

  const resizeWindowHandler = () => {
    const { innerWidth } = window;

    const desktop = innerWidth > BREAKPOINTS.tablet;
    const tablet = innerWidth <= BREAKPOINTS.tablet && innerWidth > BREAKPOINTS.mobile;
    const mobile = innerWidth <= BREAKPOINTS.mobile;

    setIsMobile(mobile);
    setIsTablet(tablet);
    setIsDesktop(desktop);
  };

  React.useEffect(() => {
    const isSSR = typeof window === 'undefined';

    if (!isSSR) {
      resizeWindowHandler();
      window.addEventListener('resize', resizeWindowHandler);

      return () => {
        window.removeEventListener('resize', resizeWindowHandler);
      };
    }
  }, []);

  return { isDesktop, isMobile, isTablet };
};

export default useCheckBreakpoints;
