import React from 'react';
import { List, ListItem } from '@deity/falcon-ui';
import { I18n, T } from '@deity/falcon-i18n';
import { Locale } from '@deity/falcon-front-kit';
import { Button } from '../../../ui/Button/Button';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { SignInForm } from './SignInForm';
import styles from './SignIn.module.scss';

export const SignIn = ({ setIsOpen }) => (
  <div className={styles.SignInOverlay}>
    <div className={styles.SignInOverlaySection}>
      <div className={styles.SignInWrapper}>
        <div className={styles.NewCustomerHeadline}>
          <T id="customerAccount.signIn" />
        </div>
        <SignInForm
          id="sign-in-overlay"
          onSuccess={() => {
            setIsOpen(false);
          }}
          lwaId="account"
        />
      </div>
    </div>
    <div className={styles.SignInOverlaySection}>
      <NewCustomer setIsOpen={setIsOpen} />
    </div>
  </div>
);

const NewCustomer = withStoreConfiguration(({ setIsOpen, storeConfiguration }) => {
  const minDelivery = storeConfiguration?.asam_shippinginformation?.general?.min_delivery;
  const maxDelivery = storeConfiguration?.asam_shippinginformation?.general?.max_delivery;
  return (
    <div className={styles.NewCustomerWrapper}>
      <div className={styles.NewCustomerHeadline}>
        <T id="customerAccount.newCustomer" />
      </div>
      <div className={styles.NewCustomerHeader}>
        <T id="customerAccount.benefitsHeader" />
      </div>
      <List className={styles.NewCustomerList}>
        <Locale>
          {localeContext => {
            const price = new Intl.NumberFormat(localeContext.locale, {
              style: 'currency',
              currency: localeContext.currency,
              minimumFractionDigits: 0
            }).format(storeConfiguration?.asam_shippinginformation?.general?.free_shipping_amount);
            return (
              <I18n>
                {t =>
                  []
                    .concat(
                      t('customerAccount.benefitsList', {
                        minDelivery,
                        maxDelivery,
                        returnObjects: true,
                        freeShippingAmount: price
                      })
                    )
                    .map(txt => (
                      <ListItem key={txt} className={styles.NewCustomerListItem}>
                        {txt}
                      </ListItem>
                    ))
                }
              </I18n>
            );
          }}
        </Locale>
      </List>
      <Button size="small" asLink path="/sign-in" onClick={() => setIsOpen(false)}>
        <T id="customerAccount.more" />
      </Button>
    </div>
  );
});
