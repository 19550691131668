import withStoreConfiguration from '../../../../helpers/StoreConfiguration/StoreConfiguration';
import { isAppMobile } from '../../../../helpers/AppMobile/AppMobile';
import { HashSha256 } from '../../../../helpers/Hash/Hash';

export const PageviewTracking = ({ storeConfiguration, dataLayerData, bufferSeconds = 30, forcePush = false }) => {
  const alreadyInDatalayer = (newDataLayer, oldDataLayer) => {
    const filteredEntries = oldDataLayer.filter(
      dataLayerEntry =>
        dataLayerEntry.key === newDataLayer.key && dataLayerEntry.timestamp > newDataLayer.timestamp - bufferSeconds
    );

    return filteredEntries.length > 0;
  };

  const logEventNative = (name, params) => {
    if (!name) {
      return;
    }
    if (window?.AnalyticsWebInterface) {
      // Call Android interface
      window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
    } else if (window?.webkit?.messageHandlers?.firebase) {
      // Call iOS interface
      const message = {
        command: 'logEvent',
        name,
        parameters: params?.[name]
      };
      window.webkit.messageHandlers.firebase.postMessage(message);
    } else {
      // No Android or iOS interface found
      console.log('No native APIs found.');
    }
  };

  const pushIntoDatalayer = (dataLayer, newDataLayer) => {
    if (forcePush || !alreadyInDatalayer(newDataLayer, window.dataLayer)) {
      if (parseInt(storeConfiguration?.asam_tracking?.googletagmanager?.debug, 10)) {
        console.log(newDataLayer);
      }

      if (isAppMobile) {
        logEventNative(newDataLayer?.event_name, newDataLayer);
      }

      dataLayer.push(newDataLayer);
    }
  };

  if (typeof window !== 'undefined') {
    if (parseInt(storeConfiguration?.asam_tracking?.googletagmanager?.active, 10) && window.dataLayer) {
      const { dataLayer } = window;
      dataLayerData = {
        timestamp: Math.floor(Date.now() / 1000),
        key: `virtual_page_view_${HashSha256(JSON.stringify(dataLayerData))}`,
        event: 'Virtual Page View',
        page_path: window?.location?.pathname,
        shop_country: storeConfiguration?.general?.country?.default,
        ...dataLayerData
      };
      dataLayerData.key = dataLayerData.key ? `ga4_${dataLayerData.key}` : undefined;
      if (dataLayerData && dataLayerData.ecommerce) {
        dataLayerData.ecommerce.currencyCode = storeConfiguration?.currency?.options?.default;
      }
      pushIntoDatalayer(dataLayer, dataLayerData);
    }
  }
  return null;
};

export const GoogleTagManager4Pageview = withStoreConfiguration(({ storeConfiguration, dataLayerData, forcePush }) => {
  PageviewTracking({ storeConfiguration, dataLayerData, forcePush });

  return null;
});
