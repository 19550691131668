import { RemoveCartItemMutation } from '@deity/falcon-shop-data';
import gql from 'graphql-tag';

export const REMOVE_CART_ITEM = gql`
  mutation RemoveCartItem($input: RemoveCartItemInput!) {
    removeCartItem(input: $input) {
      itemId
    }
  }
`;

export class RemoveCartItemMutationExt extends RemoveCartItemMutation {
  static defaultProps = {
    ...super.defaultProps,
    refetchQueries: ['MiniCart', 'Cart', 'FreeShipping', 'InitPaypalExpress']
  };
}
