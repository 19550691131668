import React from 'react';
import { useStoreContext, COMPAT_CONFIG_OBJ_KEY } from '../../elements/Store/StoreProvider';

// ITW-6136: This module is soft-deprecated, prefer useStoreContext from StoreProvider.js.
//
// The default export is now using the StoreProvider context for performance reasons.
// Because of the many use sites, we keep the default export API for compatibility with minor caveats.
// A full refactoring is WIP, see ITW-4029.
//
// Compatibility notes:
// - Using the default export requires StoreProvider to be an ancestor component. As long as it stays near the root
//   in client.js / ssrMiddleware.js, this is a non-issue for other app components.
// - If you _really_ need the StoreConfiguration outside the StoreContext, the old wrapper is still provided with
//   `import StoreConfigurationSlow from './StoreConfigurationSlow'`.
// - The unused useStoreConfiguration is removed.
//
// Performance notes:
// Previously, a new Apollo Query was created here for each wrapped element. Creating a query is relatively expensive,
// even if the data is already in the local Apollo Cache. We were spending up to 100s of ms here on pageload/navigation
// because this wrapper is used so much.

export default Component => {
  return props => {
    const storeContext = useStoreContext();
    return storeContext?.[COMPAT_CONFIG_OBJ_KEY] ? (
      <Component {...props} storeConfiguration={storeContext[COMPAT_CONFIG_OBJ_KEY]} />
    ) : null;
  };
};
