import { Component } from 'react';
import { debounce } from 'lodash';

export const BREAKPOINTS = {
  tablet: 1023,
  mobile: 767
};

export class CheckBreakpoint extends Component {
  isMounted = false;

  constructor(props) {
    super(props);

    const isSSR = typeof window === 'undefined';
    let breakpoints = {
      desktop: false,
      tablet: false,
      mobile: false,
      ssr: isSSR
    };

    if (!isSSR) {
      const { innerWidth } = window;

      breakpoints = {
        desktop: innerWidth > BREAKPOINTS.tablet,
        tablet: innerWidth <= BREAKPOINTS.tablet && innerWidth > BREAKPOINTS.mobile,
        mobile: innerWidth <= BREAKPOINTS.mobile,
        ssr: isSSR
      };
    }

    this.state = {
      breakpoints
    };

    this.handleBreakpointsUpdate = debounce(this.handleBreakpointsUpdate.bind(this), 500);
  }

  componentDidMount() {
    this.isMounted = true;
    this.handleBreakpointsUpdate();
    window.addEventListener('resize', this.handleBreakpointsUpdate);
  }

  componentWillUnmount() {
    this.isMounted = false;
    window.removeEventListener('resize', this.handleBreakpointsUpdate);
  }

  handleBreakpointsUpdate() {
    const { innerWidth } = window;

    const breakpoints = {
      desktop: innerWidth > BREAKPOINTS.tablet,
      tablet: innerWidth <= BREAKPOINTS.tablet && innerWidth > BREAKPOINTS.mobile,
      mobile: innerWidth <= BREAKPOINTS.mobile,
      ssr: false
    };

    if (this.isMounted) {
      this.setState({
        breakpoints
      });
    }
  }

  render() {
    const { breakpoints } = this.state;

    return this.props.children(breakpoints);
  }
}
