const LAST_SEEN_PRODUCTS_KEY = 'lastSeenProducts';
const LAST_SEEN_MAX_LIMIT = 8;

/**
 * Get data from local storage
 * @returns {any}
 */

const getFromStorage = () => JSON.parse(localStorage.getItem(LAST_SEEN_PRODUCTS_KEY));

/**
 * Get data from local storage by userId
 * @param userId
 * @returns {*}
 */

const getFromStorageByUser = userId => {
  const lastSeenProducts = JSON.parse(localStorage.getItem(LAST_SEEN_PRODUCTS_KEY));
  let usersLastSeenProducts = null;

  if (lastSeenProducts !== null) {
    lastSeenProducts.lastSeenProducts.forEach(item => {
      if (item.userId === userId) {
        usersLastSeenProducts = item.products;
      }
    });
  }

  return usersLastSeenProducts;
};

/**
 * Save data to local storage with correct key
 * @param lastSeenProducts
 */

const saveToStorage = lastSeenProducts =>
  localStorage.setItem(
    LAST_SEEN_PRODUCTS_KEY,
    JSON.stringify({
      lastSeenProducts
    })
  );

/**
 * Prepare last seen product data for new user
 * @param userId
 * @param productId
 * @param lastSeen
 * @returns {*}
 */

const prepareNewUser = (userId, productId, lastSeen) => {
  lastSeen.push({
    userId,
    products: [productId]
  });

  return lastSeen;
};

/**
 * Check if user already exist and update last seen product data
 * @param userId
 * @param productId
 * @param lastSeen
 * @returns {boolean}
 */

const checkAndUpdateProducts = (userId, productId, lastSeen) => {
  let userExist = false;

  lastSeen.forEach(item => {
    if (item.userId === userId) {
      if (!item.products.includes(productId)) {
        if (item.products.length === LAST_SEEN_MAX_LIMIT) {
          item.products.pop();
        }

        item.products = [productId, ...item.products];
      }

      userExist = true;
    }
  });

  return userExist;
};

export { getFromStorage, saveToStorage, prepareNewUser, checkAndUpdateProducts, getFromStorageByUser };
