import React from 'react';
import { T } from '@deity/falcon-i18n';
import { DeliveryTime } from '../../DeliveryTime/DeliveryTime';
import { i18nMarkdown } from '../../../helpers/Markdown/Markdown';
import styles from './Stock.module.scss';

export const Stock = ({ stock, productType }) => {
  const isBundle = productType === 'bundle';

  return (
    <React.Fragment>
      {isBundle ? (
        <>
          {!stock.isInStock ? (
            <div className={[styles.StockAvailability, styles.StockNotAvailable].join(' ')}>
              <T id="product.stock.notInStock" />
            </div>
          ) : null}
          {stock.isInStock ? (
            <div className={[styles.StockAvailability, styles.StockAvailable].join(' ')}>
              <T id="product.deliveryText" />
              <DeliveryTime />
            </div>
          ) : null}
        </>
      ) : (
        <>
          {!stock.isInStock ? (
            <div className={[styles.StockAvailability, styles.StockNotAvailable].join(' ')}>
              <T id="product.stock.notInStock" />
            </div>
          ) : null}
          {stock.isInStock && (stock.qty < 100 && stock.qty > 0) ? (
            <div className={[styles.StockAvailability, styles.StockNotAvailable].join(' ')}>
              {i18nMarkdown('product.stock.fewLeftInStock')}
              <T id="product.deliveryText" />
              <DeliveryTime />
            </div>
          ) : null}
          {stock.isInStock && stock.qty >= 100 ? (
            <div className={[styles.StockAvailability, styles.StockAvailable].join(' ')}>
              <T id="product.deliveryText" />
              <DeliveryTime />
            </div>
          ) : null}
          {!isBundle && stock.isInStock && stock.qty === 0 ? (
            <div className={[styles.StockAvailability, styles.StockAvailableSoon].join(' ')}>
              <T id="product.stock.soonInStock" />
            </div>
          ) : null}
        </>
      )}
    </React.Fragment>
  );
};
