import React from 'react';
import { T } from '@deity/falcon-i18n';
import { Button } from '../../../ui/Button/Button';
import { MOBILE_MENU_ROOT } from './MobileMenuLevels';
import styles from './MobileMenu.module.scss';

export const MobileMenuService = ({ onClose, openLevel }) => (
  <div className={[styles.MobileMenuService, styles.NoTopPadding, styles.MobileMenuContent].join(' ')}>
    <div
      className={styles.MobileMenuBack}
      onClick={() => {
        openLevel(MOBILE_MENU_ROOT);
      }}
      aria-hidden
    >
      <T id="mobileMenu.back" />
    </div>
    <div className={styles.MobileMenuTitle}>
      <T id="mobileMenu.customer" />
    </div>
    <div className={styles.MobileMenuServiceContent}>
      <div>
        <T id="mobileMenu.freeHotline" />
      </div>
      <div className={styles.Bold}>(DE) 00800 - 60 50 50 50</div>
      <div className={styles.Small}>
        <T id="mobileMenu.freeCall" />
      </div>
      <div className={styles.Hours}>
        <div>
          <T id="mobileMenu.hoursFirst" />
        </div>
        <div>
          <T id="mobileMenu.hoursSecond" />
        </div>
      </div>
      <Button asStandardLink path="tel:+0080060505050" variant="secondary" onClick={onClose}>
        <T id="mobileMenu.callNow" />
      </Button>
    </div>
  </div>
);
