import React, { createContext, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfigKeyValues';
import StoreConfigurationSlow from '../../helpers/StoreConfiguration/StoreConfigurationSlow';

// ITW-6136: StoreContext also stores the raw config object as a performance optimization,
// see ../../helpers/StoreConfiguration/StoreConfiguration.js
export const COMPAT_CONFIG_OBJ_KEY = '_compatStoreConfigJson';

export const StoreContext = createContext({});

export const StoreProvider = withStoreConfiguration(
  StoreConfigurationSlow(
    withRouter(({ storeConfigKeyValues, storeConfiguration: compatConfigJson, history, suffix, code, children }) => {
      const getStoreConfigValue = key => storeConfigKeyValues[key];
      const getStoreConfigFlag = key => {
        const value = getStoreConfigValue(key);
        return value === '0' ? false : !!value;
      };
      const getStoreConfigInt = key => parseInt(getStoreConfigValue(key) || '0', 10);
      const getStoreConfigFloat = key => parseFloat(getStoreConfigValue(key) || '0');
      const getStoreConfigList = (key, separator = ',') => (getStoreConfigValue(key) || '').split(separator);
      const getStoreConfigJson = key => JSON.parse(getStoreConfigValue(key) || '{}');

      const [pathname, setPathname] = useState(history.location.pathname);
      history.listen(location => {
        setPathname(location.pathname);
      });
      return (
        <StoreContext.Provider
          value={{
            suffix,
            storeCode: code,
            pathname,
            getStoreConfigValue,
            getStoreConfigFlag,
            getStoreConfigInt,
            getStoreConfigFloat,
            getStoreConfigList,
            getStoreConfigJson,
            [COMPAT_CONFIG_OBJ_KEY]: compatConfigJson
          }}
        >
          {children}
        </StoreContext.Provider>
      );
    })
  )
);

export const useStoreContext = () => useContext(StoreContext);
