import { I18n, T } from '@deity/falcon-i18n';
import React from 'react';
import { InnerHTML } from '@deity/falcon-ui-kit';
import { Button } from '../../ui/Button/Button';
import { ProductImageAlternative } from '../Product/ProductImage/ProductImage';
import { SuggestQuery } from '../Search/SuggestQuery';
import { InternalSearchTracking } from '../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './SearchAutocomplete.module.scss';

const refactorName = (query, name) => {
  const terms = query
    .toLowerCase()
    .split(/[^a-zA-ZÀ-Ÿ0-9]+/g)
    .map(term => term.trim())
    .join('|');
  return name.replace(new RegExp(terms, 'gi'), match => `<span>${match}</span>`);
};

export const SearchAutocomplete = withStoreConfiguration(
  ({ storeConfiguration, isVisible, close, submitSearch, selectProduct, query, asWidget }) => (
    <SuggestQuery variables={{ term: query || '', maxCompletions: 10, maxProducts: 4 }} passLoading passError>
      {({
        data: { suggest: { suggestList = [], productList = { items: [] }, defaultProductList } = {} },
        error,
        loading
      }) => {
        if (error) {
          console.log(`error in search bar for term: ${error}`);
        }
        if (loading) {
          return null;
        }

        const products = productList.items && productList.items?.length > 0 ? productList : defaultProductList;
        return (
          <div
            className={[
              styles.SearchAutocomplete,
              isVisible ? styles.Visible : null,
              asWidget ? styles.AsWidget : null
            ].join(' ')}
          >
            <div className={styles.SearchAutocompleteArrow}>
              <div className={styles.SearchAutocompleteArrowBg} />
            </div>
            <div className={[styles.SearchAutocompleteClose, 'after-icon-x'].join(' ')} onClick={close} aria-hidden>
              <T id="searchBar.close" />
            </div>
            <div className={styles.SearchTerms}>
              <div className={styles.SearchAutocompleteTitle}>
                <T id="searchBar.title" />
              </div>
              {suggestList?.length === 0 && productList.items?.length === 0 ? (
                <div className={styles.SearchTermNoMatch}>
                  <T id="searchBar.noMatches" />
                </div>
              ) : (
                <ul className={styles.SearchTermsList}>
                  {suggestList.map(({ value }) => (
                    <li className={styles.SearchTermsListItem} key={value}>
                      <InnerHTML
                        onClick={() => {
                          submitSearch(value);
                          InternalSearchTracking({
                            searchTerm: value,
                            storeConfiguration,
                            searchMethod: 'search_suggestion'
                          });
                        }}
                        html={`${refactorName(query, value)}...`}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className={styles.Products}>
              <div className={styles.SearchAutocompleteTitle}>
                <T id="searchBar.suggestions" />
              </div>
              <ul className={styles.ProductsList}>
                {products?.items?.map(({ id, name, urlPath, productImage, volume }) => (
                  <li className={styles.ProductsListItem} key={id}>
                    <div
                      onClick={() => {
                        selectProduct({ id, urlPath });
                        InternalSearchTracking({
                          searchTerm: name,
                          storeConfiguration,
                          searchMethod: 'product_suggestion'
                        });
                      }}
                      role="presentation"
                    >
                      <div className={styles.ProductImage}>
                        <ProductImageAlternative product={{ name, productImage }} width={64} height={64} />
                      </div>
                      <div className={styles.ProductInfo}>
                        <InnerHTML className={styles.ProductName} html={refactorName(query, name)} />
                        <div className={styles.ProductAttr}>{volume}</div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.SearchAutocompleteButton}>
              <I18n>
                {t => {
                  if (productList && productList.pagination && productList.pagination.totalItems) {
                    return (
                      <Button
                        onClick={() => {
                          InternalSearchTracking({
                            searchTerm: query,
                            searchResults: productList.pagination.totalItems,
                            storeConfiguration,
                            searchMethod: 'all_search_results'
                          });
                          submitSearch(query);
                        }}
                      >
                        {t('searchBar.seeAll', { number: productList.pagination.totalItems })}
                      </Button>
                    );
                  }
                  return null;
                }}
              </I18n>
            </div>
          </div>
        );
      }}
    </SuggestQuery>
  )
);
