import React from 'react';
import { createTheme } from '@deity/falcon-ui';

export const deityGreenTheme = createTheme({
  colors: {
    primary: '#222222',
    primaryLight: '#95c110',
    black: '#000000'
  },

  fonts: {
    sans: '"Open Sans", system-ui, sans-serif'
  },

  fontWeights: {
    bold: 500
  },

  icons: {
    loader: {
      icon: props => (
        <svg viewBox="0 0 50 50" {...props}>
          <path
            d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
            transform="rotate(241.969 25 25)"
            fill="#c92976"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 25 25"
              to="360 25 25"
              dur="0.8s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      ),
      size: 'xxl',
      stroke: 'transparent'
    },
    starfull: {
      icon: props => (
        <svg {...props} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.16605 0.894825C7.11292 0.73517 6.88708 0.735169 6.83395 0.894825L5.53444 4.79976C5.47665 4.9734 5.31419 5.09056 5.13118 5.09056H0.941427C0.770887 5.09056 0.701079 5.30962 0.840175 5.40829L4.21731 7.80392C4.36985 7.91212 4.43373 8.10732 4.37468 8.28476L3.08182 12.1697C3.02841 12.3302 3.21116 12.4655 3.34912 12.3677L6.7541 9.95229C6.90139 9.84781 7.09861 9.84781 7.24589 9.95229L10.6509 12.3677C10.7888 12.4655 10.9716 12.3302 10.9182 12.1697L9.62532 8.28476C9.56627 8.10732 9.63015 7.91212 9.78269 7.80392L13.1598 5.40829C13.2989 5.30962 13.2291 5.09056 13.0586 5.09056H8.86882C8.68581 5.09056 8.52335 4.9734 8.46556 4.79976L7.16605 0.894825Z"
            fill="#FFC445"
            stroke="#E4AF3D"
            strokeWidth="0.25"
          />
        </svg>
      ),
      stroke: 'none'
    },
    infoBlack: {
      icon: props => (
        <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="7.5" cy="7.5" r="6" stroke="#000000" />
          <line x1="7.5" y1="4.5" x2="7.5" y2="8.5" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
          <line x1="7.5" y1="10" x2="7.5" y2="11" stroke="#000000" strokeLinejoin="round" />
        </svg>
      ),
      stroke: 'none'
    },
    present: {
      icon: props => (
        <svg {...props} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path
              d="M9.86083 5.12109C9.86083 5.12109 10.072 2.07463 12.7385 0.9257C15.4049 -0.223231 16.6259 1.54899 16.5616 2.32688C16.4972 3.10476 14.9214 4.08933 13.4364 4.65078C11.9514 5.21222 9.86083 5.12109 9.86083 5.12109Z"
              stroke="#C92976"
              strokeWidth="0.75"
              strokeMiterlimit="10"
            />
            <path
              d="M9.74365 5.12291C9.74365 5.12291 9.5242 2.03903 6.74722 0.877077C3.97025 -0.284873 2.70138 1.5085 2.76738 2.29615C2.83339 3.0838 4.47515 4.08139 6.02122 4.64772C7.56728 5.21405 9.74365 5.12291 9.74365 5.12291Z"
              stroke="#C92976"
              strokeWidth="0.75"
              strokeMiterlimit="10"
            />
            <path
              d="M0.831631 5.26562H18.835V9.53262H0.831631V5.26562Z"
              stroke="#C92976"
              strokeWidth="0.75"
              strokeMiterlimit="10"
            />
            <path
              d="M1.60238 9.53418H18.1025V21.5052H1.60238V9.53418Z"
              stroke="#C92976"
              strokeWidth="0.75"
              strokeMiterlimit="10"
            />
            <path d="M9.86084 5.12109V21.5039" stroke="#C92976" strokeWidth="0.75" strokeMiterlimit="10" />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="19" height="22" fill="white" transform="matrix(-1 0 0 1 19.3335 0)" />
            </clipPath>
          </defs>
        </svg>
      )
    },
    starempty: {
      icon: props => (
        <svg {...props} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.16605 0.894825C7.11292 0.73517 6.88708 0.735169 6.83395 0.894825L5.53444 4.79976C5.47665 4.9734 5.31419 5.09056 5.13118 5.09056H0.941427C0.770887 5.09056 0.701079 5.30962 0.840175 5.40829L4.21731 7.80392C4.36985 7.91212 4.43373 8.10732 4.37468 8.28476L3.08182 12.1697C3.02841 12.3302 3.21116 12.4655 3.34912 12.3677L6.7541 9.95229C6.90139 9.84781 7.09861 9.84781 7.24589 9.95229L10.6509 12.3677C10.7888 12.4655 10.9716 12.3302 10.9182 12.1697L9.62532 8.28476C9.56627 8.10732 9.63015 7.91212 9.78269 7.80392L13.1598 5.40829C13.2989 5.30962 13.2291 5.09056 13.0586 5.09056H8.86882C8.68581 5.09056 8.52335 4.9734 8.46556 4.79976L7.16605 0.894825Z"
            fill="#E3E3E3"
            stroke="#E3E3E3"
            strokeWidth="0.25"
          />
        </svg>
      ),
      stroke: 'none'
    }
  },
  keyframes: {
    loader: {
      '0%': {
        transform: 'rotateZ(0)'
      },
      '100%': {
        transform: 'rotateZ(360deg)'
      }
    }
  },
  components: {
    appLayout: {
      px: {
        xs: '0',
        md: '0'
      },
      css: {
        margin: '0 auto',
        maxWidth: 'auto'
      }
    },
    icon: {
      stroke: 'black'
    },

    breadcrumb: {
      css: ({ theme }) => ({
        ':last-child': {
          pointerEvents: 'none',
          fontWeight: theme.fontWeights.bold,
          color: theme.colors.primaryLight,
          '::after': {
            display: 'none'
          }
        }
      })
    },

    navbar: {
      bgFullWidth: 'primary',
      css: {
        zIndex: 2
      }
    },

    navbarItemMenu: {
      css: {
        background: 'white',
        boxShadow: '0 0 8px rgba(33, 34, 36, 0.24)',
        display: 'flex',
        padding: '0',
        color: 'none'
      }
    },

    navbarItem: {
      css: {
        ':hover': {
          background: 'none',
          color: 'white'
        }
      }
    },

    sidebar: {
      css: {
        boxSizing: 'border-box',
        width: '100%',
        maxWidth: 375,
        overflow: 'initial',
        boxShadow: '0 0 8px rgba(33, 34, 36, 0.24)',
        zIndex: 4
      }
    },

    loader: {
      css: {
        height: '60px'
      }
    },

    badge: {
      bg: 'primaryLight',
      color: 'black'
    },

    button: {
      px: 'xl',
      height: 'xl',
      bg: 'black',
      css: {
        transitionProperty: 'all',
        textTransform: 'capitalize'
      },

      variants: {
        loader: {
          size: 'xl',
          borderRadius: 'round',
          border: 'bold',
          borderColor: 'primary',
          p: 'none',
          css: props => ({
            animation: `${props.theme.keyframes.loader} .8s linear infinite`,
            borderRightColor: props.theme.colors.white,
            background: 'none',
            fontSize: 0,
            whiteSpace: 'nowrap',
            cursor: 'default',
            overflow: 'hidden',

            ':hover': {
              borderColor: props.theme.colors.primaryLight,
              borderRightColor: props.theme.colors.white
            }
          })
        }
      }
    },

    input: {
      height: 'xl'
    },
    select: {
      height: 'xl'
    },

    error: {
      css: {
        fontSize: '12px',
        letterSpacing: '0.5px',
        fontWeight: '600',
        color: '#e20000',
        marginTop: '10px'
      }
    },

    formFieldError: {
      css: {
        fontSize: '12px',
        letterSpacing: '0.5px',
        color: '#e20000',
        marginBottom: '16px',
        paddingRight: '17px'
      }
    },

    label: {
      fontSize: 'xs',
      fontWeight: 'bold'
    },

    backdrop: {
      css: {
        zIndex: 2,
        opacity: 0
      }
    },

    formLayout: {
      gridGap: 'none'
    },

    form: {
      gridGap: 'none'
    },

    root: {
      css: {
        overflowX: 'initial'
      }
    }
  }
});

export const globalCss = {
  body: {
    margin: 0
  },
  html: {
    overflowY: 'scroll'
  }
};
