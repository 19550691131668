import React from 'react';
import { Helmet } from 'react-helmet-async';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';

export const ProductMeta = withStoreConfiguration(({ storeConfiguration, contentData, product, path }) => {
  const stores = Object.values(JSON.parse(storeConfiguration?.asam_store?.language_switch?.external_systems || {}));
  const current = storeConfiguration.web.secure.base_url || null;
  const productImage =
    (product?.type === 'configurable' && product.children?.[0]?.gallery?.[0]) || product?.gallery?.[0];
  const shouldShowLink = contentData?.index && !contentData.index.includes('NOINDEX');
  const isParent = product.children !== undefined;
  const allowWebP = storeConfiguration?.asam_imagecompression?.webp?.enabled === '1';
  const ogImageContent = productImage?.webpFull && allowWebP ? productImage.webpFull : productImage?.full;

  return (
    <Helmet>
      {contentData?.meta_title && <title>{contentData.meta_title}</title>}
      {contentData?.meta_title && <meta property="og:title" content={contentData.meta_title} />}
      {contentData?.meta_description && <meta name="description" content={contentData.meta_description} />}
      {contentData?.meta_description && <meta property="og:description" content={contentData.meta_description} />}
      {current && <meta property="og:url" content={`${current}${path.slice(1)}`} />}
      {ogImageContent && <meta property="og:image" content={ogImageContent} />}
      {productImage?.webpFull && allowWebP && <link rel="preload" href={productImage.webpFull} as="image" />}
      <meta property="og:image:width" content="300" />
      <meta property="og:image:height" content="300" />
      {contentData?.index && isParent ? (
        <meta name="robots" content={contentData.index} />
      ) : (
        <meta name="robots" content="NOINDEX" />
      )}
      {contentData?.canonicalLink && shouldShowLink && isParent && (
        <link rel="canonical" href={`${contentData.canonicalLink}`} />
      )}
      {!contentData?.canonicalLink && current && shouldShowLink && isParent && (
        <link rel="canonical" href={`${current}${path.slice(1)}`} />
      )}
      {stores &&
        shouldShowLink &&
        isParent &&
        product.storeUrls?.map(store => {
          const localeStore = stores.find(({ storecode }) => storecode === store.storeCode);
          return localeStore ? (
            <link key={`hreflang_${store.storeCode}`} rel="alternate" hreflang={localeStore.locale} href={store.url} />
          ) : null;
        })}
    </Helmet>
  );
});
