import { CheckDays } from '../../../../helpers/DeliveryTime/CheckDays';
import { HashSha512 } from '../../../../helpers/Hash/Hash';
import { PageviewTracking } from './Pageview';

export const PurchaseTracking = ({ storeConfiguration, lastOrder }) => {
  let estimatedDeliveryTime = CheckDays(storeConfiguration);
  estimatedDeliveryTime = estimatedDeliveryTime.toISOString().split('T')[0];

  const items = [];
  let counter = 0;

  lastOrder.items.forEach(item => {
    counter++;

    items.push({
      item_name: item.name, // e.g. 'Magic Finish Make-up Mousse'
      item_id: item.sku, // e.g. '123456789'
      price: item.basePrice, // e.g. '24.75'
      coupon: lastOrder.couponCode?.toUpperCase(), // i.e. coupon code used for the purchase
      discount: item.discountAmount, // i.e. the absolute difference between the regular price and the discounted price
      item_brand: `${item.brand} - ${item.cosmeticline}`, // e.g. 'M. Asam - Magic Finish'
      item_category: item.categoryPath, // e.g. 'Make-up'
      item_category_2: item.applicationType, // e.g. 'Make-up Mousse'
      item_category_3: item.basePrice === 0 ? 'free gift' : 'regular', // e.g. 'regular' | 'on sale' | 'free gift'
      item_category_4: item.cosContent, // e.g. '30ml'
      item_variant: item.color || undefined, // e.g. 'Ivory'
      item_list_name: '', // e.g. 'Skincare'
      item_list_id: '', // e.g. '987654321'
      index: counter,
      quantity: item.qty
    });
  });

  const dataLayerData = {
    key: `purchase_${lastOrder?.referenceNo}`,
    event: 'Ecommerce - Purchase',
    event_name: 'purchase',
    page_type: 'conversion_sale',
    customer_type: lastOrder?.isNewCustomer ? 'New customer' : 'Existing customer', // e.g. 'New customer' | ‘Existing customer‘
    purchase: {
      transaction_id: lastOrder?.referenceNo,
      affiliation: `onlineshop_${lastOrder?.storeId}`,
      delivery_option: parseInt(lastOrder?.shippingAssignments?.shipToPackstation, 10) ? 'packstation' : 'standard', // standard oder packstation
      value: lastOrder?.baseNetOrderValue,
      currency: storeConfiguration?.currency?.options?.default, // e.g. 'EUR' | 'CHF'
      payment_type: lastOrder?.paymentMethodName, // e.g. 'credit card'
      tax: lastOrder?.taxAmount,
      shipping: lastOrder?.baseShippingAmount,
      order_type: lastOrder?.subscriptionId ? 'subscription' : 'standard',
      subscription_interval: lastOrder?.subscriptionInterval ? lastOrder.subscriptionInterval : '',
      estimated_delivery_time: estimatedDeliveryTime,
      order_country: lastOrder?.billingAddress?.countryId,
      loyalty_program: lastOrder?.hasPayback ? 'payback' : '',
      order_email: lastOrder.customerEmail,
      hashedOrderEmail: HashSha512(lastOrder.customerEmail),
      items
    }
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
