import React from 'react';
import CmsPlaceholder from '../Cms/Modules/Components/CmsPlaceholder';
import useYouTubePlaceholder from '../../hooks/YouTubePlaceholder';

export const YouTubeNoCookie = ({ videoId, autoPlay = false, ...rest }) => {
  const originUrl = window?.location?.origin || 'https://www.asambeauty.com';

  const { loadPlaceholder, setLoadPlaceholder } = useYouTubePlaceholder();

  return (
    <>
      {!loadPlaceholder ? (
        <CmsPlaceholder element="youtube" setLoadPlaceholder={setLoadPlaceholder} />
      ) : (
        <iframe
          title="Asambeauty"
          src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=${autoPlay}&cc_load_policy=0&controls=1&disablekb=0&fs=1&iv_load_policy=1&modestbranding=1&playsinline=0&rel=0&showinfo=1&enablejsapi=1&origin=${originUrl}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder={0}
          {...rest}
        />
      )}
    </>
  );
};
