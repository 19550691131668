import React from 'react';
import { T } from '@deity/falcon-i18n';
import { Button } from '../../../ui/Button/Button';
import { useStoreContext } from '../../Store/StoreProvider';

export const PaypalLoginButton = () => {
  const { getStoreConfigFlag } = useStoreContext();
  const active = getStoreConfigFlag('amsociallogin.paypal.enabled');

  if (active) {
    return (
      <>
        <Button
          asLink
          path="/amsociallogin/social/login/?type=paypal"
          type="submit"
          types="paypal"
          iconSrc="paypal"
          size="small"
        >
          <div>
            <T id="logInWithPayPal" />
          </div>
        </Button>
      </>
    );
  }
  return null;
};
