import gql from 'graphql-tag';
import { Query } from '@deity/falcon-data';

export const GET_BACKEND_CONFIG = gql`
  query BackendConfig {
    backendConfig {
      locales
      activeLocale
      shop {
        sortOrderList {
          name
          value {
            field
            direction
          }
        }
        activeCurrency
        activeStore
        stores {
          code
          name
          website
          websiteName
          group
          groupName
          isDefault
          locale
        }
      }
    }
  }
`;
export class BackendConfigQuery extends Query {
  static defaultProps = {
    ...super.defaultProps,
    query: GET_BACKEND_CONFIG
  };
}
