import React, { useState } from 'react';
import { T, I18n } from '@deity/falcon-i18n';
import { Form, ErrorSummary, FormSubmit } from '@deity/falcon-ui-kit';
import { FormField } from '../../../ui/Forms/FormField';
import { Button } from '../../../ui/Button/Button';
import { ForgotPaswordProvider } from '../ForgotPassword/ForgotPasswordProvider';
import { useRequestPasswordResetMutation } from '../ForgotPassword/ForgotPasswordMutation';
import { useGlobalStateValue } from '../../../helpers/GlobalState/GlobalState';
import { ForgotPasswordTrigger } from '../ForgotPassword/ForgotPasswordTrigger';
import { AmazonLoginButton as LoginWithAmazonButtonV2 } from '../../Amazon/PayV2/AmazonLoginButton';
import { FacebookLoginButton } from '../../SocialConnect/FacebookLoginButton';
import { AppleIdLoginButton } from '../../SocialConnect/AppleId/AppleIdLoginButton';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { LoginTracking } from '../../Tracking/GoogleTagManager';
import { PaypalLoginButton } from '../../SocialConnect/PayPal/PaypalLoginButton';
import { SignInFormProvider } from './SignInFormProvider';
import styles from './SignInForm.module.scss';

export const SignInForm = withStoreConfiguration(
  ({
    storeConfiguration,
    initialEmail,
    onSuccess,
    onForgotPassword,
    variant,
    withoutSocial,
    forgotPassword,
    setForgotPassword,
    inPopup,
    onForgotSuccess,
    lwaId,
    ...formProps
  }) => {
    const [requestPasswordReset] = useRequestPasswordResetMutation();
    const [, dispatch] = useGlobalStateValue();

    const facebookButton = storeConfiguration?.asam_socialconnect?.enabled;
    const amazonButton = storeConfiguration?.payment?.amazon_payment_v2?.active === '1';
    const [isDisabled, setIsDisabled] = useState(true);
    const minPassword = storeConfiguration.customer.password.minimum_password_length;

    const validateFields = values => {
      const errors = {};
      const emailField = (values.email && values.email.trim()) || '';
      const passField = (values.password && values.password.trim()) || '';

      setIsDisabled(true);

      if (
        emailField !== '' &&
        emailField.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) &&
        passField !== '' &&
        passField.length >= minPassword
      ) {
        setIsDisabled(false);
      }
      if (emailField !== '' && emailField.length > 80) {
        errors.email = <T id="formError.maxLength" maxLength="80" />;
      }
      if (passField !== '' && passField.length > 250) {
        errors.password = <T id="formError.maxLength" maxLength="250" />;
      }
      return errors;
    };

    return !forgotPassword ? (
      <I18n>
        {t => (
          <React.Fragment>
            <SignInFormProvider
              enableReinitialize="true"
              initialValues={{
                email: initialEmail,
                password: ''
              }}
              onSuccess={data => {
                LoginTracking({ storeConfiguration, loginMethod: 'asambeauty' });
                onSuccess(data);
              }}
              validate={values => validateFields(values)}
            >
              {({ status = {} }) => (
                <Form i18nId="signIn" method="post" {...formProps}>
                  <FormField
                    name="email"
                    type="email"
                    label={t('signUp.email')}
                    placeholder={t('signUp.email')}
                    autoComplete="off"
                    showErrorMessage
                    variant={variant}
                    required
                  />
                  <FormField
                    name="password"
                    type="password"
                    label={t('signUp.password')}
                    placeholder={t('signUp.password')}
                    autoComplete="off"
                    showErrorMessage
                    variant={variant}
                    noMargin
                    required
                  />
                  {inPopup ? (
                    <div className={styles.SignInFormForgotPassword}>
                      <span
                        className="Link LinkPurple"
                        onClick={() => {
                          setForgotPassword(true);
                        }}
                        aria-hidden
                      >
                        <T id="customerAccount.forgotPasswordQuestion" />
                      </span>
                    </div>
                  ) : (
                    <div className={styles.SignInFormForgotPassword}>
                      <ForgotPasswordTrigger />
                    </div>
                  )}
                  <FormSubmit>
                    {({ form }) => (
                      <div className={styles.SignInFormButton}>
                        <Button type="submit" size="small" loading={form.isSubmitting} disabled={isDisabled}>
                          <T id="customerAccount.logIn" />
                        </Button>
                      </div>
                    )}
                  </FormSubmit>
                  {status.error && <ErrorSummary errors={status.error} />}
                  {status.error
                    ? LoginTracking({
                        storeConfiguration,
                        failed: true,
                        errorMessage: status.error[0].message,
                        loginMethod: 'asambeauty'
                      })
                    : null}
                </Form>
              )}
            </SignInFormProvider>
            {!withoutSocial ? (
              <div className={styles.SignInFormOther}>
                {amazonButton || facebookButton ? (
                  <div className={['LabelWithDashes', 'LabelWithDashesCenter', styles.SignInFormOtherLabel].join(' ')}>
                    <span className="LabelWithDashesText">
                      <T id="customerAccount.or" />
                    </span>
                  </div>
                ) : null}
                <div className={styles.SignInFacebookButton}>
                  <FacebookLoginButton />
                </div>
                <div className={styles.SignInAppleButton}>
                  <AppleIdLoginButton />
                </div>
                <div className={styles.SignInAmazonButton}>
                  <LoginWithAmazonButtonV2 placement="Other" />
                </div>
                <div>
                  <PaypalLoginButton />
                </div>
              </div>
            ) : null}
          </React.Fragment>
        )}
      </I18n>
    ) : (
      <I18n>
        {t => (
          <ForgotPaswordProvider
            initialValues={{ email: '', template: 'email_reset', websiteId: 1 }}
            onSubmit={(values, { setSubmitting }) =>
              requestPasswordReset({ variables: { input: values } }).then(() => {
                let email = null;
                let message = null;
                // eslint-disable-next-line prefer-destructuring
                email = values.email;
                message = {
                  content: t('signIn.forgotPasswordMessage', { email }),
                  type: 'success',
                  animate: false
                };
                dispatch({
                  type: 'ADD_TO_STATE',
                  section: 'messages',
                  data: message
                });
                window.scrollBy(0, -10000);
                onForgotSuccess();
                setSubmitting(false);
              })
            }
          >
            {({ isValid }) => {
              return (
                <div className={styles.ForgotPasswordCheckout}>
                  <Form id="forgotPassword">
                    <div className={styles.ForgotPasswordInput}>
                      <FormField
                        showErrorMessage
                        label={t('signUp.email')}
                        placeholder={t('signUp.email')}
                        required
                        name="email"
                        type="email"
                        variant="secondary"
                      />
                    </div>
                    <div className={styles.ForgotPasswordButtonWrapper}>
                      <FormSubmit>
                        {({ form }) => (
                          <Button type="submit" size="small" disabled={!isValid} loading={form.isSubmitting}>
                            <T id="signIn.buttonSend" />
                          </Button>
                        )}
                      </FormSubmit>
                      <span
                        className="Link LinkPurple"
                        onClick={() => {
                          setForgotPassword(false);
                        }}
                        aria-hidden
                      >
                        <T id="signIn.buttonBack" />
                      </span>
                    </div>
                  </Form>
                </div>
              );
            }}
          </ForgotPaswordProvider>
        )}
      </I18n>
    );
  }
);
