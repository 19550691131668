import React, { useState, useEffect } from 'react';
import { NavbarItem } from '@deity/falcon-ui';
import { Link } from 'react-router-dom';
import { NavigationHeaderTracking } from '../../elements/Tracking/GoogleTagManager';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { MegaMenuSubmenu } from './MegaMenuSubmenu/MegaMenuSubmenu';
import {
  MEGAMENU_SUBMENU,
  getNavBarUrl,
  isItemUrlExternal,
  getNavBarTitle,
  updateHighlightItem
} from './MegaMenuHelpers';
import styles from './MegaMenu.module.scss';

function closeSubmenu() {
  const navId = document.getElementsByClassName(styles.MenuItem);
  const navItem = document.getElementsByClassName('MenuSubmenu');
  let i;
  for (i = 0; i < navId.length; i++) {
    navId[i].classList.remove(styles.Active);
  }

  for (i = 0; i < navItem.length; i++) {
    navItem[i].classList.remove(styles.Active);
  }
}

function showSubmenu(menuMouseOver, target) {
  const navId = document.querySelector(`[data-id=${target}]`);
  const navItem = document.querySelector(`[data-nav=${target}]`);
  closeSubmenu();
  if (menuMouseOver) {
    navId.classList.add(styles.Active);
    navItem.classList.add(styles.Active);
  } else {
    setTimeout(() => {
      if (document.querySelector(`[data-id=${target}]:hover`)) {
        navId.classList.add(styles.Active);
        navItem.classList.add(styles.Active);
      }
    }, 251);
  }
}

export const MegaMenu = withStoreConfiguration(({ storeConfiguration, data }) => {
  const [menuMouseOver, setMenuMouseOver] = useState(false);
  let timeout;

  const Arrow = props => {
    const disabled = props.disabled ? styles.ScrollMenuArrowDisabled : '';
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        onClick={props.onClick}
        className={`${props.left ? styles.ScrollMenuArrowPrev : styles.ScrollMenuArrowNext} ${disabled}`}
      />
    );
  };

  const [nextClicked, setNextClicked] = useState(false);
  const [showArrows, setShowArrows] = useState(false);
  const [moveBy, setMoveBy] = useState('');
  const [transformX, setTransformX] = useState('');

  useEffect(() => {
    const parentEl = document.querySelector('.menu-wrapper');
    const parentElWidth = parseInt(parentEl.offsetWidth, 10) - 150;
    const childsEl = document.querySelector('.menu-move');
    const totalWidth = Object.values(childsEl.childNodes).reduce((total, i) => total + i.offsetWidth, 0);
    const childsElWidth = parseInt(totalWidth, 10);

    if (childsElWidth > parentElWidth) {
      setShowArrows(true);
      setMoveBy(`${parentElWidth - childsElWidth - 20}px`);
    }
  }, []);

  let menu;

  if (data) {
    menu = data.map((item, index) => {
      const navbarItemUrl = getNavBarUrl(item).trim();
      const isUrlExternal = isItemUrlExternal(navbarItemUrl);
      const navbarTitle = getNavBarTitle(item);
      const hasSubmenu = item?.type === MEGAMENU_SUBMENU && item?.elements?.length;
      const [isLiveItem, setIsLiveItem] = useState(false);
      const [isHighlightedItem, setIsHighlightedItem] = useState(false);
      updateHighlightItem({ item, setIsHighlightedItem, setIsLiveItem });

      const dataInspect = `category-item-${item.id}`;
      return (
        <NavbarItem
          className={[styles.MenuItem, isHighlightedItem ? styles.MenuItemHighlighted : ''].join(' ')}
          key={item.id}
          data-inspect={dataInspect}
          data-id={hasSubmenu ? `nav-${index}` : null}
          onMouseEnter={hasSubmenu ? () => showSubmenu(menuMouseOver, `nav-${index}`) : () => closeSubmenu()}
          onClick={() => {
            NavigationHeaderTracking({ storeConfiguration, key: 'level_1', label: navbarTitle });
            closeSubmenu();
          }}
          aria-label={navbarTitle}
          aria-hidden="true"
        >
          {isUrlExternal ? (
            <a
              className={[styles.MenuLink, isLiveItem ? styles.live : ''].join(' ')}
              rel="noopener noreferrer nofollow"
              data-label={navbarTitle}
              aria-label={navbarTitle}
              href={navbarItemUrl}
            >
              <span>{navbarTitle}</span>
              {hasSubmenu ? (
                <span className={styles.MenuArrow}>
                  <span className={styles.MenuArrowBg} />
                </span>
              ) : null}
            </a>
          ) : (
            <Link
              className={[styles.MenuLink, isLiveItem ? styles.live : ''].join(' ')}
              data-label={navbarTitle}
              aria-label={navbarTitle}
              aria-hidden="true"
              to={navbarItemUrl}
            >
              <span>{navbarTitle}</span>
              {hasSubmenu ? (
                <span className={styles.MenuArrow}>
                  <span className={styles.MenuArrowBg} />
                </span>
              ) : null}
            </Link>
          )}
        </NavbarItem>
      );
    });
  }

  return data ? (
    <nav className={[styles.Nav, 'menu-wrapper'].join(' ')}>
      <ul
        className={styles.Menu}
        onMouseLeave={() => {
          clearTimeout(timeout);
          setMenuMouseOver(false);
        }}
        onMouseEnter={() => {
          timeout = setTimeout(() => setMenuMouseOver(true), 250);
        }}
      >
        <li className={styles.CloseCaptureUp} onMouseEnter={() => closeSubmenu()} />
        <li className={styles.CloseCaptureLeft} onMouseEnter={() => closeSubmenu()} />
        <li className={styles.MenuWrapper}>
          <div
            className={[styles.MenuScroll, 'menu-move'].join(' ')}
            style={{ transform: `translateX(${transformX})` }}
          >
            {menu}
          </div>
          {showArrows && (
            <>
              <Arrow
                left
                onClick={() => {
                  setTransformX(`0px`);
                  setNextClicked(false);
                }}
                disabled={!nextClicked}
              />

              <Arrow
                onClick={() => {
                  setTransformX(moveBy);
                  setNextClicked(true);
                }}
                disabled={nextClicked}
              />
            </>
          )}
        </li>
        <li className={styles.CloseCaptureRight} onMouseEnter={() => closeSubmenu()} />
      </ul>
      {data.map((item, index) => {
        const hasSubmenu = item?.type === MEGAMENU_SUBMENU && item?.elements?.length;
        return hasSubmenu ? (
          <div
            key={`submenu-${item.id}`}
            className="MenuSubmenu"
            onMouseLeave={() => closeSubmenu()}
            data-nav={hasSubmenu ? `nav-${index}` : null}
          >
            <MegaMenuSubmenu
              subMenuId={`nav-${index}`}
              subMenuClass={styles.Active}
              data={item}
              closeSubmenu={closeSubmenu}
            />
          </div>
        ) : null;
      })}
    </nav>
  ) : null;
});
