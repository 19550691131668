import React from 'react';
import { LanguageSwitcher } from '../../elements/LanguageSwitcher/LanguageSwitcher';
import Container from '../../layout/Container/Container';
import { PreHeaderStatic } from './PreHeaderStatic';
import styles from './PreHeader.module.scss';

export const PreHeader = () => (
  <div className={styles.PreHeader}>
    <Container>
      <PreHeaderStatic />
      <LanguageSwitcher />
    </Container>
  </div>
);
