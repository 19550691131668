import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { T } from '@deity/falcon-i18n';
import { Overlay } from '../../ui/Overlay/Overlay';
import { NavigationShopTracking } from '../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { SignIn } from './SignIn/SignIn';
import styles from './CustomerAccountIcon.module.scss';
import { AccountMenu } from './AccountMenu/AccountMenu';

export const CustomerAccountIcon = withStoreConfiguration(({ storeConfiguration, customer }) => (
  <Overlay
    overlaySide={customer ? 'left' : 'right'}
    overlayWidth={customer ? '240px' : '700px'}
    overlayYPosition="58px"
    overlayXPosition={customer ? '-40px' : '-194px'}
    overlayArrowXPosition={customer ? '65px' : '465px'}
    overlayDropdownContent={({ setIsOpen }) => {
      return <>{customer ? <AccountMenu /> : <SignIn setIsOpen={setIsOpen} />}</>;
    }}
  >
    {({ overlayDropdown, setIsOpen }) => {
      useEffect(() => {
        const overlayForgotPassword = document.getElementById('sign-in-overlay-forgot') || null;
        if (overlayForgotPassword) {
          overlayForgotPassword.addEventListener('click', function closeOverlay() {
            setIsOpen(false);
          });
        }
      }, []);

      const preventCloseOnAutofill = event => {
        return event.target.type === 'email' || event.target.type === 'password' ? setIsOpen(true) : setIsOpen(false);
      };

      let accountLabel = 'header.login';
      if (customer) {
        if (customer.firstname) {
          accountLabel = customer.firstname;
        } else {
          accountLabel = 'header.account';
        }
      }

      return (
        <div
          data-inspect="customer-account-button"
          className={styles.CustomerAccountWrapper}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={preventCloseOnAutofill}
          aria-hidden="true"
        >
          <div className={styles.CustomerAccountIcon}>
            <Link
              to={customer ? '/account' : '/sign-in'}
              aria-hidden="true"
              aria-label="Sign in"
              onClick={() => {
                NavigationShopTracking({ storeConfiguration, label: 'account' });
              }}
            >
              <span className={styles.CustomerAccountLabel}>
                <T id={accountLabel} />
              </span>
            </Link>
          </div>
          {overlayDropdown}
        </div>
      );
    }}
  </Overlay>
));
