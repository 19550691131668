import React from 'react';
import { T } from '@deity/falcon-i18n';
import NotFound from '../../../overrides/Errors/Error/NotFound.js';
import { CmsRichtext } from '../../Cms/Modules/Components/CmsRichText';
import loadable from '../../../../../../components/loadable';
import Container from '../../../layout/Container/Container';
import styles from './Details.module.scss';

const Row = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ '../../Cms/Row'));

export const MobileDetails = ({ content, active }) => {
  if (content.long_description !== null && active === 'description') {
    return (
      <>
        {content.long_description.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row key={`rowItem-'${index}'`} item={item.fields} contentType={item.sys.contentType.sys.id} />
        ))}
      </>
    );
  }

  if (content.directions !== null && active === 'direction') {
    return (
      <>
        {content.directions.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row key={`rowItem-'${index}'`} item={item.fields} contentType={item.sys.contentType.sys.id} />
        ))}
      </>
    );
  }

  if (content.ingredients !== null && active === 'ingredients') {
    return (
      <Container>
        <CmsRichtext text={content.ingredients} />
        <div className={styles.IngredientsNote}>
          <T id="cms.ingredients.note" />
        </div>
      </Container>
    );
  }

  return <NotFound location />;
};
