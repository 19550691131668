import React from 'react';
import { T } from '@deity/falcon-i18n';
import { Link as RouterLink } from 'react-router-dom';
import { ForgotPasswordPath } from './ForgotPasswordPath';

export const ForgotPasswordTrigger = () => (
  <RouterLink to={ForgotPasswordPath.forgotPassword.to} className="Link LinkPurple" id="sign-in-overlay-forgot">
    <T id="customerAccount.forgotPasswordQuestion" />
  </RouterLink>
);
