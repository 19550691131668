import nextId from 'react-id-generator';

export default (state, { type, section, data, id }) => {
  const currentState = { ...state };

  switch (type) {
    case 'ADD_TO_STATE':
      // Create array if not already exist
      if (!(section in currentState)) {
        currentState[section] = [];
      }

      // Generate unique id and add it to item that will be added
      data.uniqueId = nextId();

      // Add item to our global state under specified section
      currentState[section].push(data);

      // Return modified current state
      return currentState;
    case 'REMOVE_FROM_STATE':
      // Check if section exist and id is not null
      if (section in currentState && id !== null) {
        // Remove element by given unique id
        currentState[section] = currentState[section].filter(item => item.uniqueId !== id);
      }

      // Return modified current state
      return currentState;
    case 'UPDATE_IN_STATE':
      // Check if section exist and id is not null
      if (section in currentState) {
        // Update element by given unique id
        currentState[section] = currentState[section].map(item => (item.uniqueId !== data.uniqueId ? item : data));
      }

      // Return modified current state
      return currentState;
    default:
      return state;
  }
};
