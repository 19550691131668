import { PageviewTracking } from './Pageview';

export const SelectItemTracking = ({
  storeConfiguration,
  product,
  category,
  area,
  location,
  shopCountry,
  currentIndex = null
}) => {
  const listName = location ? `${shopCountry} - ${area} - ${location}` : category?.breadcrumbPath;
  const dataLayerData = {
    key: `select_item_${product.sku}`,
    event: 'Ecommerce - Select Item',
    event_name: 'select_item',
    select_item: {
      items: [
        {
          item_name: product.name, // e.g. 'Magic Finish Make-up Mousse'
          item_id: product.sku, // e.g. '123456789'
          price: product.price?.special || product.price?.minPrice || product.price?.regular, // e.g. '24.75'
          item_brand: `${product.brand} - ${product.cosmeticlineValue}`, // e.g. 'M. Asam - Magic Finish'
          item_category: product.categoryPath, // e.g. 'Make-up'
          item_category_2: product.applicationType, // e.g. 'Make-up Mousse'
          item_category_3: product.price.special ? 'on sale' : 'regular', // e.g. 'regular' | 'on sale' | 'free gift'
          item_category_4: product.volume, // e.g. '30ml'
          item_variant:
            product.options &&
            product.options[0] &&
            product.options[0].values &&
            product.options[0].values[0] &&
            product.options[0].values[0].label
              ? product.options[0].values[0].label
              : '', // e.g. 'Ivory'
          item_list_name: listName || '', // e.g. 'Skincare'
          item_list_id: '', // e.g. '987654321'
          index: parseInt(currentIndex, 10) + 1
        }
      ]
    }
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
