import React, { createContext, useContext, useEffect, useState } from 'react';

export const ConsentContext = createContext({ uc: {} });

export const useConsentContext = () => useContext(ConsentContext);

export const ConsentProvider = ({ children }) => {
  const [uc, setUC] = useState({});

  useEffect(() => {
    window?.addEventListener('ucEvent', event => {
      if (event.detail) {
        setUC({ consents: event.detail });
      }
    });
  });

  return <ConsentContext.Provider value={{ uc }}>{children}</ConsentContext.Provider>;
};
