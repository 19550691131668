export const BodyElementAttribute = ({ key, value, action }) => {
  const bodyElement = typeof window !== 'undefined' && document.getElementsByTagName('body')[0];

  if (bodyElement && key) {
    switch (action) {
      case 'get':
        return bodyElement.getAttribute(key);
      case 'set':
        bodyElement.setAttribute(key, value);
        break;
      case 'remove':
        bodyElement.removeAttribute(key);
        break;
      default:
        return null;
    }
  }

  return null;
};
