import React from 'react';
import { Link } from 'react-router-dom';
import { I18n } from '@deity/falcon-i18n';
import Cookies from 'js-cookie';
import { useGlobalStateValue } from '../../../../helpers/GlobalState/GlobalState';
import styles from './CmsPlaceholder.module.scss';
import CmsPlaceholderIcons from './CmsPlaceholderIcons';

const CmsPlaceholder = ({ setLoadPlaceholder, element }) => {
  const [, dispatch] = useGlobalStateValue();
  const PlaceholderCookieSet = () => {
    switch (element) {
      case 'map':
        Cookies.set('mapPlaceholder', true, { expires: 1 });
        setLoadPlaceholder(true);
        break;
      case 'youtube':
        Cookies.set('youtubePlaceholder', true, { expires: 1 });
        setLoadPlaceholder(true);
        dispatch({
          type: 'ADD_TO_STATE',
          section: 'youtubeCookie',
          data: {
            set: true
          }
        });
        break;
      default:
    }
  };

  return (
    <I18n>
      {t => {
        return (
          <div className={styles.CmsPlaceholder}>
            {element === 'map' ? (
              <>
                <p className={styles.CmsPlaceholderHeadLine}>{t('widget.placeholder.mapheadline')}</p>
                <p className={styles.CmsPlaceholderSubLine}>
                  {t('widget.placeholder.mapsubline')}
                  <Link to={`/${t('cms.dataprotection.link')}`}>{t('widget.placeholder.dataProtection')}</Link>.
                </p>
              </>
            ) : (
              <>
                <p className={styles.CmsPlaceholderHeadLine}>{t('widget.placeholder.youtubeheadline')}</p>
                <p className={styles.CmsPlaceholderSubLine}>
                  {t('widget.placeholder.youtubesubline')}
                  <Link to={`/${t('cms.dataprotection.link')}`}>{t('widget.placeholder.dataProtection')}</Link>.
                </p>
              </>
            )}
            {element === 'map' ? (
              <div className={styles.CmsPlaceholderIcons}>
                <CmsPlaceholderIcons />
              </div>
            ) : null}
            <div onClick={PlaceholderCookieSet} className={styles.CmsPlaceholderButton} role="button" tabIndex={0}>
              {t('widget.placeholder.button')}
            </div>
          </div>
        );
      }}
    </I18n>
  );
};

export default CmsPlaceholder;
