import { PageviewTracking } from '../Pageview';

export const NewsletterStepsTracking = ({ storeConfiguration, step, selectedValues }) => {
  delete selectedValues.firstname;
  delete selectedValues.lastname;
  delete selectedValues.newsletterId;

  const dataLayerData = {
    key: `newsletter_registration_${step}`,
    event: 'Generic Event',
    event_name: `newsletter_configuration_step_${step}`
  };

  dataLayerData[`newsletter_configuration_step_${step}`] = {
    configuration_selection: selectedValues,
    event_category: 'newsletter',
    event_action: 'configuration',
    event_label: `step ${step}`
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
