import { PageviewTracking } from './Pageview';

export const VideoTracking = ({
  storeConfiguration,
  event,
  currentProgressIndex,
  currentIndexIncrement,
  isAutoplayActive,
  videoUrl,
  videoTitle = ''
}) => {
  const progressPoints = [25, 50, 75, 95];
  let shouldPushEvent = false;
  if (typeof window !== 'undefined') {
    if (!window.dataLayer) return;
    let dataLayerData = {
      event: 'Generic Event'
    };

    const videoPercent = Math.floor((event.target.currentTime / event.target.duration) * 100);
    switch (event.type) {
      case 'play':
        if (!Number(event.target.currentTime.toFixed(2))) {
          dataLayerData = {
            ...dataLayerData,
            event_name: 'video_start',
            video_start: {
              video_autoplay: isAutoplayActive ? 'yes' : 'no',
              video_current_time: 0,
              video_duration: Math.floor(event.target.duration),
              video_percent: 0,
              video_url: videoUrl,
              video_title: videoTitle,
              video_provider: 'asambeauty'
            }
          };
          shouldPushEvent = true;
        }
        break;
      case 'timeupdate':
        if (videoPercent < 95 && videoPercent !== 0) {
          if (videoPercent >= progressPoints[currentProgressIndex]) {
            dataLayerData = {
              ...dataLayerData,
              event_name: 'video_progress',
              video_progress: {
                video_current_time: Math.floor(event.target.currentTime),
                video_duration: Math.floor(event.target.duration),
                video_percent: videoPercent,
                video_url: videoUrl,
                video_title: videoTitle,
                video_provider: 'asambeauty'
              }
            };
            shouldPushEvent = true;
            currentIndexIncrement();
          }
        }
        if (videoPercent >= 95) {
          dataLayerData = {
            ...dataLayerData,
            event_name: 'video_complete',
            video_complete: {
              video_current_time: Math.floor(event.target.currentTime),
              video_duration: Math.floor(event.target.duration),
              video_percent: videoPercent,
              video_url: videoUrl,
              video_title: videoTitle,
              video_provider: 'asambeauty'
            }
          };
          shouldPushEvent = videoPercent >= progressPoints[currentProgressIndex];
          currentIndexIncrement(true);
        }
        break;
      default:
        return;
    }
    if (shouldPushEvent) {
      PageviewTracking({ storeConfiguration, dataLayerData, bufferSeconds: 0 });
    }
  }
  return null;
};
