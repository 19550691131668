import { PageviewTracking } from './Pageview';

export const ProductReviewTracking = ({ storeConfiguration, product, rate, location = 'product detail page' }) => {
  const productCustomAttributes = product?.customAttributes ? JSON.parse(product.customAttributes) : {};
  const productBrand = product?.brand || product?.cosBrand || '';
  const productBrandLine = product?.cosmeticlineValue || productCustomAttributes?.cosmeticlineValue || '';
  const dataLayerData = {
    key: `product_review_click_${product.sku}`,
    event: 'Generic Event',
    event_name: 'product_review',
    product_review: {
      product_id: product.sku,
      product_name: product.name,
      product_brand: `${productBrand} - ${productBrandLine}`,
      product_rating: rate,
      product_review_location: location, // 'product detail page' or 'account'
      event_category: 'product review',
      event_action: product.sku,
      event_label: undefined
    }
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
