import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@deity/falcon-data';
import headerSvg from '../../../../../assets/images/sprites/headerSprite.svg';
import { GET_BACKEND_CONFIG } from '../../overrides/BackendConfig/BackendConfigQuery';
import { getCustomLogo } from './CustomLogo';

export const Logo = () => {
  const { data } = useQuery(GET_BACKEND_CONFIG);
  const storeCode = data ? data.backendConfig.activeLocale : 'de-DE';

  const customLogo = getCustomLogo(storeCode);

  if (customLogo) {
    return customLogo;
  }

  switch (storeCode) {
    case 'de-DE':
    case 'de-CH':
      return (
        <Link to="/" aria-label="asambeauty">
          <img src={`${headerSvg}#logo`} alt="asambeauty" width={240} height={60} />
        </Link>
      );
    case 'en-GB':
      return (
        <Link to="/" aria-label="asambeauty">
          <img src={`${headerSvg}#logoUs`} alt="asambeauty" className="us-logo" width={240} height={60} />
        </Link>
      );
    default:
      return (
        <Link to="/" aria-label="asambeauty">
          <img src={`${headerSvg}#logoNoClaim`} alt="asambeauty" className="logo-noclaim" width={240} height={50} />
        </Link>
      );
  }
};
