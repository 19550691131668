import React, { useEffect, useState } from 'react';
import { I18n } from '@deity/falcon-i18n';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import loadable from '../../../../../components/loadable';
import { CheckDays } from '../../helpers/DeliveryTime/CheckDays';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../ui/Modal/Modal'));

export const DeliveryTime = withStoreConfiguration(({ storeConfiguration, showAsLink = true }) => {
  const [date, setDate] = useState(new Date());
  const [showAgbOverlay, setShowAgbOverlay] = useState(false);
  useEffect(() => {
    const updateDeliveryTime = () => setDate(CheckDays(storeConfiguration));
    updateDeliveryTime();
  }, []);
  return (
    <I18n>
      {t => (
        <React.Fragment>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <span onClick={() => setShowAgbOverlay(showAsLink)} className={showAsLink ? 'Interactive Underline' : null}>
            {`${date.toISOString().split('T')[0]}`}
          </span>
          {showAgbOverlay ? (
            <Modal
              pageContent={t('cms.estimatedDelivery.link')}
              variant="tertiary"
              scrollableContent
              visible={showAgbOverlay}
              close={() => {
                setShowAgbOverlay(false);
              }}
            />
          ) : null}
        </React.Fragment>
      )}
    </I18n>
  );
});
