import gql from 'graphql-tag';
import { Query } from '@deity/falcon-data';

export const GET_STORE_CONFIGURATION = gql`
  query StoreConfiguration {
    storeConfiguration {
      configJson
    }
  }
`;

export class StoreConfigurationQuery extends Query {
  static defaultProps = {
    query: GET_STORE_CONFIGURATION
  };
}
