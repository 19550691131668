// Note: Importing an individual algorithm from jsSHA only results in smaller
// bundle size when you're using a single one. Switch the import to the full
// library build if a second algorithm is needed.
//
import jsSHA from 'jssha';

export const HashSha256 = string => {
  // eslint-disable-next-line new-cap
  const shaObj = new jsSHA('SHA-256', 'TEXT', { encoding: 'UTF8' });
  shaObj.update(string);
  return shaObj.getHash('HEX', { outputLen: 256 });
};

export const HashSha512 = string => {
  // eslint-disable-next-line new-cap
  const shaObj = new jsSHA('SHA-512', 'TEXT', { encoding: 'UTF8' });
  shaObj.update(string);
  return shaObj.getHash('HEX', { outputLen: 256 });
};
