import React, { useEffect, useState } from 'react';
import { I18n, T } from '@deity/falcon-i18n';
import { Price, RouterLink } from '@deity/falcon-ui-kit';
import { RemoveCartItemMutationExt } from '../../../overrides/Cart/RemoveCartItemMutationExt';
import { UpdateCartItemMutationExt } from '../../../overrides/Cart/UpdateCartItemMutationExt';
import { ChangeItemNumberInput } from '../../../pages/Cart/CartInputs/CartInputs';
import { Errors } from '../../Errors/Errors';
import { AddToWishlist } from '../../Wishlist/AddToWishlist/AddToWishlist';
import styles from '../MiniCart.module.scss';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { BasePrice } from '../../Product';
import productPlaceholder from '../../../../../../assets/images/product/placeholder.png';
import { ProductImageCart } from '../../Product/ProductImage/ProductImage';
import { Checkbox } from '../../../ui/Checkbox/Checkbox';
import { CartTracking, LastOrderUpdate } from '../../Tracking/GoogleTagManager';

export const MiniCartProduct = withStoreConfiguration(
  ({ storeConfiguration, product, currency, onAddedToWishlist, cartItems }) => {
    const isFreeItem = product.freeItem;
    const freeItemState = isFreeItem && product.isFreeItemActive !== null ? product.isFreeItemActive : true;
    const [checked, setChecked] = useState(freeItemState);
    const freeItemLabel = <I18n>{t => <span className="normal">{t('productTable.freeItemCheckbox')}</span>}</I18n>;
    const priceWithoutTax =
      storeConfiguration.asam_layoutconfiguration &&
      storeConfiguration.asam_layoutconfiguration.show_price &&
      parseInt(storeConfiguration.asam_layoutconfiguration.show_price.without_tax, 10);
    const maxQtyAllowedInCart = storeConfiguration?.cataloginventory?.item_options?.max_sale_qty;

    const price = priceWithoutTax ? product.price : product.priceInclTax;
    // verify if there's a discount to show
    const newPrice = product.discountAmount > 0 ? price - product.discountAmount / product.qty : 0;
    // load original price for products with promotional price
    const itemOriginalPrice = product.originalPrice > price ? product.originalPrice : 0;
    // if there's a coupon and price with discount, we show the original price
    const hideFinalPrice = itemOriginalPrice > 0 && product.discountAmount > 0;

    useEffect(() => {
      setChecked(freeItemState);
    }, [freeItemState]);

    return (
      <I18n>
        {t => (
          <div className={styles.MiniCartItem}>
            <div className={[styles.MiniCartItemImage, isFreeItem && !checked ? styles.DisabledInfo : ''].join(' ')}>
              {product.urlKey ? (
                <RouterLink to={product.urlKey}>
                  <ProductImageCart product={product} alternativeImage={productPlaceholder} width={64} height={64} />
                </RouterLink>
              ) : (
                <ProductImageCart product={product} alternativeImage={productPlaceholder} width={64} height={64} />
              )}
            </div>
            <div className={[styles.MiniCartItemInfo, isFreeItem && !checked ? styles.DisabledInfo : ''].join(' ')}>
              {product.urlKey ? (
                <RouterLink className={styles.MiniCartItemName} to={product.urlKey}>
                  {product.name}
                </RouterLink>
              ) : (
                <>{product.name}</>
              )}
              <div className={styles.MiniCartItemAdditional}>
                {product.cosContent ? `${t('product.content')}: ${product.cosContent}` : null}
                {product.normPrice && <BasePrice info={product.normPrice} />}
              </div>
              {!isFreeItem ? (
                <>
                  <div className={styles.MiniCartItemActions}>
                    <RemoveCartItemMutationExt>
                      {removeCartItem => (
                        <a
                          className="Link LinkPurple"
                          onClick={() => {
                            const updatedCartItems = [...cartItems]?.filter(item => item.itemId !== product?.itemId);
                            CartTracking({
                              storeConfiguration,
                              product,
                              action: 'remove',
                              origin: 'mini cart',
                              quantity: product.qty
                            });

                            LastOrderUpdate({
                              storeConfiguration,
                              cart: { items: [...updatedCartItems] },
                              product,
                              action: 'remove'
                            });
                            return removeCartItem({
                              variables: { input: { itemId: product.itemId } },
                              optimisticResponse: {
                                removeCartItem: { __typename: 'RemoveCartItemPayload', itemId: product.itemId }
                              }
                            });
                          }}
                          aria-hidden
                        >
                          <T id="miniCart.removeProduct" />
                        </a>
                      )}
                    </RemoveCartItemMutationExt>
                    {product.itemOptions.length === 0 && (
                      <React.Fragment>
                        <span className="Divider DividerVertical" />
                        <AddToWishlist
                          productId={product.entityId}
                          variant="quaternary"
                          onSuccess={onAddedToWishlist}
                          origin="mini cart"
                          product={product}
                        />
                      </React.Fragment>
                    )}
                  </div>
                </>
              ) : null}
            </div>
            <UpdateCartItemMutationExt>
              {(updateCartItem, { loading, error }) => (
                <div className={[styles.MiniCartQty, isFreeItem ? styles.WidthAuto : ''].join(' ')}>
                  {!isFreeItem ? (
                    <>
                      <p>
                        <T id="miniCart.amount" />
                      </p>
                      <ChangeItemNumberInput
                        loading={loading}
                        max={maxQtyAllowedInCart}
                        id={`product${product.id}`}
                        defaultValue={product.qty}
                        onChange={ev => {
                          const qty = parseInt(ev.target.value, 10);
                          const updatedCartItems = [...cartItems]?.map(item => {
                            if (item.itemId === product.itemId) {
                              return {
                                ...item,
                                qty
                              };
                            }
                            return item;
                          });
                          if (!Number.isNaN(qty)) {
                            CartTracking({
                              storeConfiguration,
                              product,
                              action: product.qty > qty ? 'remove' : 'add',
                              origin: 'mini cart',
                              quantity: Math.abs(product.qty - qty),
                              newQuantity: qty
                            });

                            LastOrderUpdate({
                              storeConfiguration,
                              cart: { items: [...updatedCartItems] },
                              product,
                              action: product.qty > qty ? 'remove' : 'add'
                            });
                            updateCartItem({
                              variables: {
                                input: {
                                  itemId: product.itemId,
                                  sku: product.sku,
                                  qty
                                }
                              }
                            });
                          }
                        }}
                      />
                    </>
                  ) : (
                    <div className={styles.FreeItemCheckboxHolder}>
                      <Checkbox
                        loading={loading.toString()}
                        id={`freeitemstatus-minicart_${product.itemId}`}
                        name={`freeitemstatus-${product.itemId}`}
                        buttonOnTop
                        type="checkbox"
                        checked={checked}
                        label={freeItemLabel}
                        onChange={() => {
                          setChecked(!checked);
                          const qty = 1;
                          const isFreeItemActive = !checked;
                          updateCartItem({
                            variables: {
                              input: {
                                itemId: product.itemId,
                                sku: product.sku,
                                qty,
                                isFreeItemActive
                              }
                            }
                          });
                        }}
                      />
                    </div>
                  )}

                  {!!error && <Errors error={error.graphQLErrors} />}
                </div>
              )}
            </UpdateCartItemMutationExt>
            {!isFreeItem ? (
              <div className={styles.priceColumn}>
                {itemOriginalPrice > 0 ? (
                  <>
                    <span className={styles.priceUvp}>
                      <T id="product.retailPrice" />
                    </span>
                    <Price className={styles.discountOldPrice} currency={currency} value={itemOriginalPrice} />
                  </>
                ) : null}
                {!hideFinalPrice ? (
                  <>
                    {newPrice > 0 || product.discountAmount ? (
                      <span className={styles.priceUvp}>
                        <T id="product.retailPrice" />
                      </span>
                    ) : null}
                    <Price
                      className={[
                        styles.MiniCartItemPrice,
                        newPrice > 0 || product.discountAmount ? styles.discountOldPrice : null,
                        itemOriginalPrice > 0 && newPrice === 0 ? styles.discountNewPrice : null
                      ].join(' ')}
                      currency={currency}
                      value={price}
                    />
                  </>
                ) : null}
                {newPrice > 0 || product.discountAmount ? (
                  <Price value={newPrice} className={[styles.MiniCartItemPrice, styles.discountNewPrice].join(' ')} />
                ) : null}
              </div>
            ) : null}
          </div>
        )}
      </I18n>
    );
  }
);
