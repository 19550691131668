import React from 'react';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './BasePrice.module.scss';

export const BasePrice = withStoreConfiguration(({ storeConfiguration, info, wrapped = true }) => {
  const storeBasePrice = storeConfiguration?.asam_productbaseprice?.show_baseprice?.active === '1' || false;
  /* Exception case for WishlistItemRenderer.js */
  const additionalClass = wrapped ? [styles.BasePriceHolder] : [styles.BasePriceHolder, styles.NoWrapped];
  const extendInfo = wrapped ? info : `${info}, `;

  return storeBasePrice ? <span className={additionalClass.join(' ')}>{extendInfo}</span> : null;
});
