import React from 'react';
import { withRouter } from 'react-router-dom';
import { T } from '@deity/falcon-i18n';
import PropTypes from 'prop-types';
import { CustomerQuery } from '../../../overrides/Customer/CustomerQuery';
import { SetAddProductToWishlistMutation, SetRemoveProductFromWishlistMutation } from '../WishlistMutation';
import { ProductsInWishlistQuery } from '../WishlistQuery';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { useGlobalStateValue } from '../../../helpers/GlobalState/GlobalState';
import { WishlistTracking } from '../../Tracking/GoogleTagManager';
import styles from './AddToWishlist.module.scss';

export const AddToWishlist = withRouter(
  withStoreConfiguration(({ storeConfiguration, variant, onSuccess, productId, history, origin, product }) => {
    const [, dispatch] = useGlobalStateValue();
    let additionalClass = null;

    if (variant === 'secondary') {
      additionalClass = styles.Secondary;
    }

    if (variant === 'tertiary') {
      additionalClass = styles.Tertiary;
    }

    if (variant === 'quaternary') {
      additionalClass = styles.Secondary;
    }

    const onError = () => {
      window.scrollBy(0, -10000);
      dispatch({
        type: 'ADD_TO_STATE',
        section: 'messages',
        data: {
          content: <T id="notifications.errorWishlist" />,
          type: 'error',
          animate: false
        }
      });
    };

    const showNotification = () => {
      window.scrollBy(0, -10000);
      dispatch({
        type: 'ADD_TO_STATE',
        section: 'messages',
        data: {
          content: <T id="notifications.signInBefore" />,
          type: 'info',
          animate: false
        }
      });
    };

    const loginFirstHandle = () => {
      const search = `?next=${history.location.pathname}${history.location.search}&toWishlist=${productId}`;
      history.push({
        pathname: '/sign-in',
        search
      });
      showNotification();
    };

    const addToWishlistHandle = (mutation, customer) =>
      !customer
        ? loginFirstHandle()
        : mutation({ variables: { productId, customerId: customer.id } }).then(
            ({ data: { setAddProductToWishlist } }) => {
              if ('success' in setAddProductToWishlist && setAddProductToWishlist.success) {
                onSuccess();
                WishlistTracking({
                  storeConfiguration,
                  action: 'add',
                  origin,
                  product
                });
              } else {
                onError();
              }
            }
          );

    const removeFromWishlistHandle = (mutation, customer) =>
      !customer
        ? loginFirstHandle()
        : mutation({ variables: { productId, customerId: customer.id } }).then(
            ({ data: { setRemoveProductFromWishlist } }) => {
              if (!('success' in setRemoveProductFromWishlist && setRemoveProductFromWishlist.success)) {
                onError();
              } else {
                WishlistTracking({
                  storeConfiguration,
                  action: 'remove',
                  origin,
                  product
                });
              }
            }
          );

    let addToWishlistButton = <span className="before-icon-heart" />;
    let removeFromWishlistButton = <span className="before-icon-heart-full" />;

    if (variant === 'secondary') {
      addToWishlistButton = (
        <span className="Link LinkPurple LinkWithIcon LinkWithUnderline LinkLarge before-icon-heart">
          <T id="product.addToWishlist" />
        </span>
      );
      removeFromWishlistButton = (
        <span className="Link LinkPurple LinkWithIcon LinkWithUnderline LinkLarge before-icon-heart-full">
          <T id="product.removeFromWishlist" />
        </span>
      );
    }

    if (variant === 'tertiary') {
      removeFromWishlistButton = (
        <span className="Link LinkPurple LinkWithUnderline LinkLarge">
          <T id="product.removeFromWishlistShort" />
        </span>
      );
    }

    if (variant === 'quaternary') {
      addToWishlistButton = (
        <span className="Link LinkPurple">
          <T id="product.addToWishlist" />
        </span>
      );
      removeFromWishlistButton = (
        <span className="Link LinkPurple">
          <T id="product.removeFromWishlist" />
        </span>
      );
    }

    return (
      <CustomerQuery passLoading>
        {({ data: { customer } }) => {
          return customer ? (
            <ProductsInWishlistQuery>
              {({ data: { productsInWishlist } }) => {
                const inWishlistIds = productsInWishlist ? productsInWishlist.map(item => item.itemId) : [];
                const isInWishlist = inWishlistIds.includes(productId);

                return (
                  <SetAddProductToWishlistMutation onError={onError}>
                    {(setAddProductToWishlist, addOptions) => (
                      <SetRemoveProductFromWishlistMutation>
                        {(setRemoveProductFromWishlist, removeOptions) => (
                          <div
                            className={[styles.AddToWishlist, 'AddToWishlist', additionalClass].join(' ')}
                            onClick={() =>
                              isInWishlist
                                ? removeFromWishlistHandle(setRemoveProductFromWishlist, customer)
                                : addToWishlistHandle(setAddProductToWishlist, customer)
                            }
                            style={addOptions.loading || removeOptions.loading ? { opacity: 0.4 } : null}
                            aria-hidden
                          >
                            {!isInWishlist ? addToWishlistButton : null}
                            {isInWishlist ? removeFromWishlistButton : null}
                          </div>
                        )}
                      </SetRemoveProductFromWishlistMutation>
                    )}
                  </SetAddProductToWishlistMutation>
                );
              }}
            </ProductsInWishlistQuery>
          ) : (
            <div
              className={[styles.AddToWishlist, 'AddToWishlist', additionalClass].join(' ')}
              onClick={() => {
                loginFirstHandle();
              }}
              aria-hidden
            >
              {addToWishlistButton}
            </div>
          );
        }}
      </CustomerQuery>
    );
  })
);

AddToWishlist.defaultProps = {
  variant: 'primary'
};

AddToWishlist.propTypes = {
  /** Decide to show/hide additional text near icon */
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary']),
  /** Id of product that should be added to wishlist */
  productId: PropTypes.string,
  /** Function fired up when product is added to wishlist */
  onSuccess: PropTypes.func,
  /** Array of products ids that are in wishlist */
  inWishlistIds: PropTypes.arrayOf(PropTypes.string)
};
