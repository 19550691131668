import gql from 'graphql-tag';
import { Query } from '@deity/falcon-data';

export const GET_LOADER_STATE = gql`
  query Loader {
    loader @client {
      isVisible
    }
  }
`;

export class LoaderQuery extends Query {
  static defaultProps = {
    query: GET_LOADER_STATE
  };
}
