import { T } from '@deity/falcon-i18n';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../ui/Button/Button';
import loadable from '../../../../../../components/loadable';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../../ui/Modal/Modal'));

const getPopupContent = toggle => (
  <div>
    <div className="title">
      <T id="wishlist.addToWishlistTitlePopup" />
    </div>
    <Button onClick={() => toggle()}>
      <T id="wishlist.addToWishlistButtonPopup" />
    </Button>
    <Link to="/account/wishlist" className="Link LinkPurple LinkLarge">
      <T id="wishlist.toWishlistLinkPopup" />
    </Link>
  </div>
);

export const AddedToWishlistPopup = ({ on, toggle }) => (
  <Modal variant="secondary" content={getPopupContent(toggle)} close={toggle} visible={on} autohide />
);
