import storesConfig from '../../../../../storesConfig';

export default currentUrl => {
  const storeViewsConfig = storesConfig();
  const defaultConfig = storesConfig().storeViewsMap.find(item => item.storeView === storesConfig().defaultStoreView);
  let lang = { language: defaultConfig.language };

  storeViewsConfig.storeViewsMap.forEach(item => {
    if (currentUrl === `/${item.suffix}` || currentUrl.indexOf(`/${item.suffix}/`) === 0) {
      lang = { language: item.language };
    }
  });
  return lang;
};
