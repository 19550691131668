import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Mutation } from '@deity/falcon-data';

export const ADD_TO_CART = gql`
  mutation AddToCart($input: AddToCartInput!) {
    addToCart(input: $input) {
      itemId
      sku
      qty
      name
      price
      productType
    }
  }
`;

export class AddToCartMutation extends Mutation {
  static defaultProps = {
    mutation: ADD_TO_CART,
    refetchQueries: ['MiniCart', 'Cart', 'FreeShipping', 'InitPaypalExpress']
  };
}

export const useAddToCartMutation = (options = {}) =>
  useMutation(ADD_TO_CART, {
    refetchQueries: ['MiniCart', 'Cart', 'FreeShipping', 'InitPaypalExpress'],
    ...options
  });
