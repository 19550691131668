import gql from 'graphql-tag';
import { Query } from '@deity/falcon-data';

export const GET_FACEBOOK_LINK = gql`
  query GetFacebookLink {
    getFacebookLink {
      buttonLink
      redirectLink
    }
  }
`;

export class GetFacebookLinkQuery extends Query {
  static defaultProps = {
    passLoading: true,
    query: GET_FACEBOOK_LINK
  };
}
