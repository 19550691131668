import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from '@deity/falcon-data';

export const GET_URL = gql`
  query Url($path: String!) {
    url(path: $path) {
      type
      redirect
      id
      path
      targetPath
    }
  }
`;

export class UrlQuery extends Query {
  static defaultProps = {
    query: GET_URL
  };

  static propTypes = {
    ...Query.propTypes,
    variables: PropTypes.shape({
      path: PropTypes.string.isRequired
    })
  };
}
