import storeConfig from 'src/storesConfig';

const getActiveStoreConfig = () => {
  const storeViewsConfig = storeConfig();
  const { pathname } = window.location;
  return storeViewsConfig.storeViewsMap.find(
    item => pathname === `/${item.suffix}` || pathname.indexOf(`/${item.suffix}/`) === 0
  );
};

export const getActiveStoreCode = () => {
  const activeStoreConfig = getActiveStoreConfig() || {};
  return activeStoreConfig.storeView || storeConfig().defaultStoreView;
};

export const getActiveStoreSuffix = () => {
  const activeStoreConfig = getActiveStoreConfig() || {};
  return activeStoreConfig.suffix || storeConfig().defaultSuffix;
};
