import React from 'react';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import { Store } from '../Store/Store';

export const COOKIE_PAYBACK_SCRID = 'asam_payback_scrid';

export const PaybackScridReferrer = withRouter(({ location, history }) => {
  if (typeof window !== 'undefined') {
    return (
      <Store>
        {({ getStoreConfigFlag }) => {
          const searchParams = new URLSearchParams(location.search || '');
          const scrid = searchParams.get('scrid');
          if (scrid) {
            if (scrid !== Cookies.get(COOKIE_PAYBACK_SCRID) && getStoreConfigFlag('payback.general.enabled')) {
              // ToDo: set maybe an expires
              Cookies.set(COOKIE_PAYBACK_SCRID, scrid);
            }
            searchParams.delete('scrid');
            history.replace({ search: searchParams.toString() });
          }
          return null;
        }}
      </Store>
    );
  }

  return null;
});

export const getPaybackScrid = () => {
  return Cookies.get(COOKIE_PAYBACK_SCRID);
};

export const removePaybackScrid = () => {
  Cookies.remove(COOKIE_PAYBACK_SCRID);
};
