import { I18n, T } from '@deity/falcon-i18n';
import { Locale } from '@deity/falcon-front-kit';
import React from 'react';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { Tooltip } from '../../ui/Tooltip/Tooltip';
import styles from './ShippingCostTooltip.module.scss';

export const ShippingCostTooltip = withStoreConfiguration(({ label, storeConfiguration }) => {
  const tooltipContent = (
    <div className={styles.ShippingCostTooltipContent}>
      <div className="title">
        <T id="shippingCostsTooltip.title" />
      </div>
      <ul className="list">
        <Locale>
          {localeContext => {
            const price = new Intl.NumberFormat(localeContext.locale, {
              style: 'currency',
              currency: localeContext.currency,
              minimumFractionDigits: 0
            }).format(storeConfiguration?.asam_shippinginformation?.general?.free_shipping_amount);
            const minDelivery = storeConfiguration?.asam_shippinginformation?.general?.min_delivery;
            const maxDelivery = storeConfiguration?.asam_shippinginformation?.general?.max_delivery;
            return (
              <I18n>
                {t =>
                  []
                    .concat(
                      t('shippingCostsTooltip.items', {
                        returnObjects: true,
                        freeShippingAmount: price,
                        minDelivery,
                        maxDelivery
                      })
                    )
                    .map((x, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li className="before-icon-check" key={`tooltip-${index}`}>
                        {x}
                      </li>
                    ))
                }
              </I18n>
            );
          }}
        </Locale>
      </ul>
    </div>
  );

  return <Tooltip content={tooltipContent} label={label} id="shippingCostTooltip" placement="bottom-end" />;
});
