import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { I18n } from '@deity/falcon-i18n';
import { ProductSlider } from '../ProductSlider/ProductSlider';
import { ProductListBySkusQuery } from '../../Odoscope/ProductListBySkusQuery';
import {
  checkAndUpdateProducts,
  getFromStorage,
  getFromStorageByUser,
  prepareNewUser,
  saveToStorage
} from './LastSeenProductsProvider';

const isSSR = typeof window === 'undefined';

export const LastSeenProducts = ({ currentProductId, customer: user, setShow }) => {
  useEffect(() => {
    if (isSSR) {
      return;
    }

    const userId = user === null ? null : user.id;
    const lastSeenProducts = getFromStorage();
    let lastSeenData = [];

    if (lastSeenProducts === null) {
      prepareNewUser(userId, currentProductId, lastSeenData);
    } else {
      if (!checkAndUpdateProducts(userId, currentProductId, lastSeenProducts.lastSeenProducts)) {
        prepareNewUser(userId, currentProductId, lastSeenProducts.lastSeenProducts);
      }

      lastSeenData = lastSeenProducts.lastSeenProducts;
    }

    if (lastSeenData.length > 0) {
      saveToStorage(lastSeenData);
    }
  }, []);

  const lastSeenProducts = isSSR ? null : getFromStorageByUser(user === null ? null : user.id);
  return lastSeenProducts === null ? null : (
    <I18n>
      {t => (
        <ProductListBySkusQuery
          variables={{
            skus: lastSeenProducts
          }}
        >
          {({ data }) => {
            const productsList = data.productListBySkus;
            if (productsList?.items?.length < 4 && setShow) {
              setShow(false);
            }
            return productsList && productsList.items.length ? (
              <ProductSlider
                title={t('product.lastSeenSliderTitle')}
                products={productsList.items}
                autoHeight
                location="last_seen_products"
                source="LastSeenProducts"
              />
            ) : null;
          }}
        </ProductListBySkusQuery>
      )}
    </I18n>
  );
};

LastSeenProducts.propTypes = {
  currentProductId: PropTypes.string.isRequired
};
