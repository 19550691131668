import React, { useState } from 'react';
import styles from './Tooltip.module.scss';

export const Tooltip = ({ id, content, displayRelative, placement }) => {
  const [visible, setVisible] = useState(false);

  const showToolTip = () => {
    setVisible(true);
  };

  const hideToolTip = () => {
    setVisible(false);
  };

  return (
    <>
      <div
        className={[styles.Tooltip, displayRelative ? styles.TooltipRelative : null].join(' ')}
        onMouseEnter={showToolTip}
        onMouseLeave={hideToolTip}
      >
        <div className={[styles.TooltipTrigger, 'icon-alert-circle'].join(' ')} id={id} />
      </div>
      <div>
        {visible && (
          <div className={styles.TooltipBox} data-placement={placement}>
            <div className={styles.TooltipContent} onMouseLeave={() => setVisible(false)}>
              <div
                className={[styles.TooltipClose, 'ShowTablet', 'icon-x'].join(' ')}
                onClick={() => setVisible(false)}
                aria-hidden
              />
              {content}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
