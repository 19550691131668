const storeConfig = {
  defaultStoreView: 'asambeauty_eur_de',
  defaultSuffix: '',
  defaultLanguage: 'de-DE',
  storeViewsMap: [
    // {
    //   storeView: 'asambeauty_gbp_gb',
    //   suffix: 'uk',
    //   language: 'en-GB'
    // },
    {
      storeView: 'asambeauty_eur_de',
      suffix: '',
      language: 'de-DE'
    },
    // {
    //   storeView: 'asambeauty_eur_int',
    //   suffix: 'en',
    //   language: 'en-US'
    // },
    {
      storeView: 'asambeauty_chf_ch',
      suffix: 'de_ch',
      language: 'de-CH'
    },
    {
      storeView: 'asambeauty_chf_fr',
      suffix: 'fr_ch',
      language: 'fr-CH'
    },
    {
      storeView: 'asambeauty_fra_fr',
      suffix: 'fr',
      language: 'fr-FR'
    }
  ]
};

export default () => {
  return storeConfig;
};

export const getStoreConfigByUrl = url => {
  return storeConfig.storeViewsMap.find(item => url === `/${item.suffix}` || url.indexOf(`/${item.suffix}/`) === 0);
};

export const getStoreViewCodeByUrl = url => {
  const storeConfigItem = getStoreConfigByUrl(url);
  return storeConfigItem?.storeView || storeConfig?.defaultStoreView;
};

export const getLanguageByUrl = url => {
  const storeConfigItem = getStoreConfigByUrl(url);
  return storeConfigItem?.language || storeConfig?.defaultLanguage;
};

export const getStoreViewByCode = code => storeConfig.storeViewsMap.find(({ storeView }) => storeView === code);

export const supportedStoreCodes = storeConfig.storeViewsMap.map(({ storeView }) => storeView);

/**
 * storeCodeMap can be used as import and enum
 *
 * import { storeCodeMap } from '../Store';
 *
 * console.log(storeCodeMap.store.eur_de); // 'asambeauty_eur_de'
 * console.log(storeCodeMap.stores.chf); // ['asambeauty_chf_ch', 'asambeauty_chf_fr']
 */
export const storeCodeMap = supportedStoreCodes.reduce(
  (result, storeCode) => {
    const storeIdent = storeCode.replace('asambeauty_', '');
    const websiteIdent = storeIdent.split('_')[0];
    result.all.push(storeCode);
    result.store[storeIdent] = storeCode;
    result.website[websiteIdent] = [...(result.website[websiteIdent] || []), storeCode];
    return result;
  },
  { all: [], store: {}, website: {} }
);
