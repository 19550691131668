import React from 'react';
import { Value } from 'react-powerplug';
import PropTypes from 'prop-types';
import styles from './Multiselect.module.scss';

export const Multiselect = ({
  headers,
  contents,
  scrollToHead,
  onlyMobile,
  groupClass,
  removePaddingOnMobile,
  openedTabIndex,
  iconVariant,
  titleVariant,
  blockContentEvents
}) => {
  if (!headers || !contents) {
    return null;
  }

  const scrollUp = index => {
    document?.querySelectorAll(`[data-scroll=Scroll-${index}]`)?.[0]?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Value initial={openedTabIndex ? `multiselect${openedTabIndex}` : null}>
      {({ value, set }) =>
        headers.map((item, index) => {
          const key = `multiselect${index}`;
          const activeClass = value === key ? styles.Active : null;
          const onlyMobileClass = onlyMobile ? styles.OnlyMobile : null;
          const removePaddingOnMobileClass = removePaddingOnMobile ? styles.RemovePaddingOnMobile : null;
          let titleVariantClass = null;

          if (item === null) {
            return null;
          }

          if (titleVariant === 'secondary') {
            titleVariantClass = styles.MultiselectTitleSecondary;
          }

          let arrow = <i className={[styles.MultiselectArrowIcon, 'after-icon-chevron-down'].join(' ')} />;
          if (iconVariant === 'secondary') {
            arrow =
              value === key ? (
                <i className={[styles.MultiselectMinusIcon, 'after-icon-minus'].join(' ')} />
              ) : (
                <i className={[styles.MultiselectPlusIcon, 'after-icon-plus'].join(' ')} />
              );
          }
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div
              className={[
                styles.MultiselectGroup,
                groupClass,
                onlyMobileClass,
                activeClass,
                removePaddingOnMobileClass
              ].join(' ')}
              key={key}
            >
              <div
                className={styles.MultiselectHeader}
                data-opt={`Multiselect-${index}`}
                data-scroll={scrollToHead ? `Scroll-${index}` : null}
                onClick={() => [
                  value === key ? set(null) : set(key),
                  scrollToHead && value !== key
                    ? // eslint-disable-next-line func-names
                      setTimeout(function() {
                        scrollUp(index);
                      }, 420)
                    : null
                ]}
                aria-hidden
              >
                <div className={styles.MultiselectArrow}>{arrow}</div>
                <div className={[styles.MultiselectGroupTitle, titleVariantClass].join(' ')}>{item}</div>
              </div>
              <div
                className={[styles.MultiselectContent, blockContentEvents ? styles.BlockContentEvents : null].join(' ')}
              >
                <div className={styles.MultiselectGroupContent}>{contents[index]}</div>
              </div>
            </div>
          );
        })
      }
    </Value>
  );
};

Multiselect.propTypes = {
  /** Array of titles displayed in multiselect bar */
  headers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
  /** Array of contents displayed in multiselect */
  contents: PropTypes.arrayOf(PropTypes.node),
  /** Display multiselect only on mobile */
  onlyMobile: PropTypes.bool,
  /** Remove left/right paddings only on mobile */
  removePaddingOnMobile: PropTypes.bool,
  /** Index of initially visible multiselect tab */
  openedTabIndex: PropTypes.string,
  /** Different icon variant */
  iconVariant: PropTypes.string,
  /** Different title variant */
  titleVariant: PropTypes.string
};
