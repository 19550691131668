import { PageviewTracking } from './Pageview';

export const CouponTracking = ({ storeConfiguration, couponCode = null, errorMessage = null }) => {
  let dataLayerData;
  let bufferSeconds = 30;

  if (errorMessage) {
    dataLayerData = {
      key: `coupon_error_${errorMessage}`,
      event: 'Generic Event',
      event_name: 'coupon_fail',
      coupon_fail: {
        error_message: errorMessage, // e.g. 'The coupon code Test does not exist.'

        event_category: 'cart',
        event_action: 'coupon',
        event_label: 'fail'
      }
    };
  } else {
    bufferSeconds = 1000;
    dataLayerData = {
      key: `coupon_success_${couponCode}`,
      event: 'Generic Event',
      event_name: 'coupon_success',
      coupon_success: {
        event_category: 'cart',
        event_action: 'coupon',
        event_label: 'success'
      }
    };
  }

  return PageviewTracking({ storeConfiguration, dataLayerData, bufferSeconds });
};
