import { PageviewTracking } from './Pageview';

export const SocialClickTracking = ({ storeConfiguration, network, url }) => {
  const dataLayerData = {
    key: `social_click_${network}_${url}`,
    event: 'Generic Event',
    event_name: 'social_click',
    social_click: {
      social_network: network,

      event_category: 'social click',
      event_action: url,
      event_label: undefined
    }
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
