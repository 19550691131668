import { PageviewTracking } from './Pageview';

export const ProductVariantTracking = ({ storeConfiguration, variant }) => {
  let dataLayerData;

  if (variant) {
    dataLayerData = {
      key: `product_variant_click_${variant.label}`,
      event: 'Generic Event',
      event_name: 'product_variant_click',
      product_variant_click: {
        event_category: 'product_variant',
        event_action: 'click',
        event_label: variant.label
      }
    };
  }

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
