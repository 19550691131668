import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem } from '@deity/falcon-ui';
import styles from '../MegaMenuSubmenu.module.scss';
import withStoreConfiguration from '../../../../helpers/StoreConfiguration/StoreConfiguration';
import { NavigationHeaderTracking } from '../../../../elements/Tracking/GoogleTagManager';

export const Category = withStoreConfiguration(
  ({ storeConfiguration, name, links, subMenuClass, subMenuId, parent }) => {
    function closeSubmenu() {
      const navItem = document.querySelector(`[data-nav=${subMenuId}]`);
      navItem.classList.remove(subMenuClass);
    }

    return (
      <div className={styles.MenuDrawerColumn}>
        <div className={styles.MenuTitle}>{name}</div>
        {links?.length ? (
          <List className={styles.MenuDrawerLinks}>
            {links.map(link => {
              return (
                <ListItem key={link?.category_id}>
                  <Link
                    to={`/${link.url}`}
                    onClick={() => {
                      NavigationHeaderTracking({
                        storeConfiguration,
                        key: 'level_3',
                        label: `${parent.title} - ${name} - ${link.name}`
                      });
                      closeSubmenu();
                    }}
                  >
                    {link?.name}
                  </Link>
                </ListItem>
              );
            })}
          </List>
        ) : null}
      </div>
    );
  }
);
