import React from 'react';
import { Link } from 'react-router-dom';
import { T } from '@deity/falcon-i18n';
import { CustomerQuery } from '../../overrides/Customer/CustomerQuery';
import { NavigationShopTracking } from '../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './WishlistIcon.module.scss';

export const WishlistIcon = withStoreConfiguration(({ storeConfiguration }) => (
  <div className={styles.WishlistIconWrapper}>
    <div className={styles.WishlistIcon}>
      <CustomerQuery passLoading>
        {({ data: { customer } }) => (
          <Link
            to={customer ? '/account/wishlist' : '/sign-in'}
            onClick={() => {
              NavigationShopTracking({ storeConfiguration, label: 'wishlist' });
            }}
          >
            <span className={styles.WishlistLabel}>
              <T id="header.wishlist" />
            </span>
          </Link>
        )}
      </CustomerQuery>
    </div>
  </div>
));
