import React from 'react';
import { StoreConfigurationQuery } from './StoreConfigurationQuery';

// ITW-6136: Provided for compatibility and for use in .../elements/Store/StoreProvider.js.
// Prefer useStoreContext from StoreProvider, see ./StoreConfiguration.js
export default Component => {
  return props => (
    <StoreConfigurationQuery passLoading>
      {({ data: { storeConfiguration } }) => {
        return storeConfiguration ? <Component {...props} storeConfiguration={storeConfiguration.configJson} /> : null;
      }}
    </StoreConfigurationQuery>
  );
};
