import React, { useState } from 'react';
import { T } from '@deity/falcon-i18n';
import { MegaMenuQuery } from '../MegaMenuQuery';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import headerSvg from '../../../../../../assets/images/sprites/headerSprite.svg';
import { OutboundLink } from '../../../elements/Tracking/GoogleTagManager';
import svgSprite from '../../../../../../assets/images/sprites/sprites.svg';
import { MobileMenuRootLevel } from './MobileMenuRootLevel';
import { MobileMenuFirstLevel } from './MobileMenuFirstLevel';
import { MobileMenuSecondLevel } from './MobileMenuSecondLevel';
import { MobileMenuAdditional } from './MobileMenuAdditional';
import { MobileMenuService } from './MobileMenuService';
import { MobileMenuStore } from './MobileMenuStore';
import { MobileMenuTrigger } from './MobileMenuTrigger';
import {
  MOBILE_MENU_ROOT,
  MOBILE_MENU_FIRST,
  MOBILE_MENU_SECOND,
  MOBILE_MENU_SERVICE,
  MOBILE_MENU_STORE
} from './MobileMenuLevels';
import styles from './MobileMenu.module.scss';

export const MobileMenu = withStoreConfiguration(({ storeConfiguration }) => {
  const [selectedFirstLevel, setSelectedFirstLevel] = useState(null);
  const [selectedSecondLevel, setSelectedSecondLevel] = useState(null);
  const [activeLevel, setActiveLevel] = useState(null);
  const [opened, setOpened] = useState(false);
  const storeView = storeConfiguration?.general?.country?.default;
  const trustedShopActive = parseInt(storeConfiguration?.asam_layoutconfiguration?.trustedshop?.active, 10);
  const [currentPosition, setCurrentPosition] = useState(typeof window !== 'undefined' ? window.pageYOffset : 0);
  // set default url in case config is empty
  let trustedLink = 'https://www.trustedshops.de/bewertung/info_XD44DBE0B47337B705AEE09C7FD01632A.html';
  if (trustedShopActive && storeConfiguration?.asam_layoutconfiguration?.trustedshop?.url) {
    trustedLink = storeConfiguration.asam_layoutconfiguration.trustedshop.url;
  }

  const firstLevelChangeHandle = selectedIndex => {
    setSelectedFirstLevel(selectedIndex);
  };

  const secondLevelChangeHandle = selectedIndex => {
    setSelectedSecondLevel(selectedIndex);
  };

  const closeMenuHandle = () => {
    const root = document.getElementById('root');
    setOpened(false);
    root.classList.remove('StopBgScrolling');
    root.style.height = 'auto';
  };

  const openMenuHandle = () => {
    const root = document.getElementById('root');
    setCurrentPosition(window.pageYOffset);
    setOpened(true);
    root.classList.add('StopBgScrolling');
    root.style.height = `${window.innerHeight}px`;
  };

  const mobileMenuLevelHandle = level => {
    setActiveLevel(level);
  };

  return (
    <React.Fragment>
      <MobileMenuTrigger open={openMenuHandle} />
      <div
        className={[styles.MobileMenuOverlay, opened ? styles.MobileMenuOpened : null].join(' ')}
        onClick={closeMenuHandle}
        aria-hidden
        style={{ opacity: 0 }}
      />
      <div
        className={[styles.MobileMenu, opened ? styles.MobileMenuOpened : null].join(' ')}
        style={{ transform: 'translateX(-105%)' }}
      >
        <MegaMenuQuery>
          {({ data: { megaMenu } }) => {
            const additionalClasses = [];

            switch (activeLevel) {
              case MOBILE_MENU_FIRST:
                additionalClasses.push(styles.FirstLevelActive);
                break;
              case MOBILE_MENU_SECOND:
                additionalClasses.push(styles.SecondLevelActive);
                break;
              case MOBILE_MENU_SERVICE:
                additionalClasses.push(styles.ServiceActive);
                break;
              case MOBILE_MENU_STORE:
                additionalClasses.push(styles.StoreActive);
                break;
              default:
                additionalClasses.push(styles.RootLevelActive);
            }
            return (
              <div>
                <div className={styles.MobileMenuHeader}>
                  {activeLevel === MOBILE_MENU_SECOND ? (
                    <div
                      className={styles.MobileMenuBackToRoot}
                      aria-hidden
                      onClick={() => {
                        mobileMenuLevelHandle(MOBILE_MENU_ROOT);
                      }}
                    >
                      <T id="mobileMenu.backToRoot" />
                    </div>
                  ) : null}
                  {trustedShopActive ? (
                    <a
                      className={styles.MobileMenuTrusted}
                      href={trustedLink}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      onClick={() => OutboundLink({ storeConfiguration, url: trustedLink })}
                    >
                      <img src={`${svgSprite}#trustedshops`} alt="TrustedShops" width="30" height="30" />
                    </a>
                  ) : null}
                  {storeView === 'DE' || storeView === 'CH' ? (
                    <div className={styles.MobileMenuLogo}>
                      <img src={`${headerSvg}#mobileMenuLogo`} alt="M. Asam Kosmetik" width={101} height={16} />
                    </div>
                  ) : null}
                  <div
                    className={styles.MobileMenuClose}
                    onClick={() => {
                      mobileMenuLevelHandle(MOBILE_MENU_ROOT);
                      closeMenuHandle();
                      // Close menu and scroll back to the position it was opened with the stuck menu
                      window.scrollTo(0, currentPosition + 1);
                      setTimeout(() => {
                        window.scrollTo(0, currentPosition);
                      }, 10);
                    }}
                    aria-hidden
                  >
                    <T id="mobileMenu.close" />
                  </div>
                </div>
                <div className={additionalClasses}>
                  <MobileMenuRootLevel
                    data={megaMenu}
                    onFirstLevelChange={firstLevelChangeHandle}
                    openLevel={mobileMenuLevelHandle}
                    onClose={closeMenuHandle}
                  />
                  <MobileMenuFirstLevel
                    data={megaMenu}
                    selectedFirstLevel={selectedFirstLevel}
                    openLevel={mobileMenuLevelHandle}
                    onClose={closeMenuHandle}
                    onSecondLevelChange={secondLevelChangeHandle}
                  />
                  <MobileMenuSecondLevel
                    data={megaMenu}
                    selectedFirstLevel={selectedFirstLevel}
                    selectedSecondLevel={selectedSecondLevel}
                    onClose={closeMenuHandle}
                    openLevel={mobileMenuLevelHandle}
                  />
                  <MobileMenuService onClose={closeMenuHandle} openLevel={mobileMenuLevelHandle} />
                  <MobileMenuStore onClose={closeMenuHandle} openLevel={mobileMenuLevelHandle} />
                </div>
              </div>
            );
          }}
        </MegaMenuQuery>
        <MobileMenuAdditional openLevel={mobileMenuLevelHandle} onClose={closeMenuHandle} />
      </div>
    </React.Fragment>
  );
});
