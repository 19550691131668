import React, { useEffect, useState } from 'react';
import { Dropdown } from '../../../ui/Dropdown/Dropdown';
import styles from './CustomOptions.module.scss';

export const CustomOptions = ({ options, values, setFieldValue, setPriceModificator }) => {
  const [activeOptionId, setActiveOptionId] = useState(undefined);
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    options.map(item => {
      // eslint-disable-next-line array-callback-return
      item.values.map((val, index) => {
        if (index === 0) {
          setPriceModificator(val.price);
        }
      });
    });
  }, []);

  const valuesWithPrices = options?.[0]?.values?.reduce((prev, current) => {
    return { ...prev, [current.optionTypeId]: current.price };
  }, {});

  // If a variant is changed to a configurable product, and it has custom Options (Dropdown prices) the price is reset
  if (activeOptionId !== options?.[0]?.optionId) {
    setActiveOptionId(options?.[0]?.optionId);
    const valueChange = options?.[0]?.values[0]?.optionTypeId?.toString();
    const resetValues = values.customOptions;
    resetValues[(options?.[0].optionId)] = options?.[0]?.values[0]?.optionTypeId;
    setFieldValue('customOptions', resetValues);
    setPriceModificator(valuesWithPrices[valueChange]);
  }

  return options.map(item => {
    item.values.sort((base, compare) => {
      if (base.price < compare.price) {
        return -1;
      }
      if (base.price > compare.price) {
        return 1;
      }
      return 0;
    });

    return (
      <div key={item.optionId}>
        <div className={styles.CustomOption}>
          <div className={styles.CustomOptionTitle}>{item.title}:</div>
          <div className={styles.CustomOptionInput}>
            <Dropdown
              options={item.values.map(x => {
                return { value: x.optionTypeId.toString(), label: x.title };
              })}
              onChange={value => {
                const newValues = values.customOptions;
                newValues[item.optionId] = value;
                setFieldValue('customOptions', newValues);
                setPriceModificator(valuesWithPrices[value]);
              }}
              listFullWidth
            />
          </div>
        </div>
        <div className="Divider DividerHorizontal" />
      </div>
    );
  });
};

CustomOptions.defaultProps = {
  options: []
};
