import React from 'react';
import { Button } from '@deity/falcon-ui';
import styles from './VideoPlayer.module.scss';

export const VideoPlayer = ({
  videoUrl,
  videoThumb,
  isAutoplayActive,
  isPlaying = false,
  isGallery = false,
  clickHandler,
  handleVideoEvent,
  title
}) => {
  return (
    <>
      {isGallery ? (
        <>
          {!isAutoplayActive ? (
            <video
              id="cfVideo"
              muted
              loop
              onTimeUpdate={e => handleVideoEvent(e)}
              onPlay={e => handleVideoEvent(e)}
              title={title}
              poster={videoThumb}
            >
              <source src={videoUrl} />
            </video>
          ) : (
            <video
              id="cfVideo"
              onTimeUpdate={e => handleVideoEvent(e)}
              onPlay={e => handleVideoEvent(e)}
              onEnded={e => handleVideoEvent(e)}
              muted
              autoPlay
              loop
              title={title}
              poster={videoThumb}
            >
              <source src={videoUrl} />
            </video>
          )}
        </>
      ) : (
        <>
          {isPlaying ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              id={`CmsCfVideo-${videoUrl}`}
              src={videoUrl}
              poster={videoThumb}
              controls
              playsInline
              onPlay={e => handleVideoEvent(e)}
              onTimeUpdate={e => handleVideoEvent(e)}
            />
          ) : (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video id={`CmsCfVideo-${videoUrl}`} src={videoUrl} poster={videoThumb} playsInline />
          )}
        </>
      )}

      {isAutoplayActive !== undefined && !isAutoplayActive && !isPlaying ? (
        <Button className={isGallery ? styles.GalleryPlayButton : styles.CmsVideoPlayButton} onClick={clickHandler}>
          <span className="PlayButtonIcon" />
        </Button>
      ) : null}
    </>
  );
};
